import { useLayoutEffect } from "react";

export default function useBodyBackgroundColor(color) {
  useLayoutEffect(() => {
    if (color) {
      const style = document.createElement("style");
      style.innerHTML = `body { background-color: ${color}; }`;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [color]);
}
