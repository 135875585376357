import { gql } from "@apollo/client";
import Stack from "atoms/Stack";
import EventAvatar, { EventAvatarFragment } from "molecules/EventAvatar";
import EventSubjectLabel, { EventSubjectLabelFragment } from "molecules/EventSubjectLabel";
import { videoPageContext } from "pages/VideoPage";
import React, { useContext } from "react";

import EventActions, { EventActionsFragment } from "./EventActions";
import VideoEventPotwRating, { VideoEventPotwRatingFragment } from "./VideoEventPotwRating";

export const HighlightHubFragment = gql`
  fragment HighlightHubFragment on Game {
    id
    ...VideoEventPotwRatingFragment
    ...EventActionsFragment
    events {
      id
      eventView {
        id
        ...EventAvatarFragment
        humanName
      }
      ...EventSubjectLabelFragment
    }
  }
  ${EventSubjectLabelFragment}
  ${EventActionsFragment}
  ${EventAvatarFragment}
  ${VideoEventPotwRatingFragment}
`;

export default function HighlightHub({ game }) {
  const { activeEventId } = useContext(videoPageContext);
  const event = game?.events.find((event) => event.id === activeEventId);

  return (
    <>
      {event && (
        <>
          <EventAvatar size={32} inverse eventView={event?.eventView} />
          <Stack
            veryDense
            style={{
              fontSize: 12,
              flex: "1 1 auto",
            }}
          >
            <div
              style={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              <EventSubjectLabel event={event} />
            </div>
            <div style={{ opacity: 0.8 }}>{event?.eventView.humanName}</div>
          </Stack>
        </>
      )}
      <Stack veryDense horizontal alignItemsCenter>
        <VideoEventPotwRating game={game} eventId={activeEventId} />
        <EventActions game={game} eventId={activeEventId} />
      </Stack>
    </>
  );
}
