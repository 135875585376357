import { Paper } from "@mui/material";
import React, { createContext } from "react";
import { useComponentSize } from "react-use-size";

export const controlBarContext = createContext();

export default function ControlBar({ children, ...others }) {
  const size = useComponentSize();
  const dense = size.width < 1000;

  const controlBarHeight = dense ? 32 : 50;

  return (
    <controlBarContext.Provider value={{ controlBarHeight }}>
      <Paper
        square
        {...others}
        ref={size.ref}
        style={{
          overflow: "hidden",
          ...others.style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "stretch",
            alignItems: "stretch",
            margin: "-0.5px",
            fontSize: dense ? 10 : 14,
          }}
        >
          {children}
        </div>
      </Paper>
    </controlBarContext.Provider>
  );
}
