import { gql } from "@apollo/client";
import DateTime from "atoms/DateTime";
import TextWithTooltip from "atoms/TextWithTooltip";
import useData from "helpers/useData";
import StatsTable, {
  StatsTableHeaderCell,
  StatsTableRow,
  StatsTableStatCell,
  StatsTableStatCellFragment,
} from "molecules/StatsTable";
import React from "react";

export default function PersonGamesStats({ personId }) {
  const [data] = useData(
    gql`
      query PersonGamesStats($personId: ID!) {
        person(id: $personId) {
          id
          gamePlayers(limit: 10) {
            id
            won
            lost
            game {
              id
              playedAt
              homeScore
              awayScore
              forfeit
              draw
            }
            team {
              id
              name
              logoUrl
            }
            againstTeam {
              id
              name
            }
            boxScoreStats {
              shortName
              name
              ...StatsTableStatCellFragment
            }
          }
        }
      }
      ${StatsTableStatCellFragment}
    `,
    { personId },
  );

  return (
    <>
      <StatsTable
        headerColumns={["Playing", "Result"]}
        columns={data?.person?.gamePlayers[0]?.boxScoreStats.map((stat) => (
          <TextWithTooltip key={stat.shortName} tooltip={stat.name}>
            {stat.shortName}
          </TextWithTooltip>
        ))}
      >
        {data?.person?.gamePlayers.map((gamePlayer) => (
          <StatsTableRow key={gamePlayer.id}>
            <StatsTableHeaderCell>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  alignItems: "center",
                }}
              >
                <img
                  src={gamePlayer.team.logoUrl}
                  alt={gamePlayer.team.name}
                  style={{
                    height: "2em",
                    width: "2em",
                    objectFit: "contain",
                  }}
                />
                <div>
                  <div>
                    <span style={{ opacity: 0.8 }}>v</span> <b>{gamePlayer.againstTeam.name}</b>
                  </div>
                  <DateTime variant="short_date" value={gamePlayer.game.playedAt} />
                </div>
              </div>
            </StatsTableHeaderCell>
            <StatsTableHeaderCell>
              {gamePlayer.game.forfeit && <span>F</span>}
              {gamePlayer.game.draw && <span>D</span>}
              {gamePlayer.won && <span style={{ color: "green" }}>W</span>}
              {gamePlayer.lost && <span style={{ color: "red" }}>L</span>}
              &nbsp;{gamePlayer.game.homeScore} - {gamePlayer.game.awayScore}
            </StatsTableHeaderCell>
            {gamePlayer.boxScoreStats.map((stat) => (
              <StatsTableStatCell stat={stat} key={stat.shortName} />
            ))}
          </StatsTableRow>
        ))}
      </StatsTable>
    </>
  );
}
