import { grey } from "@mui/material/colors";

export const COLOR_BLACK = "#030303";
export const COLOR_BLACK_LIGHTER = "#4e4d4d";
export const COLOR_BLACK_DARKER = "#000000";

export const COLOR_WHITE = "#F5F5F5";
export const COLOR_WHITE_LIGHTER = "#ffffff";
export const COLOR_WHITE_DARKER = "#eaeaea";

export const COLOR_GREY = "#7e7e7e";

export const COLOR_TEXT_MUTE = grey[600];
export const COLOR_GREY_BORDER = "#d3d3d3";
export const COLOR_GREY_BORDER_DARK = grey[600];
export const COLOR_TEXT_HOVER = "#ACA7B4";

export const COLOR_PRIMARY = "#1F2F74";
export const COLOR_PRIMARY_LIGHTER = "#4967eb";
export const COLOR_PRIMARY_DARKER = "#101427";

export const COLOR_SECONDARY = "#F1394B";
export const COLOR_SECONDARY_LIGHTER = "#F35A6B";
export const COLOR_SECONDARY_DARKER = "#ba303d";

export const COLOR_GL_RED = "#FF0055";
export const COLOR_GL_NAVY = "#171C38";
export const COLOR_GL_DARK_NAVY = "#111426";

export const COLOR_POINT = "#00BB08";
export const COLOR_MISS = "#909090";
export const COLOR_ASSIST = "#FFD94A";
export const COLOR_BLOCK = "#CF1E45";
export const COLOR_STEAL = "#C733E6";
export const COLOR_TURNOVER = "#A2A2A2";
export const COLOR_REBOUND = "#009DB3";
export const COLOR_FOUL = "#ff6600";

export const getStatColor = (statName) => {
  if (!statName) return COLOR_GREY;
  for (const [key, value] of [
    ["miss", COLOR_MISS],
    ["point", COLOR_POINT],
    ["goal", COLOR_POINT],
    ["assist", COLOR_ASSIST],
    ["block", COLOR_BLOCK],
    ["steal", COLOR_STEAL],
    ["turnover", COLOR_TURNOVER],
    ["rebound", COLOR_REBOUND],
    ["foul", COLOR_FOUL],
  ]) {
    if (statName.toLowerCase().includes(key)) {
      return value;
    }
  }
  return COLOR_GREY;
};

export const TEAM_COLORS = {
  red: "#CD223D",
  orange: "#FC6820",
  yellow: "#F7A00F",
  teal: "#187C7B",
  light_blue: "#288ABB",
  blue: "#274591",
  pink: "#DC456F",
  purple: "#57299E",
  violet: "#73197D",
  light_green: "#008035",
  green: "#134B35",
  gray: "#323232",
  light_gray: "#939393",
};
