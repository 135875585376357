import { gql } from "@apollo/client";

import useData from "./useData";

export default function usePlaylistsEnabled() {
  const [data] = useData(gql`
    query usePlaylistEnabled {
      currentUser {
        id
        isPublicLeaguePersona
        playlistsEnabled
      }
    }
  `);

  return (
    // has to be logged in
    data?.currentUser &&
    // feature flag
    !!data?.currentUser?.playlistsEnabled &&
    // not a public league persona
    data?.currentUser?.isPublicLeaguePersona === false
  );
}
