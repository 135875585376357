import { gql } from "@apollo/client";
import useData from "helpers/useData";
import React from "react";
import { Helmet } from "react-helmet";
import NotFoundPage from "shared/NotFoundPage";

import PotwVideoPlayer, { PotwVideoPlayerFragment } from "./video_player/PotwVideoPlayer";

export default function PotwCompetitionVideoPage({ potwCompetitionId }) {
  const [data] = useData(
    gql`
      query PotwCompetitionVideoPage($potwCompetitionId: ID!) {
        potwCompetition(id: $potwCompetitionId) {
          id
          name
          ...PotwVideoPlayerFragment
        }
      }
      ${PotwVideoPlayerFragment}
    `,
    { potwCompetitionId },
  );

  if (data && !data.potwCompetition) return <NotFoundPage />;

  return (
    <>
      <Helmet>
        <title>{data?.potwCompetition?.name}</title>
      </Helmet>
      <PotwVideoPlayer potwCompetitionId={potwCompetitionId} potwCompetition={data?.potwCompetition} />
    </>
  );
}
