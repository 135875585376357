import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function FetchMoreButton({ dataMeta, rows, rowsCount = null, autoFetch = false, ...others }) {
  const [offset, offsetSet] = useState();
  let endOfPage = rowsCount != null ? rows?.length >= rowsCount : offset === rows?.length;
  if (dataMeta?.loading) endOfPage = false;

  const fetchMoreDisabled = !dataMeta || !rows || endOfPage || dataMeta.loading;
  const fetchMoreRun = async () => {
    if (fetchMoreDisabled) return;

    await dataMeta.fetchMore({
      variables: {
        offset: rows.length,
      },
    });
    offsetSet(rows.length);
  };

  useEffect(() => {
    if (autoFetch && !fetchMoreDisabled) {
      fetchMoreRun();
    }
  }, [autoFetch, fetchMoreDisabled]);

  return (
    <Button
      color="inherit"
      onClick={fetchMoreRun}
      disabled={!dataMeta || !rows || endOfPage || dataMeta.loading}
      style={{
        padding: "1em",
        color: "inherit",
      }}
      {...others}
    >
      {endOfPage && <>End of results</>}
      {dataMeta?.loading && <CircularProgress color="inherit" size="1.5em" />}
      {dataMeta && !dataMeta.loading && !!rows && !endOfPage && <>Load More Results</>}
      {!!rows && (
        <span style={{ margin: "0 .5em", opacity: 0.5, fontSize: "0.8em" }}>
          (showing {rows.length}/{rowsCount === null ? "?" : rowsCount})
        </span>
      )}
    </Button>
  );
}
