import { useState } from "react";

export default function useRouteState(key) {
  const [value, valueSet] = useState(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(key);
  });

  const setter = (valueNew) => {
    if (valueNew?.constructor === Function) valueNew = valueNew(value);
    if (valueNew !== null) valueNew = String(valueNew);
    if (valueNew === value) return;

    valueSet(valueNew);

    const urlSearchParams = new URLSearchParams(window.location.search);

    if (valueNew === null) urlSearchParams.delete(key);
    else urlSearchParams.set(key, valueNew);

    let searchNew = urlSearchParams.toString();
    searchNew = searchNew ? "?" + searchNew : "";
    window.history.replaceState(window.history.state, "", location.href.replace(/\?.*$/, "") + searchNew);
  };

  return [value, setter];
}
