import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

export default function PotwCompetitionEmptyDialog() {
  return (
    <Dialog open>
      <DialogTitle>Competition Empty</DialogTitle>
      <DialogContent>
        <p>This competition does not have any highlights yet!</p>
        <p>
          <b>Rate any highlight from games to be in the running!</b>
        </p>
      </DialogContent>
      <DialogActions>
        <Button href="/potw">Back to Plays of the Week</Button>
        <Button href="/games" variant="contained">
          Go to Games
        </Button>
      </DialogActions>
    </Dialog>
  );
}
