import { COLOR_WHITE } from "helpers/colors";
import useDOMEvent from "helpers/useDOMEvent";
import useFullscreenState from "helpers/useFullscreenState";
import useGotoUrl from "helpers/useGotoUrl";
import React from "react";
import { useParams } from "react-router-dom";

import Navbar from "./Navbar";

export function Page({ Component }) {
  const [fullscreen] = useFullscreenState();
  const params = useParams();
  const gotoUrl = useGotoUrl();

  useDOMEvent("click", (event) => {
    if (event.defaultPrevented) return;
    const a = event.target.closest("a[href]");
    if (!a) return;
    let href = a.getAttribute("href");
    const target = a.getAttribute("target");

    event.stopPropagation();
    if (target && target !== "_self") return;
    if (event.ctrlKey || event.shiftKey || event.metaKey || event.altKey) return;

    event.preventDefault();
    if (!href || href.startsWith("#")) return;
    gotoUrl(href);
  });

  return (
    <>
      <div style={{ display: "flex", flexFlow: "column nowrap", minHeight: "100svh", backgroundColor: COLOR_WHITE }}>
        {!fullscreen && <Navbar />}
        <Component {...params} />
      </div>
    </>
  );
}
