import { gql } from "@apollo/client";
import { TextField } from "@mui/material";
import apolloClient from "helpers/apolloClient";
import { ONSEN_AUTH_TOKEN_KEY } from "helpers/constants";
import { GLORYLEAGUE_URL } from "helpers/environment";
import recaptcha from "helpers/recaptcha";
import useActionFragment from "helpers/useActionFragment";
import useEnforceAuth from "helpers/useEnforceAuth";
import useShowMessage from "helpers/useShowMessage";
import React, { useState } from "react";
import FormPage, { FormPageSubmitButton } from "shared/FormPage";

export default function LoginPage() {
  const [email, emailSet] = useState("");
  const [password, passwordSet] = useState("");
  useEnforceAuth({ enforceUnauthenticated: true });
  const login = useActionFragment("login", "authToken");
  const showMessage = useShowMessage();

  return (
    <FormPage
      title={
        <>
          Login to <b>Glory League</b>
        </>
      }
      onSubmit={async () => {
        const gRecaptchaResponse = await recaptcha("login");
        const result = await login({
          input: {
            email,
            password,
            gRecaptchaResponse,
          },
        });
        const authToken = result.login.authToken;
        if (!authToken) {
          await showMessage("Invalid email or password");
          return;
        }
        window.localStorage.setItem(ONSEN_AUTH_TOKEN_KEY, authToken);
        await apolloClient.query({
          fetchPolicy: "network-only",
          query: gql`
            query LoginPage {
              currentUser {
                id
              }
            }
          `,
        });
      }}
    >
      <TextField
        autoFocus
        label="Email Address"
        autoComplete="email"
        value={email}
        onChange={(event) => emailSet(event.target.value)}
        required
      />
      <TextField
        label="Password"
        type="password"
        autoComplete="current-password"
        value={password}
        onChange={(event) => passwordSet(event.target.value)}
        required
      />
      <FormPageSubmitButton label="Login" />
      <div>
        Don&apos;t have a new password or forgot your password? <a href="/reset-password">reset password</a>
      </div>
      <div>
        Are you an administrator? <a href={`${GLORYLEAGUE_URL}/login`}>Login here</a>
      </div>
    </FormPage>
  );
}
