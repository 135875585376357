import { modalContext } from "helpers/contexts";
import React, { useContext, useId, useLayoutEffect, useState } from "react";

export default function ModalContextProvider({
  open = true,
  // for debugging
  name,
  children,
}) {
  const id = `modal-${name}-${useId()}`;
  const [openChildIds, openChildIdsSet] = useState([]);
  const childrenOpen = openChildIds.length > 0;

  const parentModalContext = useContext(modalContext);

  const isModalActive = open && !childrenOpen;
  const selfOrChildrenOpen = open || childrenOpen;

  useLayoutEffect(() => {
    if (selfOrChildrenOpen && parentModalContext) {
      parentModalContext.openChildIdsSet((prev) => [...prev, id]);

      return () => {
        parentModalContext.openChildIdsSet((prev) => prev.filter((childId) => childId !== id));
      };
    }
  }, [selfOrChildrenOpen, !!parentModalContext]);

  return (
    <modalContext.Provider
      value={{
        isModalActive,
        open,
        selfOrChildrenOpen,
        openChildIds,
        openChildIdsSet,
      }}
    >
      {children}
    </modalContext.Provider>
  );
}
