import { gql } from "@apollo/client";
import { Card, darken, lighten } from "@mui/material";
import { ButtonBase } from "@mui/material";
import { COLOR_BLACK, COLOR_SECONDARY, COLOR_WHITE, TEAM_COLORS } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import useData from "helpers/useData";
import React, { Fragment } from "react";

export const TeamCardFragment = gql`
  fragment TeamCardFragment on Team {
    id
    name
    logoUrl
    colour
    lastLeague {
      id
      name
    }
    gamesCount
    playersCount: teamMembersCount(role: "player")
    coachesCount: teamMembersCount(role: "coach")
  }
`;

export default function TeamCard({ team, personId }) {
  const color = TEAM_COLORS[team?.colour] || COLOR_SECONDARY;
  const colorDarken = darken(color, 0.5);
  const colorLighten = lighten(color, 0.1);

  const [personData] = useData(
    gql`
      query TeamCard_person($personId: ID!, $teamId: ID) {
        team(id: $teamId) {
          id
          personRoles(personId: $personId)
          personPlayerTeamMember(personId: $personId) {
            id
            playerNumber
          }
        }
        person(id: $personId) {
          id
          fullNameWithAnonymisation
        }
      }
    `,
    { personId, teamId: team?.id },
    { skip: !personId },
  );

  const personPlayerTeamMember = personData?.team?.personPlayerTeamMember;

  return (
    <Card
      component={ButtonBase}
      href={`/team/${team?.id}`}
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "stretch",
        alignItems: "stretch",
      }}
    >
      <div
        style={{
          flex: "1 1 auto",
          backgroundColor: color,
          color: COLOR_WHITE,
          background: `linear-gradient(130deg, ${colorLighten}, ${colorDarken})`,
          display: "flex",
          flexFlow: "row wrap",
          alignItems: "center",
          justifyContent: "stretch",
          padding: 20,
          gap: 20,
        }}
      >
        <img
          src={team?.logoUrl}
          alt="Team logo"
          style={{
            width: 100,
            aspectRatio: "1/1",
            objectFit: "contain",
            objectPosition: "center",
          }}
        />
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
            gap: 10,
          }}
        >
          <h2
            style={{
              margin: 0,
              fontSize: 48,
              fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
              fontWeight: 100,
              textTransform: "uppercase",
            }}
          >
            {team?.name}
          </h2>
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "stretch",
              gap: 10,
              fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
              fontWeight: 100,
              textTransform: "uppercase",
            }}
          >
            {[
              ["League", team?.lastLeague?.name, 24],
              ["Games", team?.gamesCount],
              ["Players", team?.playersCount],
              ["Coaches", team?.coachesCount],
            ].map(([label, value, fontSize = 48]) => (
              <div
                key={label}
                style={{
                  flex: "1 1 auto",
                }}
              >
                <div
                  style={{
                    opacity: 0.5,
                    fontSize: 12,
                  }}
                >
                  {label}
                </div>
                <div
                  style={{
                    fontSize,
                  }}
                >
                  {value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {personId && (
        <div
          style={{
            backgroundColor: COLOR_BLACK,
            color: COLOR_WHITE,
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            padding: 10,
            gap: 10,
          }}
        >
          <div>{personData?.person?.fullNameWithAnonymisation}</div>
          {personPlayerTeamMember && (
            <>
              <div
                style={{
                  fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                }}
              >
                #{personPlayerTeamMember.playerNumber}
              </div>
            </>
          )}
          <div style={{ flex: "1 0 auto" }} />
          {personData?.team?.personRoles.map((role) => (
            <div
              key={role}
              style={{
                textTransform: "uppercase",
                fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
              }}
            >
              {role}
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}
