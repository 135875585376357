import { gql } from "@apollo/client";
import { HelpCircleOutline } from "mdi-material-ui";
import PlayerButton from "pages/video_player/PlayerButton";
import { videoPageContext } from "pages/VideoPage";
import React, { useContext, useState } from "react";

import VideoPageTour from "./VideoPageTour";

export const VideoTourButtonFragment = gql`
  fragment VideoTourButtonFragment on Game {
    id
    events {
      id
      occurredAtSeconds
    }
  }
`;

export default function VideoTourButton({ game }) {
  const { videoRef, filteredEventIds, activeEventId } = useContext(videoPageContext);
  const [open, openSet] = useState(false);

  return (
    <>
      <PlayerButton
        title="Take a tour"
        data-video-joyride="thanks"
        icon={<HelpCircleOutline />}
        disabled={!game}
        onClick={() => {
          openSet(true);
          const firstEvent = game?.events.find((e) => filteredEventIds.includes(e.id));
          if (!activeEventId && firstEvent) {
            videoRef.current.currentTime = firstEvent.occurredAtSeconds;
            videoRef.current.pause();
          }
        }}
      />
      <VideoPageTour open={open} onClose={() => openSet(false)} />
    </>
  );
}
