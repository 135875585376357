import { COLOR_BLACK, COLOR_GREY_BORDER, COLOR_SECONDARY, COLOR_WHITE } from "helpers/colors";
import { controlBarContext } from "molecules/ControlBar";
import React, { useContext } from "react";

export default function ControlBarContent({ children, inverse, component: Component = "div", ...others }) {
  const { controlBarHeight } = useContext(controlBarContext);

  return (
    <Component
      {...others}
      style={{
        minHeight: controlBarHeight,
        flex: "99999 1 300px",
        color: COLOR_BLACK,
        backgroundColor: COLOR_WHITE,
        border: `.5px solid ${inverse ? COLOR_SECONDARY : COLOR_GREY_BORDER}`,
        ...(inverse && {
          color: COLOR_WHITE,
          backgroundColor: COLOR_SECONDARY,
        }),
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "center",
        ...others.style,
      }}
    >
      {children}
    </Component>
  );
}
