import checkHasAudio from "helpers/checkHasAudio";
import { ONSEN_ENV } from "helpers/environment";
import mergeRefs from "helpers/mergeRefs";
import HlsJs from "hls.js";
import React, { useEffect, useRef, useState } from "react";

const M3U8_MIME_TYPE = "application/vnd.apple.mpegurl";

function nativeM3u8Support() {
  if (window._nativeM3u8Support === undefined) {
    const videoEl = document.createElement("video");
    const nativeM3u8Support = !!videoEl.canPlayType(M3U8_MIME_TYPE);
    window._nativeM3u8Support = nativeM3u8Support;
  }
  return window._nativeM3u8Support;
}

// low-level video component that handles these features:
//
// - HLS support via hls.js
// - detecting whether video has audio track
// - patch ontimeupdate event without throttling
//
export default function HLSVideo({ src, videoRef, onAudioEnabledChange, onTimeUpdateUnthrottled, ...others }) {
  const [audioEnabled, audioEnabledSet] = useState(null);
  const srcParsed = src && new URL(src, window.location.href);

  let hlsJsSrc = null;
  const hlsJsSupport = HlsJs.isSupported();
  if (srcParsed?.pathname.toLowerCase().endsWith(".m3u8") && !nativeM3u8Support() && hlsJsSupport) {
    hlsJsSrc = src;
    src = null;
  }
  const ref = useRef();

  useEffect(() => {
    const videoEl = ref.current;

    if (hlsJsSrc && videoEl) {
      const hls = new HlsJs();
      hls.loadSource(hlsJsSrc);
      hls.attachMedia(videoEl);
      return () => {
        hls.detachMedia();
        hls.destroy();
      };
    }
  }, [hlsJsSrc]);

  useEffect(() => {
    if (audioEnabled !== null) onAudioEnabledChange?.(audioEnabled);
  }, [audioEnabled]);

  // unthrottled ontimeupdate
  useEffect(() => {
    if (onTimeUpdateUnthrottled) {
      let stop = false;
      const video = videoRef.current;
      const func = () => {
        if (stop) return;
        onTimeUpdateUnthrottled?.(video);
        window.requestAnimationFrame(func);
      };
      window.requestAnimationFrame(func);

      return () => {
        stop = true;
      };
    }
  }, [!!onTimeUpdateUnthrottled]);

  return (
    <video
      ref={mergeRefs(videoRef, ref)}
      src={src}
      {...others}
      {...(ONSEN_ENV === "test" && {
        autoPlay: false,
      })}
      onLoadedData={(event) => {
        const audioEnabled = checkHasAudio(ref.current);
        audioEnabledSet(audioEnabled);
        others.onLoadedData?.(event);
      }}
      onPlaying={(event) => {
        const audioEnabled = checkHasAudio(ref.current);
        audioEnabledSet(audioEnabled);
        others.onPlaying?.(event);
      }}
      onTimeUpdate={(event) => {
        const audioEnabled = checkHasAudio(ref.current);
        audioEnabledSet(audioEnabled);
        others.onTimeUpdate?.(event);
      }}
    />
  );
}
