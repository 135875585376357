import { useContext } from "react";

import { messagesContext } from "./contexts";

export default function useShowMessage() {
  const { showMessage } = useContext(messagesContext);

  return (content, severity, options) => {
    let message = {
      content,
    };

    if (content?.constructor === Object) {
      message = {
        ...message,
        ...content,
      };
    }

    if (severity?.constructor === Object) {
      message = {
        ...message,
        ...severity,
      };
    }
    if (typeof severity === "string") {
      message = {
        ...message,
        severity,
      };
    }

    message = {
      ...message,
      ...options,
    };

    showMessage(message);
  };
}
