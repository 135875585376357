import ExtendedError from "./ExtendedError";

export const ERROR_MESSAGE_CANCELLED = "Cancelled";

export default class AbortError extends ExtendedError {
  constructor(message = ERROR_MESSAGE_CANCELLED, metadata) {
    super(message);
    Object.assign(this, {
      ...metadata,
      metadata,
      isAbortError: true,
    });
  }
}
