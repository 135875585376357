import { gql } from "@apollo/client";
import { FormControlLabel, Switch, TextField } from "@mui/material";
import useActionFragment from "helpers/useActionFragment";
import useShowMessage from "helpers/useShowMessage";
import Message from "molecules/Message";
import Section from "molecules/Section";
import Toolbar, { ToolbarButton } from "molecules/Toolbar";
import React from "react";

export const EditAccountFormFragment = gql`
  fragment EditAccountFormFragment on CurrentUser {
    id
    email
    subscribedNotifications
    subscribedMarketing
    subscribedCompetitions
    peopleCount
    people {
      id
      fullName
    }
  }
`;

export default function EditAccountForm({ user }) {
  const [email, setEmail] = React.useState(user.email);

  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");

  const PASSWORD_MINIMUM_CHARS = 6;
  const [passwordProvided, setPasswordProvided] = React.useState(false);
  const [passwordComplete, setPasswordComplete] = React.useState(false);
  const [passwordConfirmed, setPasswordConfirmed] = React.useState(false);
  const [passwordLegalChars, setPasswordLegalChars] = React.useState(false);
  const getPasswordErrorText = () => {
    if (passwordProvided) {
      if (!passwordComplete) {
        return `Must be at least ${PASSWORD_MINIMUM_CHARS} characters`;
      } else if (!passwordLegalChars) {
        return "Must not contain any spaces";
      }
    }
    return `Minimum of ${PASSWORD_MINIMUM_CHARS} characters`;
  };

  const [subscribedNotifications, setsubscribedNotifications] = React.useState(user.subscribedNotifications);
  const [subscribedMarketing, setsubscribedMarketing] = React.useState(user.subscribedMarketing);
  const [subscribedCompetitions, setsubscribedCompetitions] = React.useState(user.subscribedCompetitions);

  const [savingForm, setSavingForm] = React.useState(false);
  const validateForm = () => {
    const passwordValid =
      (passwordProvided && passwordComplete && passwordLegalChars && passwordConfirmed) || !passwordProvided; //ignore validating password if no password provided
    return passwordValid;
  };
  const showMessage = useShowMessage();

  const accountUpdate = useActionFragment(
    "currentUserUpdate",
    `
      currentUser { id email subscribedNotifications subscribedMarketing subscribedCompetitions onboarded }
    `,
  );

  return (
    <form
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        gap: 20,
      }}
      onSubmit={async (event) => {
        event.preventDefault();
        setSavingForm(true);
        if (validateForm()) {
          try {
            const result = await accountUpdate({
              input: {
                email,
                password: passwordProvided ? password : undefined,
                subscribedCompetitions,
                subscribedNotifications,
                subscribedMarketing,
              },
            });

            if (passwordProvided) {
              //reset password fields
              setPassword("");
              setPasswordConfirmation("");
              setPasswordProvided(false);
              setPasswordComplete(false);
              setPasswordConfirmed(false);
              setPasswordLegalChars(false);
            }

            showMessage("Successfully saved changes", "success");

            user = result;
          } finally {
            setSavingForm(false);
          }
        } else {
          showMessage("Must fix errors before saving", "error");
        }
        setSavingForm(false);
      }}
    >
      <Section title="Account Details">
        <TextField
          required
          label="Email Address"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          size="small"
          helperText="This is used to access all games under this account"
        />
        {user.peopleCount > 1 && email !== user.email && (
          <Message>
            You have multiple profiles under this account.
            <ul>
              {user.people.map((person) => (
                <li key={person.id}>{person.fullName}</li>
              ))}
            </ul>
            Changing email address will update all profiles. <br />
            If you want to change email address for a specific profile, please use <b>Transfer Profile</b> in&nbsp;
            <a href="/people">My Profiles</a>.
          </Message>
        )}
      </Section>
      <Section title="Change Password">
        <TextField
          label="New password"
          type="password"
          value={password}
          onChange={(event) => {
            const password = event.target.value;
            setPassword(password);
            setPasswordProvided(password.length > 0);
            setPasswordComplete(password.length >= PASSWORD_MINIMUM_CHARS);
            setPasswordConfirmed(password === passwordConfirmation);
            setPasswordLegalChars(!/\s/.test(password));
          }}
          autoComplete="new-password"
          size="small"
          error={passwordProvided && (!passwordComplete || !passwordLegalChars)}
          helperText={getPasswordErrorText()}
        />
        <TextField
          label="New password confirmation"
          type="password"
          value={passwordConfirmation}
          onChange={(event) => {
            const passwordConfirmation = event.target.value;
            setPasswordConfirmation(passwordConfirmation);
            setPasswordConfirmed(password === passwordConfirmation);
          }}
          error={passwordProvided && !passwordConfirmed}
          helperText={passwordProvided && !passwordConfirmed ? "Passwords must match" : ""}
          autoComplete="new-password"
          size="small"
        />
      </Section>
      <Section title="Email Subscriptions">
        <FormControlLabel
          control={
            <Switch
              data-cy="sub-notifications"
              checked={subscribedNotifications}
              onChange={(event) => {
                setsubscribedNotifications(event.target.checked);
              }}
            />
          }
          label="Receive Game Notifications"
        />
        <FormControlLabel
          control={
            <Switch
              checked={subscribedCompetitions}
              onChange={(event) => {
                setsubscribedCompetitions(event.target.checked);
              }}
            />
          }
          label="Receive Plays of the Week Notifications"
        />
        <FormControlLabel
          control={
            <Switch
              checked={subscribedMarketing}
              onChange={(event) => {
                setsubscribedMarketing(event.target.checked);
              }}
            />
          }
          label="Receive Marketing Notifications"
        />
      </Section>
      <Toolbar>
        <ToolbarButton label="Save all changes" type="submit" primary disabled={savingForm} />
      </Toolbar>
    </form>
  );
}
