import { gql } from "@apollo/client";
import { IconButton } from "@mui/material";
import HoverModal from "atoms/HoverModal";
import Stack from "atoms/Stack";
import WithTooltip from "atoms/WithTooltip";
import { COLOR_GREY } from "helpers/colors";
import { fetchFile } from "helpers/fetchFile";
import {
  SHORTCUT_KEY_CODE_VIDEO_PAGE_ADD_TO_PLAYLIST,
  SHORTCUT_KEY_CODE_VIDEO_PAGE_DOWNLOAD_HIGHLIGHT,
  SHORTCUT_KEY_CODE_VIDEO_PAGE_EDIT_HIGHLIGHT,
  SHORTCUT_KEY_CODE_VIDEO_PAGE_SHARE_HIGHLIGHT,
  SHORTCUT_KEY_CODE_VIDEO_PAGE_TOGGLE_STAR,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_ADD_TO_PLAYLIST,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_DOWNLOAD_HIGHLIGHT,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_EDIT_HIGHLIGHT,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_SHARE_HIGHLIGHT,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_TOGGLE_STAR,
} from "helpers/SHORTCUTS";
import useActionFragment from "helpers/useActionFragment";
import useData from "helpers/useData";
import useDOMEvent from "helpers/useDOMEvent";
import usePlaylistsEnabled from "helpers/usePlaylistsEnabled";
import { progressBar } from "helpers/useProgressBar";
import useShowMessage from "helpers/useShowMessage";
import { Download, Pencil, PlaylistPlus, Share, Star, StarOutline } from "mdi-material-ui";
import AddToPlaylistDialog from "pages/playlists/AddToPlaylistDialog";
import HighlightPlayerDialog from "pages/video_player/HighlightPlayerDialog";
import { videoPageContext } from "pages/VideoPage";
import React, { cloneElement, useContext, useState } from "react";

import EditHighlightDialog, { EditHighlightDialogFragment } from "./EditHighlightDialog";

export const EventActionsFragment = gql`
  fragment EventActionsFragment on Game {
    id
    canOnsenTagVideo
    events {
      id
      eventView {
        id
        title
        shareable
        videoUrl
      }
    }
    ...EditHighlightDialogFragment
  }
  ${EditHighlightDialogFragment}
`;

export default function EventActions({ game, eventId }) {
  const { isVideoPageModalActive } = useContext(videoPageContext);
  const event = game?.events.find((event) => event.id === eventId);

  const eventToggleStarred = useActionFragment(
    "eventToggleStarred",
    `playlist { id clips { id } } event { id starred }`,
  );
  const showMessage = useShowMessage();

  const [shareOpen, shareOpenSet] = useState(false);
  const [addToPlaylistOpen, addToPlaylistOpenSet] = useState(false);
  const [editOpen, editOpenSet] = useState(false);
  const playlistsEnabled = usePlaylistsEnabled();

  // Fetch starred status later as it couldn't be preloaded easily
  const [data] = useData(
    gql`
      query EventActions_starred($eventId: ID!) {
        event(id: $eventId) {
          id
          starred
        }
      }
    `,
    { eventId },
  );

  const starred = data?.event?.starred;

  const triggerEdit = () => {
    if (!game?.canOnsenTagVideo) return;
    editOpenSet(true);
  };

  const triggerToggleStarred = () =>
    progressBar(async () => {
      if ([null, undefined].includes(starred)) return;
      await eventToggleStarred({
        input: { eventId, starred: !starred },
      });
      showMessage(starred ? "Highlight unstarred" : "Highlight starred");
    });

  const triggerShare = () => {
    if (!event?.eventView.shareable) return;
    shareOpenSet(true);
  };

  const triggerDonwload = () => {
    if (!event?.eventView.shareable || !event?.eventView.videoUrl) return;
    progressBar(async () => {
      const { filename, fileObjectUrl } = await fetchFile(event.eventView.videoUrl, event.eventView.title);
      const a = document.createElement("a");
      a.href = fileObjectUrl;
      a.download = filename;
      a.click();
    });
  };

  const triggerAddToPlaylist = () => {
    if (!event?.eventView.shareable) return;
    addToPlaylistOpenSet(true);
  };

  useDOMEvent("keydown", (event) => {
    if (!isVideoPageModalActive) return;
    if (event.metaKey || event.ctrlKey || event.shiftKey || event.altKey) return;
    if (event.code === SHORTCUT_KEY_CODE_VIDEO_PAGE_EDIT_HIGHLIGHT) {
      event.preventDefault();
      event.stopImmediatePropagation();
      triggerEdit();
    }
    if (event.code === SHORTCUT_KEY_CODE_VIDEO_PAGE_SHARE_HIGHLIGHT) {
      event.preventDefault();
      event.stopImmediatePropagation();
      triggerShare();
    }
    if (event.code === SHORTCUT_KEY_CODE_VIDEO_PAGE_DOWNLOAD_HIGHLIGHT) {
      event.preventDefault();
      event.stopImmediatePropagation();
      triggerDonwload();
    }
    if (event.code === SHORTCUT_KEY_CODE_VIDEO_PAGE_ADD_TO_PLAYLIST) {
      event.preventDefault();
      event.stopImmediatePropagation();
      triggerAddToPlaylist();
    }
    if (event.code === SHORTCUT_KEY_CODE_VIDEO_PAGE_TOGGLE_STAR) {
      event.preventDefault();
      event.stopImmediatePropagation();
      triggerToggleStarred();
    }
  });

  return (
    <>
      <AddToPlaylistDialog open={addToPlaylistOpen} onClose={() => addToPlaylistOpenSet(false)} eventId={eventId} />
      <EditHighlightDialog game={game} open={editOpen} eventId={eventId} onClose={() => editOpenSet(false)} />
      <HighlightPlayerDialog open={shareOpen} onClose={() => shareOpenSet(false)} eventViewId={event?.eventView.id} />
      <HoverModal
        element={
          <Stack noGap horizontal alignItemsCenter>
            {game?.canOnsenTagVideo && (
              <EventActionButton
                icon={<Pencil />}
                title="Edit highlight"
                shortcutLabel={SHORTCUT_KEY_LABEL_VIDEO_PAGE_EDIT_HIGHLIGHT}
                onClick={triggerEdit}
              />
            )}
            <EventActionButton
              icon={<Share />}
              title="Share highlight"
              shortcutLabel={SHORTCUT_KEY_LABEL_VIDEO_PAGE_SHARE_HIGHLIGHT}
              href={event && `/event/${event.eventView.id}`}
              disabled={!event?.eventView.shareable}
              onClick={(event) => {
                event.preventDefault();
                triggerShare();
              }}
            />
            <EventActionButton
              title="Download highlight"
              shortcutLabel={SHORTCUT_KEY_LABEL_VIDEO_PAGE_DOWNLOAD_HIGHLIGHT}
              icon={<Download />}
              disabled={!event?.eventView.shareable || !event?.eventView.videoUrl}
              onClick={triggerDonwload}
            />
            {playlistsEnabled && (
              <>
                <EventActionButton
                  title={starred ? "Unstar this highlight" : "Star this highlight"}
                  shortcutLabel={SHORTCUT_KEY_LABEL_VIDEO_PAGE_TOGGLE_STAR}
                  icon={starred ? <Star /> : <StarOutline />}
                  disabled={!event?.eventView.shareable || !data}
                  onClick={triggerToggleStarred}
                />
                <EventActionButton
                  title="Add to playlist"
                  shortcutLabel={SHORTCUT_KEY_LABEL_VIDEO_PAGE_ADD_TO_PLAYLIST}
                  icon={<PlaylistPlus fontSize="inherit" />}
                  disabled={!event?.eventView.shareable}
                  onClick={triggerAddToPlaylist}
                />
              </>
            )}
          </Stack>
        }
      />
    </>
  );
}

function EventActionButton({ title, shortcutLabel, icon, onClick, disabled = false, href = "#" }) {
  return (
    <WithTooltip
      tooltip={title}
      shortcutLabel={shortcutLabel}
      placement="top"
      inverse
      element={
        <IconButton
          size="small"
          color="inherit"
          href={href}
          onClick={onClick}
          disabled={disabled}
          area-label={title}
          style={{
            ...(disabled && {
              color: COLOR_GREY,
            }),
          }}
        />
      }
    >
      {cloneElement(icon, { fontSize: "inherit" })}
    </WithTooltip>
  );
}
