import { gql } from "@apollo/client";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import useGotoUrl from "helpers/useGotoUrl";
import { useEffect } from "react";

export default function TeamsPage() {
  useEnforceAuth({ enforceAuthenticated: true });
  const gotoUrl = useGotoUrl();
  const [data, dataMeta] = useData(gql`
    query TeamsPage {
      currentUser {
        id
        teams {
          id
        }
      }
    }
  `);

  useEffect(() => {
    if (!dataMeta.loading && data?.currentUser) {
      const teamId = data.currentUser.teams[0]?.id;
      if (teamId) {
        gotoUrl(`/team/${teamId}`);
      } else {
        // No teams, new user
        gotoUrl(`/`);
      }
    }
  }, [data, dataMeta]);

  return null;
}
