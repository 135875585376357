import { TextField } from "@mui/material";
import recaptcha from "helpers/recaptcha";
import useActionFragment from "helpers/useActionFragment";
import useEnforceAuth from "helpers/useEnforceAuth";
import useGotoUrl from "helpers/useGotoUrl";
import useShowMessage from "helpers/useShowMessage";
import React, { useState } from "react";
import FormPage, { FormPageSubmitButton } from "shared/FormPage";

export default function ResetPasswordPage() {
  useEnforceAuth({ enforceUnauthenticated: true });

  const [email, emailSet] = useState("");
  const requestResetPassword = useActionFragment("requestResetPassword");
  const gotoUrl = useGotoUrl();
  const showMessage = useShowMessage();

  return (
    <FormPage
      title={<>Reset Password</>}
      onSubmit={async () => {
        const gRecaptchaResponse = await recaptcha();
        await requestResetPassword({
          input: {
            email,
            gRecaptchaResponse,
          },
        });
        await showMessage(
          "An email will been sent to you with instructions on how to reset your password if an account exists with that email address.",
        );
        await gotoUrl("/login");
      }}
    >
      <div>Enter your email address and we&apos;ll send you a link to reset your password.</div>
      <TextField
        autoFocus
        label="Email Address"
        autoComplete="email"
        value={email}
        onChange={(event) => emailSet(event.target.value)}
        required
      />
      <FormPageSubmitButton label="Send reset password email" />
      <div>
        <a href="/login">Back to login</a>
      </div>
    </FormPage>
  );
}
