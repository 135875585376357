import { gql } from "@apollo/client";
import { Alert } from "@mui/material";
import useData from "helpers/useData";
import React from "react";

export default function GamesBlankMessage() {
  const [data] = useData(gql`
    query GamesBlankMessage {
      currentUser {
        id
        fullName
      }
    }
  `);

  return (
    <Alert variant="outlined" severity="info" icon={false}>
      <p>Hey {data?.currentUser?.fullName}, unfortunately we don&apos;t have any games for you yet.</p>
      <p>
        You will receive an email after each game you play in, with a link which will take you directly to that
        game&apos;s video.
      </p>
      <p>
        If you&apos;re expecting games to be here already, please contact{" "}
        <a href="mailto:support@gloryleague.com">support@gloryleague.com</a> and we&apos;ll get you sorted.
      </p>
      <p>In the meantime check out this video for more info on some of the great stuff you can do.</p>
      <p>
        <video
          style={{
            width: "100%",
          }}
          controls
          poster="https://d1erkakct02rdc.cloudfront.net/summary-video/poster.jpg"
          src="https://d1erkakct02rdc.cloudfront.net/summary-video/glsummary-540.mp4"
        ></video>
      </p>
    </Alert>
  );
}
