import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { COLOR_PRIMARY, COLOR_SECONDARY } from "helpers/colors";
import React from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main: COLOR_SECONDARY,
    },
  },
});

export default function MuiProvider({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
