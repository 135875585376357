import { gql } from "@apollo/client";
import { COLOR_GREY, COLOR_PRIMARY, TEAM_COLORS } from "helpers/colors";
import useData from "helpers/useData";
import Section from "molecules/Section";
import React from "react";
import { ComposedChart, Line, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export default function PointChart({ gameId }) {
  const [data] = useData(
    gql`
      query PointChart($gameId: ID!) {
        game(id: $gameId) {
          id
          home {
            id
            name
            colour
          }
          away {
            id
            name
            colour
          }
          pointChart {
            maxScore
            entries {
              timeString
              homeScore
              awayScore
            }
            periods {
              name
              startTimeString
              finishTimeString
            }
          }
        }
      }
    `,
    { gameId },
  );

  return (
    <Section title="Point Chart">
      <div style={{ height: "50vh" }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={data?.game?.pointChart.entries || []}>
            <XAxis dataKey="timeString" hide />
            <YAxis hide scale="linear" />
            <Tooltip />
            <Line
              type="linear"
              dataKey="homeScore"
              name={data?.game?.home.name}
              stroke={TEAM_COLORS[data?.game?.home.colour] || COLOR_PRIMARY}
              dot={false}
              strokeWidth={2}
            />
            <Line
              type="linear"
              dataKey="awayScore"
              name={data?.game?.away.name}
              stroke={TEAM_COLORS[data?.game?.away.colour] || COLOR_PRIMARY}
              dot={false}
              strokeWidth={2}
            />
            {data?.game?.pointChart.periods.map((period) => (
              <ReferenceArea
                key={period.name}
                x1={period.startTimeString}
                x2={period.finishTimeString}
                y1={0}
                y2={data?.game?.pointChart.maxScore}
                stroke={COLOR_GREY}
                strokeOpacity={0.3}
                fillOpacity={0}
                label={period.name}
              />
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Section>
  );
}
