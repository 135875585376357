import { gql } from "@apollo/client";
import { TEAM_COLORS } from "helpers/colors";
import React from "react";

import PlayerAvatar from "../atoms/PlayerAvatar";
import TeamAvatar from "./TeamAvatar";

export const ReelViewAvatarFragment = gql`
  fragment ReelViewAvatarFragment on ReelView {
    isPlayerReel
    playerNumber
    personAvatarUrl
    isTeamReel
    teamColor
    teamLogoUrl
  }
`;

export default function ReelViewAvatar({ reelView, ...others }) {
  return (
    (reelView?.isPlayerReel && (
      <PlayerAvatar
        playerNumber={reelView.playerNumber}
        avatarUrl={reelView.personAvatarUrl}
        color={TEAM_COLORS[reelView.teamColor]}
        {...others}
      />
    )) ||
    (reelView?.isTeamReel && <TeamAvatar teamLogoUrl={reelView.teamLogoUrl} {...others} />)
  );
}
