import React from "react";

export default function ImageIcon({ src, alt, fontSize = "1.5em", ...others }) {
  return (
    <img
      src={src}
      alt={alt}
      {...others}
      style={{
        display: "inline-block",
        height: "1em",
        width: "1em",
        objectFit: "contain",
        verticalAlign: "middle",
        fontSize,
        ...others.style,
      }}
    />
  );
}
