import { useMutation } from "@apollo/client";
import { defer } from "lodash-es";

import AbortError from "./AbortError";
import apolloClient from "./apolloClient";
import getApolloErrors from "./getApolloErrors";
import useShowMessage from "./useShowMessage";

export default function useAction(mutation, defaultOptions) {
  const showMessage = useShowMessage();
  const [mutate, { error, loading }] = useMutation(mutation, {
    client: apolloClient,
  });

  const action = async (variables, actionOptions) => {
    const options = {
      ...(defaultOptions?.constructor === Function ? defaultOptions(variables) : defaultOptions),
      ...actionOptions,
    };

    let result;
    try {
      result = await mutate({
        ...options,
        variables,
      });
    } catch (apolloError) {
      const errors = getApolloErrors(apolloError);
      for (const error of errors) {
        defer(() => {
          throw error;
        });
      }
      showMessage({
        severity: "error",
        content: errors.map((error) => error.message).join("; "),
      });
      throw new AbortError();
    }

    return result.data;
  };

  action.loading = loading;
  action.error = error;
  return action;
}
