// difine spacing between any neibouring elements
export const SPACING = "20px";
export const SPACING_HALF = "10px";

// smaller spacing for elemenst inside text flow
export const SPACING_DENSE = "8px";
export const SPACING_DENSE_HALF = "4px";

// even smaller spacing for extreeeme space saving
export const SPACING_VERY_DENSE = "2px";
export const SPACING_VERY_DENSE_HALF = "2px";

// padding for the page content container
export const SPACING_PADDING = 20;
