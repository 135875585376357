import { gql } from "@apollo/client";
import { FormControl, FormControlLabel, FormHelperText, Switch } from "@mui/material";
import useActionFragment from "helpers/useActionFragment";
import useData from "helpers/useData";
import useShowMessage from "helpers/useShowMessage";
import Section from "molecules/Section";
import React from "react";

export default function PersonSettings({ personId }) {
  const [data] = useData(
    gql`
      query PersonSettings($personId: ID!) {
        person(id: $personId) {
          id
          anonymised
        }
      }
    `,
    { personId },
  );

  const onsenPersonUpdate = useActionFragment("onsenPersonUpdate", "person { id anonymised }");

  const showMessage = useShowMessage();

  return (
    <>
      <Section title="Privacy">
        <FormControl>
          <FormControlLabel
            label="Hide my name publicly"
            control={
              <Switch
                checked={!!data?.person?.anonymised}
                onChange={async (event) => {
                  await onsenPersonUpdate({
                    input: {
                      personId,
                      anonymised: event.target.checked,
                    },
                  });
                  showMessage("Privacy settings updated");
                }}
              />
            }
          />
          <FormHelperText>
            Select this if you want to hide your name from other users in Glory League player portal.
          </FormHelperText>
        </FormControl>
      </Section>
    </>
  );
}
