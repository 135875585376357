import { gql } from "@apollo/client";
import { ButtonBase, IconButton } from "@mui/material";
import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import useData from "helpers/useData";
import { CloseCircle } from "mdi-material-ui";
import getCompetitionStyle from "pages/potw/getCompetitionStyle";
import React, { useState } from "react";

export default function VideoPotwCompetitionButton({ gameId }) {
  const [hidden, hiddenSet] = useState(false);
  const [data] = useData(
    gql`
      query VideoPotwCompetitionButton($gameId: ID!) {
        game(id: $gameId) {
          id
          livePotwCompetition {
            id
            name
            competitionType
            startDate
            endDate
          }
        }
      }
    `,
    { gameId },
  );
  const livePotwCompetition = data?.game.livePotwCompetition;

  if (!livePotwCompetition || hidden) return null;
  const competitionStyle = getCompetitionStyle(livePotwCompetition.competitionType);

  return (
    <ButtonBase
      href={`/potw/competitions/${livePotwCompetition.id}`}
      style={{
        backgroundColor: COLOR_BLACK,
        color: COLOR_WHITE,
        backgroundImage: competitionStyle?.backgroundImage && `url(${competitionStyle.backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
        fontWeight: 500,
        display: "flex",
        flexFlow: "row nowrap",
        padding: 5,
        gap: 10,
        alignItems: "center",
      }}
    >
      <img src={competitionStyle.logoImage} alt={competitionStyle.title} style={{ height: "3em" }} />
      <div>
        <div
          style={{
            color: competitionStyle.color,
          }}
        >
          {competitionStyle.title}
        </div>
        <div style={{ fontSize: "1.5em" }}>{livePotwCompetition.name}</div>
        <div>Rate your best highlights!</div>
      </div>
      <IconButton
        size="large"
        color="inherit"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          hiddenSet(true);
        }}
      >
        <CloseCircle />
      </IconButton>
    </ButtonBase>
  );
}
