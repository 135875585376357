import { gql } from "@apollo/client";
import useData from "helpers/useData";
import React from "react";
import { Helmet } from "react-helmet";
import NotFoundPage from "shared/NotFoundPage";

import HighlightPlayer, { HighlightPlayerFragment } from "./video_player/HighlightPlayer";

export default function EventPage({ eventViewId }) {
  const [data] = useData(
    gql`
      query EventPage($eventViewId: ID!) {
        eventView(id: $eventViewId) {
          id
          title
          ...HighlightPlayerFragment
        }
      }
      ${HighlightPlayerFragment}
    `,
    {
      eventViewId,
    },
  );
  if (data && !data.eventView) return <NotFoundPage />;

  return (
    <>
      <Helmet>
        <title>{data?.eventView?.title}</title>
      </Helmet>
      <HighlightPlayer eventView={data?.eventView} eventViewId={eventViewId} />
    </>
  );
}
