import { gql } from "@apollo/client";
import { useEffect } from "react";

import useData from "./useData";
import useGotoUrl from "./useGotoUrl";

export default function useEnforceAuth({ enforceUnauthenticated = false, enforceAuthenticated = false } = {}) {
  const [data, dataMeta] = useData(
    gql`
      query useEnforceAuth {
        currentUser {
          id
        }
      }
    `,
    null,
    {
      skip: !enforceUnauthenticated && !enforceAuthenticated,
    },
  );

  const gotoUrl = useGotoUrl();

  useEffect(() => {
    if (data?.currentUser && enforceUnauthenticated) {
      let redirectToPath = location.search.match(/redirect=([^&]+)/)?.[1];
      if (redirectToPath) redirectToPath = decodeURIComponent(redirectToPath);
      redirectToPath = redirectToPath || "/";
      gotoUrl(redirectToPath, { replace: true });
    }
    if (data && !data.currentUser && enforceAuthenticated) {
      let onboardToken = location.search.match(/onboardToken=([^&]+)/)?.[1];
      let redirectToPath = location.pathname + location.search + location.hash;
      if (onboardToken) {
        redirectToPath = redirectToPath.replace(/onboardToken=[^&]+/, "");
        gotoUrl(`/configure-password?onboardToken=${onboardToken}&redirect=${encodeURIComponent(redirectToPath)}`, {
          replace: true,
        });
      } else {
        gotoUrl(`/login?redirect=${encodeURIComponent(redirectToPath)}`, { replace: true });
      }
    }
  }, [data]);

  const enforceAuth = {
    loading: !data,
    refetch: dataMeta.refetch,
  };
  return enforceAuth;
}
