import React from "react";

export default function GameTitle({ homeTeamName = "Home", awayTeamName = "Away", dense = false, ...others }) {
  return (
    <span
      style={{
        fontWeight: "bold",
        fontSize: dense ? ".9em" : "1em",
        ...others,
      }}
    >
      {homeTeamName}
      <span
        style={{
          fontWeight: "normal",
          fontSize: ".8em",
        }}
      >
        {" "}
        vs{" "}
      </span>
      {awayTeamName}
    </span>
  );
}
