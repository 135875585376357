import { gql } from "@apollo/client";
import { ButtonBase, IconButton, Paper } from "@mui/material";
import schickBanner350x160Jpg from "assets/schick/schick_banner_350x160.jpg";
import schickBanner740x120Jpg from "assets/schick/schick_banner_740x120.jpg";
import schickBanner1280x120Jpg from "assets/schick/schick_banner_1280x120.jpg";
import { SCHICK_BANNER_ENABLED } from "helpers/constants";
import useData from "helpers/useData";
import { CloseCircle } from "mdi-material-ui";
import React, { useEffect, useState } from "react";
import { useWindowSize } from "react-use-size";

export default function SchickBanner() {
  const windowSize = useWindowSize();
  const [hovering, hoveringSet] = useState(false);
  const imgSrc = !hovering
    ? schickBanner350x160Jpg
    : windowSize.width < 740 + 30
      ? schickBanner350x160Jpg
      : windowSize.width < 1280 + 30
        ? schickBanner740x120Jpg
        : schickBanner1280x120Jpg;
  const [closed, closedSet] = useState(true);

  const [data] = useData(
    gql`
      query SchickBanner {
        currentUser {
          id
          schickBannerEnabled
        }
      }
    `,
    {},
    { skip: !SCHICK_BANNER_ENABLED },
  );

  useEffect(() => {
    if (data?.currentUser?.schickBannerEnabled) {
      closedSet(false);
    }
  }, [data?.currentUser?.id]);

  return (
    <Paper
      component={ButtonBase}
      href="https://www.everydayballers.basketball/"
      target="_blank"
      onMouseEnter={() => hoveringSet(true)}
      onMouseLeave={() => hoveringSet(false)}
      style={{
        position: "fixed",
        zIndex: 1,
        right: 15,
        bottom: 15,
        display: "flex",
        overflow: "hidden",
        transition: "opacity 0.5s",
        ...(closed && {
          opacity: 0,
          pointerEvents: "none",
        }),
      }}
      elevation={10}
    >
      <IconButton
        onClick={(event) => {
          event.preventDefault();
          closedSet(true);
        }}
        size="small"
        style={{
          position: "absolute",
          zIndex: 1,
          top: 0,
          right: 0,
        }}
      >
        <CloseCircle />
      </IconButton>
      <img src={imgSrc} alt="Schick Banner" style={{ height: 120 }} />
    </Paper>
  );
}
