import React from "react";

import Dialog, { DialogActionButton } from "./Dialog";

export default function FormDialog({
  title,
  inverse = false,
  disabled = false,
  open,
  onClose,
  children,
  submitButtonLabel = "Submit",
  onSubmit,
  actions,
  ...others
}) {
  return (
    <Dialog
      title={title}
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();
          if (!onSubmit) return;
          await onSubmit(event);
          onClose();
        },
      }}
      inverse={inverse}
      actions={
        <>
          {!!onSubmit && <DialogActionButton primary disabled={disabled} type="submit" label={submitButtonLabel} />}
          <DialogActionButton onClick={onClose} label="Cancel" />
          {actions}
        </>
      }
      {...others}
    >
      {children}
    </Dialog>
  );
}
