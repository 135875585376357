import { gql } from "@apollo/client";
import Stack from "atoms/Stack";
import { getStatColor } from "helpers/colors";
import { FONT_FAMILY_PATHWAY_GOTHIC_ONE } from "helpers/FONT";
import useData from "helpers/useData";
import StatValue, { StatValueFragment } from "molecules/StatValue";
import { videoPageContext } from "pages/VideoPage";
import React, { useContext } from "react";

import { videoPageLayoutContext } from "./VideoPageLayout";

export default function VideoActivePlayerStats() {
  const { activeEventId } = useContext(videoPageContext);
  const { isPortrait } = useContext(videoPageLayoutContext);

  const [data] = useData(
    gql`
      query VideoActivePlayerStats($eventId: ID!) {
        event(id: $eventId) {
          id
          gamePlayer {
            id
            playerNumber
            person {
              id
              shortNameWithAnonymisation
            }
            overviewStats {
              shortName
              name
              ...StatValueFragment
            }
          }
        }
      }
      ${StatValueFragment}
    `,
    { eventId: activeEventId },
    { skip: !activeEventId },
  );

  if (isPortrait) {
    return null;
  }

  return (
    <Stack
      veryDense
      padding
      style={{
        flex: "1 1 150px",
        fontFamily: FONT_FAMILY_PATHWAY_GOTHIC_ONE,
        fontWeight: "bold",
        fontSize: 14,
        ...(!data?.event?.gamePlayer && {
          visibility: "hidden",
        }),
      }}
    >
      <div>
        {data?.event?.gamePlayer?.person?.shortNameWithAnonymisation} #{data?.event?.gamePlayer?.playerNumber}
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
        }}
      >
        {data?.event?.gamePlayer?.overviewStats.map((stat) => (
          <Stack
            veryDense
            alignItemsCenter
            key={stat.shortName}
            style={{
              color: getStatColor(stat.name),
            }}
          >
            <StatValue stat={stat} />
            <div>{stat.shortName}</div>
          </Stack>
        ))}
      </div>
    </Stack>
  );
}
