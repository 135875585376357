import { darken, lighten } from "@mui/material";
import { COLOR_BLACK, COLOR_PRIMARY, COLOR_WHITE } from "helpers/colors";
import React from "react";

export default function PlayerAvatar({
  avatarUrl,
  color,
  inverse = false,
  active = false,
  playerNumber,
  alt,
  size = 32,
  ...others
}) {
  color ||= COLOR_PRIMARY;
  let textColor = inverse ? COLOR_BLACK : COLOR_WHITE;
  let textStrokeColor = inverse ? lighten(color, 0.2) : lighten(color, 0.4);
  let backgroundColor = inverse ? darken(color, 0.4) : lighten(color, 0.8);

  if (active) {
    if (inverse) {
      backgroundColor = lighten(backgroundColor, 0.3);
      textStrokeColor = lighten(textStrokeColor, 0.3);
    } else {
      backgroundColor = darken(backgroundColor, 0.3);
      textStrokeColor = darken(textStrokeColor, 0.3);
    }
  }

  return (
    <div
      {...others}
      style={{
        height: size,
        width: size,
        borderRadius: "50%",
        overflow: "hidden",
        backgroundColor,
        color: textColor,
        fontWeight: "bold",
        textShadow: [
          `1px 0 ${textStrokeColor}`,
          `0 1px ${textStrokeColor}`,
          `-1px 0 ${textStrokeColor}`,
          `0 -1px ${textStrokeColor}`,
        ].join(", "),
        ...others.style,
      }}
    >
      {avatarUrl ? (
        <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={avatarUrl} alt={alt} />
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: size * 0.5,
          }}
        >
          {playerNumber || "?"}
        </div>
      )}
    </div>
  );
}
