import { gql } from "@apollo/client";
import { PageContentStack } from "atoms/Stack";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import useRouteState from "helpers/useRouteState";
import { SubnavToggleLabel } from "molecules/Subnav";
import React, { useState } from "react";

import LeaderboardTableSegment, { LeaderboardTableSegmentFragment } from "./leaderboard/LeaderboardTableSegment";
import TeamPagesHeader from "./team/TeamPagesHeader";
import TeamSeasonSelectSubnav from "./team/TeamSeasonSelectSubnav";

export default function TeamLeaderboardPage({ teamId }) {
  useEnforceAuth({ enforceAuthenticated: true });

  const [seasonId, seasonIdSet] = useRouteState("seasonId");
  const [data] = useData(
    gql`
      query TeamLeaderboardPage($teamId: ID!, $seasonId: ID) {
        team(id: $teamId) {
          id
          leaderboardTableSegments(seasonId: $seasonId) {
            title
            ...LeaderboardTableSegmentFragment
          }
        }
      }
      ${LeaderboardTableSegmentFragment}
    `,
    { teamId, seasonId },
  );
  const [displaySegmentTitle, displaySegmentTitleSet] = useState(null);

  return (
    <>
      <TeamPagesHeader teamId={teamId} tab="leaderboard" />
      <TeamSeasonSelectSubnav teamId={teamId} seasonId={seasonId} seasonIdSet={seasonIdSet} allowAllSeasons>
        <SubnavToggleLabel
          checked={!displaySegmentTitle}
          onClick={() => displaySegmentTitleSet(null)}
          text="Overview"
        />
        {data?.team?.leaderboardTableSegments.map((leaderboardTableSegment) => (
          <SubnavToggleLabel
            key={leaderboardTableSegment.title}
            checked={displaySegmentTitle === leaderboardTableSegment.title}
            onClick={() => displaySegmentTitleSet(leaderboardTableSegment.title)}
            text={leaderboardTableSegment.title}
          />
        ))}
      </TeamSeasonSelectSubnav>

      <PageContentStack>
        {data?.team?.leaderboardTableSegments
          .filter((s) => !displaySegmentTitle || s.title === displaySegmentTitle)
          .map((leaderboardTableSegment) => (
            <LeaderboardTableSegment
              key={leaderboardTableSegment.title}
              leaderboardTableSegment={leaderboardTableSegment}
              collapsible={!displaySegmentTitle}
            />
          ))}
      </PageContentStack>
    </>
  );
}
