import { COLOR_SECONDARY } from "helpers/colors";
import IntroBanner from "molecules/IntroBanner";
import React from "react";

export default function PotwIntroBanner() {
  return (
    <IntroBanner
      title="Plays of the Week"
      description="Get your plays in the running for the ultimate bragging rights."
      learnMoreDialogContent={
        <>
          <p>
            <b>Nominate and rate your favourite game highlights! Here’s how:</b>
          </p>
          <ul>
            <li>
              <b>Nominate:</b> Click on any highlight in an eligible game video to rate it. When you rate a highlight it
              is automatically nominated for Plays of the Week.
            </li>
            <li>
              <b>Vote:</b> Watch other highlights from across your association and vote for your favourites.
            </li>
          </ul>
          <p>
            You have 7 days after your game was played to vote. Look for the Plays of the Week symbol next to eligible
            games on the games page to see which are open for voting!
          </p>
          <p>
            Find out more{" "}
            <a
              href="https://www.gloryleague.basketball/support/plays-of-the-week-how-it-works"
              style={{ color: COLOR_SECONDARY, textDecoration: "none" }}
            >
              here
            </a>
            .
          </p>
        </>
      }
    />
  );
}
