import { ONSEN_ENV, RECAPTCHA_SITE_KEY } from "./environment";
import { progressBar } from "./useProgressBar";

export default async function recaptcha(action = "submit") {
  return await progressBar(async () => {
    if (ONSEN_ENV === "test") return "test-recaptcha-token";
    await new Promise((resolve) => window.grecaptcha.ready(resolve));

    const token = window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action });
    return token;
  });
}
