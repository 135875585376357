import { gql } from "@apollo/client";
import { ButtonBase } from "@mui/material";
import Stack, { PageContentStack } from "atoms/Stack";
import { COLOR_BLACK_LIGHTER, COLOR_PRIMARY, COLOR_WHITE, TEAM_COLORS } from "helpers/colors";
import useAction from "helpers/useAction";
import useData from "helpers/useData";
import Section from "molecules/Section";
import Toolbar, { ToolbarButton } from "molecules/Toolbar";
import React, { useEffect, useState } from "react";

export default function TeamChangeLogo({ open, onClose, teamId }) {
  const [load, loadSet] = useState(false);
  const [data] = useData(
    gql`
      query TeamChangeLogo($teamId: ID!) {
        team(id: $teamId) {
          id
          colour
          logo
        }
        teamColours
      }
    `,
    { teamId },
    { skip: !load },
  );
  const [dataColour] = useData(
    gql`
      query TeamChangeLogo_Colour($colour: String!) {
        teamLogos(colour: $colour) {
          name
          url
        }
      }
    `,
    { colour: data?.team.colour },
    { skip: !data?.team.colour },
  );

  const teamUpdateLogo = useAction(gql`
    mutation TeamChangeLogo($input: TeamUpdateLogoInput!) {
      teamUpdateLogo(input: $input) {
        team {
          id
          colour
          logo
          logoUrl
        }
      }
    }
  `);

  useEffect(() => {
    if (open) loadSet(true);
  }, [open]);

  return (
    <div
      style={{
        backgroundColor: TEAM_COLORS[data?.team.colour] || COLOR_PRIMARY,
        color: COLOR_WHITE,
      }}
    >
      <PageContentStack>
        <Section title="Choose team colour">
          <Stack horizontal>
            {data?.teamColours.map((colour) => (
              <ButtonBase
                onClick={() =>
                  teamUpdateLogo({
                    input: {
                      teamId,
                      colour: colour,
                    },
                  })
                }
                title={colour}
                key={colour}
                style={{
                  border: `solid 2px ${colour === data?.team.colour ? COLOR_WHITE : COLOR_BLACK_LIGHTER}`,
                  backgroundColor: TEAM_COLORS[colour],
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                }}
              ></ButtonBase>
            ))}
          </Stack>
        </Section>
        <Section title="Choose team logo">
          <Stack horizontal>
            {dataColour?.teamLogos.map((teamLogo) => (
              <ButtonBase
                onClick={() =>
                  teamUpdateLogo({
                    input: {
                      teamId,
                      logo: teamLogo.name,
                    },
                  })
                }
                key={teamLogo.name}
              >
                <img
                  src={teamLogo.url}
                  alt={teamLogo.name}
                  style={{
                    opacity: teamLogo.name === data?.team.logo ? 1 : 0.3,
                    width: 80,
                    height: 80,
                    objectFit: "contain",
                  }}
                />
              </ButtonBase>
            ))}
          </Stack>
        </Section>
        <Toolbar>
          <ToolbarButton label="Close" onClick={onClose} />
        </Toolbar>
      </PageContentStack>
    </div>
  );
}
