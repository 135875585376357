import { Help, Lock, LockOpenVariant, Star } from "mdi-material-ui";
import React, { cloneElement } from "react";

export default function PlaylistIcon({ playlistType, ...others }) {
  const icon = {
    starred: <Star />,
    default: <Lock />,
    shared: <LockOpenVariant />,
  }[playlistType] || <Help />;

  return cloneElement(icon, others);
}
