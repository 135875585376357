import { defer } from "lodash-es";
import React, { cloneElement } from "react";
import ReactDOM from "react-dom/client";

const _refs = {};

export default function useSingleton(key, element) {
  if (!_refs[key]) {
    const container = document.createElement("div");
    const ref = React.createRef();
    document.body.parentElement.append(container);
    defer(() => {
      ReactDOM.createRoot(container).render(cloneElement(element, { ref: ref }));
    });

    _refs[key] = ref;
  }

  return _refs[key];
}
