import { gql } from "@apollo/client";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from "@mui/material";
import AbortError from "helpers/AbortError";
import useActionFragment from "helpers/useActionFragment";
import useData from "helpers/useData";
import useGotoUrl from "helpers/useGotoUrl";
import useShowMessage from "helpers/useShowMessage";
import FormDialog from "molecules/FormDialog";
import Message from "molecules/Message";
import Section from "molecules/Section";
import Toolbar, { ToolbarButton } from "molecules/Toolbar";
import React, { useLayoutEffect, useState } from "react";

export default function PersonAccount({ personId }) {
  const [data] = useData(
    gql`
      query PersonAccount($personId: ID!) {
        person(id: $personId) {
          id
        }
        currentUser {
          id
          email
          peopleCount
        }
      }
    `,
    { personId },
  );

  const [transferProfileDialogOpen, transferProfileDialogOpenSet] = useState(false);

  return (
    <Section title="Account">
      <div>
        Your profile is associated with the account <b>{data?.currentUser?.email}</b>. You can change its settings in
        the <a href="/account">account settings</a>.
      </div>
      {data?.currentUser?.peopleCount > 1 && (
        <>
          <div>Want to transfer this profile to another account?</div>
          <Toolbar>
            <ToolbarButton label="Transfer Profile" onClick={() => transferProfileDialogOpenSet(true)} />
          </Toolbar>
        </>
      )}
      <TransferProfileDialog
        personId={personId}
        open={transferProfileDialogOpen}
        onClose={() => transferProfileDialogOpenSet(false)}
      />
    </Section>
  );
}

export const ERROR_MESSAGE_EMAILS_DO_NOT_MATCH = "Emails do not match";

function TransferProfileDialog({ personId, open, onClose }) {
  const gotoUrl = useGotoUrl();
  const [data] = useData(
    gql`
      query TransferProfileDialog($personId: ID!) {
        person(id: $personId) {
          id
          fullName
        }
      }
    `,
    { personId },
    { skip: !open },
  );

  const [newEmail, newEmailSet] = useState("");
  const [confirmEmail, confirmEmailSet] = useState("");
  const [supporterFirstName, supporterFirstNameSet] = useState("");
  const [supporterLastName, supporterLastNameSet] = useState("");
  const [remainSupporter, remainSupporterSet] = useState(false);
  const onsenPersonChangeEmail = useActionFragment(
    "onsenPersonChangeEmail",
    "currentUser { id people { id } peopleCount }",
  );

  useLayoutEffect(() => {
    newEmailSet("");
    confirmEmailSet("");
    remainSupporterSet(false);
  }, [open]);

  const confirmEmailError = newEmail && confirmEmail && confirmEmail !== newEmail && ERROR_MESSAGE_EMAILS_DO_NOT_MATCH;
  const showMessage = useShowMessage();

  return (
    <FormDialog
      title="Transfer Profile"
      open={open}
      onClose={onClose}
      onSubmit={async () => {
        if (confirmEmailError) throw new AbortError(confirmEmailError);
        await onsenPersonChangeEmail({
          input: {
            personId,
            newEmail,
            ...(remainSupporter && {
              remainSupporter: true,
              supporterFirstName,
              supporterLastName,
            }),
          },
        });
        showMessage("Profile transferred");
        if (remainSupporter) await gotoUrl(`/person/${personId}`);
        else await gotoUrl("/people");
      }}
    >
      <Message>
        You are transfering this profile (<b>{data?.person?.fullName}</b>) to another account.
      </Message>
      <TextField
        label="New Account Email"
        type="email"
        autoComplete="email"
        value={newEmail}
        onChange={(event) => newEmailSet(event.target.value)}
        required
      />
      <TextField
        label="Confirm Email"
        type="email"
        autoComplete="email"
        required
        value={confirmEmail}
        onChange={(event) => confirmEmailSet(event.target.value)}
        error={!!confirmEmailError}
        helperText={confirmEmailError}
      />
      <FormControl>
        <FormControlLabel
          label="Keep me as a supporter for this profile"
          control={
            <Checkbox checked={remainSupporter} onChange={(event) => remainSupporterSet(event.target.checked)} />
          }
        />
        <FormHelperText>Select this if you want to keep your access to videos of this profile.</FormHelperText>
      </FormControl>
      {remainSupporter && (
        <>
          <Message>We will create a new profile as supporter for the current profile.</Message>
          <TextField
            label="Your First Name"
            value={supporterFirstName}
            onChange={(event) => supporterFirstNameSet(event.target.value)}
            required
          />
          <TextField
            label="Your Last Name"
            value={supporterLastName}
            onChange={(event) => supporterLastNameSet(event.target.value)}
          />
        </>
      )}
    </FormDialog>
  );
}
