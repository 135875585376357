import { gql } from "@apollo/client";
import Stack from "atoms/Stack";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { sum } from "lodash-es";
import TeamAvatar, { TeamAvatarFragment } from "molecules/TeamAvatar";
import { videoPageContext } from "pages/VideoPage";
import React, { useContext } from "react";
import { useWindowSize } from "react-use-size";

export const VideoHeaderFragment = gql`
  fragment VideoHeaderFragment on Game {
    id
    home {
      id
      name
      ...TeamAvatarFragment
    }
    away {
      id
      name
      ...TeamAvatarFragment
    }
    periods {
      number
      name
    }
    events {
      id
      pointsScored
      occurredAtSeconds
      team {
        id
      }
    }
  }
  ${TeamAvatarFragment}
`;

export default function VideoHeader({ game }) {
  const teamNameStyle = {
    flex: "1 1 100px",
    fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
    textTransform: "uppercase",
  };

  const scoreStyle = {
    fontSize: "1.5em",
    fontWeight: "bold",
  };

  const { currentTime, currentPeriodNumber } = useContext(videoPageContext);

  const homeScore = sum(
    game?.events
      .filter((e) => e.team?.id === game.home.id && e.occurredAtSeconds <= currentTime)
      .map((e) => e.pointsScored),
  );

  const awayScore = sum(
    game?.events
      .filter((e) => e.team?.id === game.away.id && e.occurredAtSeconds <= currentTime)
      .map((e) => e.pointsScored),
  );
  const windowSize = useWindowSize();
  const size = Math.min(32, windowSize.height / 20);

  return (
    <Stack style={{ flex: "100 1 auto", fontSize: size * 0.6 }} horizontal noWrap alignItemsCenter padding>
      <TeamAvatar team={game?.home} size={size} />
      <div style={teamNameStyle}>{game?.home.name}</div>
      <div style={scoreStyle}>{homeScore}</div>
      <div
        style={{
          fontSize: "0.8em",
          opacity: 0.8,
        }}
      >
        {game?.periods.find((p) => p.number === currentPeriodNumber)?.name}
      </div>
      <div style={scoreStyle}>{awayScore}</div>
      <div
        style={{
          textAlign: "end",
          ...teamNameStyle,
        }}
      >
        {game?.away.name}
      </div>
      <TeamAvatar team={game?.away} size={size} />
    </Stack>
  );
}
