import { gql } from "@apollo/client";
import { ButtonBase, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import logoWhiteSvg from "assets/logoWhite.svg";
import apolloClient from "helpers/apolloClient";
import { COLOR_PRIMARY, COLOR_SECONDARY } from "helpers/colors";
import { ONSEN_AUTH_TOKEN_KEY } from "helpers/constants";
import rollbar from "helpers/rollbar";
import { SPACING, SPACING_DENSE, SPACING_HALF } from "helpers/spacings";
import useData from "helpers/useData";
import usePlaylistsEnabled from "helpers/usePlaylistsEnabled";
import { progressBar } from "helpers/useProgressBar";
import {
  AccountCircle,
  AccountCog,
  AccountGroup,
  AccountMultiple,
  Basketball,
  ClipboardPlayMultiple,
  Logout,
  Refresh,
  SwapHorizontal,
  Trophy,
} from "mdi-material-ui";
import React, { cloneElement, createContext, useContext, useEffect, useRef, useState } from "react";
import { useComponentSize } from "react-use-size";

const navbarContext = createContext();

export default function Navbar() {
  const [data] = useData(gql`
    query NavbarCurrentUser {
      currentUser {
        id
        onboarded
        isPublicLeaguePersona
        potwEnabled
        canAccessReadmin
        email
      }
      readminUrl
    }
  `);
  const componentSize = useComponentSize();
  const navbarWidth = componentSize.width;
  const playlistsEnabled = usePlaylistsEnabled();
  const currentUserId = data?.currentUser?.id;
  const currentUserEmail = data?.currentUser?.email;

  useEffect(() => {
    rollbar.configure({
      payload: {
        person: {
          id: currentUserId,
          email: currentUserEmail,
        },
      },
    });
  }, [currentUserId, currentUserEmail]);

  return (
    <navbarContext.Provider value={{ navbarWidth }}>
      <div
        ref={componentSize.ref}
        style={{
          background: COLOR_PRIMARY, //standard navbar colour
          display: "flex",
          color: "white",
          flexFlow: "row nowrap",
          alignItems: "stretch",
        }}
      >
        <HomeLogo />
        <NavbarItem label="Games" icon={<Basketball />} href="/games" />
        <NavbarItem label="Teams" icon={<AccountGroup />} href="/teams" />
        {data?.currentUser?.potwEnabled && !data.currentUser.isPublicLeaguePersona && (
          <NavbarItem label="Plays of the Week" icon={<Trophy />} href="/potw" />
        )}
        {playlistsEnabled && <NavbarItem label="Playlists" icon={<ClipboardPlayMultiple />} href="/playlists" />}
        <div style={{ flex: "1 1 auto" }} />
        {data?.currentUser && !data?.currentUser?.isPublicLeaguePersona && (
          <>
            <NavbarMenu icon={<AccountCircle />} label={data.currentUser.email}>
              {data.currentUser.canAccessReadmin && (
                <NavbarMenuItem icon={<SwapHorizontal />} label="Switch to Admin Console" href={data.readminUrl} />
              )}
              <NavbarMenuItem label="My Profiles" icon={<AccountMultiple />} href="/people" />
              <NavbarMenuItem
                label={data?.currentUser?.onboarded ? "My Account & Settings" : "Set up your Account"}
                icon={<AccountCog />}
                href="/account"
              />
              <NavbarMenuItem
                label="Logout"
                icon={<Logout />}
                onClick={() =>
                  progressBar(async () => {
                    window.localStorage.removeItem(ONSEN_AUTH_TOKEN_KEY);
                    await apolloClient.resetStore();
                  })
                }
              />
            </NavbarMenu>
          </>
        )}
        {data && !data.currentUser && <NavbarItem label="Log in" href="/login" />}
        <IconButton
          className="progressbar-spinner"
          color="inherit"
          onClick={() => progressBar(() => apolloClient.refetchQueries({ include: "active" }))}
        >
          <Refresh />
        </IconButton>
      </div>
    </navbarContext.Provider>
  );
}

function HomeLogo() {
  const { navbarWidth } = useContext(navbarContext);
  const [data] = useData(gql`
    query HomeLogo {
      currentUser {
        id
        isPublicLeaguePersona
        publicLeagueTitle
        publicLeagueLogo
      }
    }
  `);

  return (
    <a
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        fontSize: 16,
        padding: SPACING_HALF,
        gap: SPACING,
        background: COLOR_SECONDARY,
        textDecoration: "none",
        color: "inherit",
      }}
      href="/"
    >
      {data?.currentUser?.isPublicLeaguePersona ? (
        <>
          <img
            src={data?.currentUser.publicLeagueLogo}
            style={{
              flex: "none",
              width: 50,
            }}
          />
          {navbarWidth > 1000 && (
            <span
              style={{
                fontSize: "1.1em",
                flex: "auto",
                fontWeight: "bold",
              }}
            >
              {data?.currentUser.publicLeagueTitle}
            </span>
          )}
        </>
      ) : (
        <img src={logoWhiteSvg} alt="Glory League Logo" />
      )}
    </a>
  );
}

function NavbarItem({ label, href, icon = null }) {
  const { navbarWidth } = useContext(navbarContext);
  const [hovering, hoveringSet] = useState(false);
  return (
    <>
      <ButtonBase
        focusRipple
        onMouseEnter={() => hoveringSet(true)}
        onMouseLeave={() => hoveringSet(false)}
        style={{
          outline: "initial",
          display: "flex",
          alignItems: "center",
          padding: SPACING_HALF,
          gap: SPACING_DENSE,
          fontSize: 14,
          flex: "initial",
        }}
        href={href}
      >
        {icon &&
          cloneElement(icon, {
            style: {
              fontSize: "1.5em",
              ...(hovering && {
                opacity: 0.8,
              }),
            },
          })}
        {navbarWidth > 600 && (
          <span
            style={{
              fontWeight: 500,
              ...(hovering && {
                opacity: 0.8,
              }),
            }}
          >
            {label}
          </span>
        )}
      </ButtonBase>
    </>
  );
}

function NavbarMenu({ icon, label, children }) {
  const { navbarWidth } = useContext(navbarContext);
  const [open, openSet] = useState(false);
  const ref = useRef();

  return (
    <>
      <ButtonBase
        ref={ref}
        focusRipple
        style={{
          display: "flex",
          padding: SPACING_HALF,
          alignItems: "center",
          flex: "none",
          fontSize: "14px",
          cursor: "pointer",
          gap: SPACING_DENSE,
        }}
        onClick={() => openSet(true)}
      >
        {icon &&
          cloneElement(icon, {
            style: {
              fontSize: "1.6em",
            },
          })}
        {/* probably a tablet */}
        {navbarWidth > 1000 && label}
      </ButtonBase>
      <Menu
        variant="menu"
        anchorEl={ref.current}
        open={open}
        onClose={() => openSet(false)}
        onClick={() => openSet(false)}
      >
        {children}
      </Menu>
    </>
  );
}

function NavbarMenuItem({ label, icon, href, onClick }) {
  return (
    <MenuItem style={{ fontSize: "14px" }} component="a" href={href || "#"} onClick={onClick}>
      <ListItemIcon>
        {icon &&
          cloneElement(icon, {
            style: {
              fontSize: "1.3em",
              color: "rgba(white, .4)",
            },
          })}
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ fontSize: "1em" }}>{label}</ListItemText>
    </MenuItem>
  );
}
