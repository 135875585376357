import { gql } from "@apollo/client";
import { PageContentStack } from "atoms/Stack";
import useData from "helpers/useData";
import React from "react";
import { Helmet } from "react-helmet";
import NotFoundPage from "shared/NotFoundPage";

import PersonAccount from "./person/PersonAccount";
import PersonPagesHeader from "./person/PersonPagesHeader";
import PersonProfile from "./person/PersonProfile";
import PersonSettings from "./person/PersonSettings";
import PersonSupporters from "./person/PersonSupporters";

export default function PersonSettingsPage({ personId }) {
  const [data] = useData(
    gql`
      query PersonSettingsPage($personId: ID!) {
        person(id: $personId) {
          id
          fullNameWithAnonymisation
        }
      }
    `,
    { personId },
  );

  if (data && !data.person) return <NotFoundPage />;
  return (
    <>
      <Helmet>
        <title>{data?.person?.fullNameWithAnonymisation}</title>
      </Helmet>
      <PersonPagesHeader personId={personId} tab="settings" />
      <PageContentStack>
        <PersonProfile personId={personId} />
        <PersonSupporters personId={personId} />
        <PersonSettings personId={personId} />
        <PersonAccount personId={personId} />
      </PageContentStack>
    </>
  );
}
