import { remove } from "lodash-es";
import { useEffect, useState } from "react";

const SAVED_VIDEO_VOLUME_KEY = "gloryleague_saved_video_volume";

const _setters = [];

export const useVideoVolumn = () => {
  const [volume, volumeSet] = useState(() => {
    let savedVolume = parseFloat(localStorage.getItem(SAVED_VIDEO_VOLUME_KEY));
    if (Number.isNaN(savedVolume)) savedVolume = 30; // default, non-intrusive volume
    return savedVolume;
  });

  useEffect(() => {
    _setters.push(volumeSet);
    return () => {
      remove(_setters, volumeSet);
    };
  }, []);

  const volumeSetWrapped = (value) => {
    localStorage.setItem(SAVED_VIDEO_VOLUME_KEY, value);
    for (const setter of _setters) {
      setter(value);
    }
  };

  return [volume, volumeSetWrapped];
};
