import { gql } from "@apollo/client";
import { Divider } from "@mui/material";
import gtag from "helpers/gtag";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import { Plus } from "mdi-material-ui";
import Message from "molecules/Message";
import Toolbar, { ToolbarButton } from "molecules/Toolbar";
import CreatePlaylistDialog from "pages/playlists/CreatePlaylistDialog";
import PlaylistsIntroBanner from "pages/playlists/PlaylistsIntroBanner";
import PlaylistSummary, { PlaylistSummaryFragment } from "pages/playlists/PlaylistSummary";
import React, { Fragment, useState } from "react";

export default function PlaylistsPage() {
  useEnforceAuth({ enforceAuthenticated: true });

  const [creating, creatingSet] = useState(false);
  const [data] = useData(gql`
    query PlaylistsPage {
      currentUser {
        id
        playlistsMaximum
        playlistsMaximumReached
        playlists {
          id
          ...PlaylistSummaryFragment
        }
        starredPlaylist {
          id
          ...PlaylistSummaryFragment
        }
      }
    }
    ${PlaylistSummaryFragment}
  `);

  return (
    <>
      <PlaylistsIntroBanner />
      <div
        style={{
          padding: 20,
          display: "flex",
          flexFlow: "column nowrap",
          gap: 20,
        }}
      >
        <CreatePlaylistDialog
          open={creating}
          onPlaylistCreated={() => {
            gtag("event", "playlist_create", {
              location: "home",
            });
          }}
          onClose={() => creatingSet(false)}
        />
        {[data?.currentUser?.starredPlaylist, ...(data?.currentUser?.playlists || [])]
          .filter(Boolean)
          .map((playlist) => (
            <Fragment key={playlist.id}>
              <PlaylistSummary playlist={playlist} />
              <Divider />
            </Fragment>
          ))}
        <Toolbar>
          <ToolbarButton
            icon={<Plus />}
            label={`New Playlist (${data?.currentUser?.playlists?.length ?? "?"}/${data?.currentUser?.playlistsMaximum ?? "?"})`}
            disabled={data?.currentUser?.playlistsMaximumReached}
            onClick={() => creatingSet(true)}
          />
        </Toolbar>
        {data?.currentUser.playlistsMaximumReached && (
          <Message>You have reached the maximum number of playlists. Delete unwanted ones to free up space</Message>
        )}
      </div>
    </>
  );
}
