import { Alert, Snackbar } from "@mui/material";
import { messagesContext } from "helpers/contexts";
import useSingleton from "helpers/useSingleton";
import React, { useImperativeHandle, useState } from "react";

export default function MessagesProvider({ children }) {
  const ref = useSingleton("SnackbarRenderer", <SnackbarRenderer />);

  return (
    <messagesContext.Provider
      value={{
        showMessage: (...args) => ref.current?.showMessage(...args),
      }}
    >
      {children}
    </messagesContext.Provider>
  );
}

const SnackbarRenderer = React.forwardRef(function SnackbarRenderer(props, ref) {
  const [message, messageSet] = useState(null);
  const [open, openSet] = useState(false);

  useImperativeHandle(ref, () => ({
    showMessage: async (message) => {
      messageSet(message);
      if (!open) {
        openSet(true);
      } else {
        openSet(false);
        setTimeout(() => openSet(true), 100);
      }
    },
  }));

  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      onClose={() => openSet(false)}
      anchorOrigin={{
        vertical: message?.top ? "top" : "bottom",
        horizontal: message?.right ? "right" : "left",
      }}
    >
      <Alert variant="filled" severity={message?.severity || "info"} onClose={() => openSet(false)}>
        {message?.content}
      </Alert>
    </Snackbar>
  );
});
