import { alpha, ButtonBase } from "@mui/material";
import Stack from "atoms/Stack";
import WithTooltip from "atoms/WithTooltip";
import { COLOR_BLACK } from "helpers/colors";
import { VIDEO_PAGE_SKIPPING_SECONDS } from "helpers/constants";
import { SHORTCUT_KEY_LABEL_VIDEO_SKIP_BACKWARD, SHORTCUT_KEY_LABEL_VIDEO_SKIP_FORWARD } from "helpers/SHORTCUTS";
import { SPACING_DENSE } from "helpers/spacings";
import { FastForward, Rewind } from "mdi-material-ui";
import { videoPageContext } from "pages/VideoPage";
import React, { useContext } from "react";

export default function VideoSkipControl() {
  const { videoRef } = useContext(videoPageContext);

  return (
    <Stack
      dense
      horizontal
      alignItemsCenter
      padding
      style={{
        borderRadius: SPACING_DENSE,
        backgroundColor: alpha(COLOR_BLACK, 0.5),
      }}
    >
      <WithTooltip
        tooltip={`Back ${VIDEO_PAGE_SKIPPING_SECONDS} seconds`}
        placement="top"
        inverse
        shortcutLabel={SHORTCUT_KEY_LABEL_VIDEO_SKIP_BACKWARD}
        element={
          <ButtonBase focusRipple onClick={() => (videoRef.current.currentTime -= VIDEO_PAGE_SKIPPING_SECONDS)}>
            <Rewind />
          </ButtonBase>
        }
      />
      <Stack veryDense alignItemsCenter>
        <div>3</div>
        <div style={{ fontSize: "0.5em" }}>SEC</div>
      </Stack>
      <WithTooltip
        tooltip={`Forward ${VIDEO_PAGE_SKIPPING_SECONDS} seconds`}
        placement="top"
        inverse
        shortcutLabel={SHORTCUT_KEY_LABEL_VIDEO_SKIP_FORWARD}
        element={
          <ButtonBase focusRipple onClick={() => (videoRef.current.currentTime += VIDEO_PAGE_SKIPPING_SECONDS)}>
            <FastForward />
          </ButtonBase>
        }
      />
    </Stack>
  );
}
