import { useQuery } from "@apollo/client";
import { defer } from "lodash-es";
import { useEffect } from "react";

import apolloClient from "./apolloClient";
import getApolloErrors from "./getApolloErrors";

export default function useData(query, variables, { ...options } = {}) {
  const { data, ...others } = useQuery(query, {
    variables,
    client: apolloClient,
    fetchPolicy: "cache-and-network",
    errorPolicy: "none",
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  const { error } = others;

  useEffect(() => {
    if (error) {
      const errors = getApolloErrors(error);
      for (const error of errors) {
        defer(() => {
          throw error;
        });
      }
    }
  }, [error]);

  return [data, others];
}
