import { gql } from "@apollo/client";
import Avatar from "atoms/Avatar";
import gtag from "helpers/gtag";
import { Trophy } from "mdi-material-ui";
import React from "react";

import ReelVideoPlayer from "./ReelVideoPlayer";

export const PotwVideoPlayerFragment = gql`
  fragment PotwVideoPlayerFragment on PotwCompetition {
    name
    club {
      id
      name
    }
    videoUrl
  }
`;

export default function PotwVideoPlayer({ potwCompetition, potwCompetitionId }) {
  return (
    <>
      <ReelVideoPlayer
        avatar={<Avatar icon={<Trophy />} inverse />}
        title={potwCompetition?.name}
        subtitle={potwCompetition?.club.name}
        poster={potwCompetition?.thumbnailUrl}
        videoUrl={potwCompetition?.videoUrl}
        shareUrl={`/potw/competitions/${potwCompetitionId}/video`}
        sharingDisabled={potwCompetition?.shareable === false}
        onShared={({ share_destination }) => {
          gtag("event", "potw_video_share", {
            share_destination,
          });
        }}
        onVideoPlay={() => {
          gtag("event", "potw_video_play");
        }}
      />
    </>
  );
}
