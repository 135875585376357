import { useId, useLayoutEffect } from "react";

export default function usePseudoStyle(styleHash) {
  const id = useId();
  const className = "pseudo-style-" + id.replace(/[^\w]/gi, "");
  const styleStr = Object.entries(styleHash)
    .map(([key, value]) => `.${className}${key}{${value}}`)
    .join("\n");
  useLayoutEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = styleStr;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [styleStr]);

  return { className };
}
