import playlistbuttonssnapshot from "assets/playlistbuttonssnapshot.png";
import { COLOR_PRIMARY_DARKER, COLOR_WHITE } from "helpers/colors";
import { PlaylistPlus, StarOutline } from "mdi-material-ui";
import IntroBanner from "molecules/IntroBanner";
import React from "react";

export default function PlaylistsIntroBanner() {
  return (
    <IntroBanner
      title="Playlists"
      description={
        <>
          Collect, study, and share your favourite basketball moments from{" "}
          <span style={{ fontWeight: 600 }}>multiple games</span> in{" "}
          <span style={{ fontWeight: 600 }}>one convenient place</span>.
        </>
      }
      learnMoreDialogContent={
        <>
          <p>Adding to a playlist</p>
          <ul>
            <li>1. Go to your games</li>
            <li>2. Watch any game video</li>
            <li>3. Select the highlight you want to add</li>
            <li>4. Add to a playlist using one of the following buttons:</li>
            <li>
              <img src={playlistbuttonssnapshot} style={{ maxWidth: "100%", height: "auto" }} />
            </li>
            <li>
              <PlaylistPlus
                style={{
                  fontSize: "1.5em",
                  border: "1px solid black",
                  borderRadius: 5,
                  backgroundColor: COLOR_PRIMARY_DARKER,
                  color: COLOR_WHITE,
                }}
              />{" "}
              icon: Add to a specific playlist
            </li>
            <li>
              <StarOutline
                style={{
                  fontSize: "1.5em",
                  border: "1px solid black",
                  borderRadius: 5,
                  backgroundColor: COLOR_PRIMARY_DARKER,
                  color: COLOR_WHITE,
                }}
              />{" "}
              icon: Quickly add to your default playlist
            </li>
          </ul>
        </>
      }
    />
  );
}
