import { gql } from "@apollo/client";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import EventAvatar, { EventAvatarFragment } from "molecules/EventAvatar";
import React from "react";

export const PotwEntryDetailsFragment = gql`
  fragment PotwEntryDetailsFragment on PotwEntry {
    id
    rank
    title
    subtitle
    eventView {
      id
      ...EventAvatarFragment
    }
  }
  ${EventAvatarFragment}
`;

export default function PotwEntryDetails({ potwEntry }) {
  return (
    <div
      style={{
        padding: 5,
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "center",
        gap: 10,
        fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
        fontSize: 14,
      }}
    >
      <div
        style={{
          fontStyle: "italic",
        }}
      >
        <span style={{ opacity: 0.3 }}>{"#"}</span>
        <span
          style={{
            opacity: 1,
            fontSize: "1.8em",
          }}
        >
          {potwEntry?.rank}
        </span>
      </div>
      <EventAvatar size={32} eventView={potwEntry?.eventView} />
      <div>
        {potwEntry?.title}
        <div
          style={{
            fontSize: ".9em",
            textTransform: "uppercase",
            opacity: 0.5,
          }}
        >
          {potwEntry?.subtitle}
        </div>
      </div>
    </div>
  );
}
