import { gql } from "@apollo/client";
import { PageContentStack } from "atoms/Stack";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import React from "react";
import { Helmet } from "react-helmet";
import NotFoundPage from "shared/NotFoundPage";

import PersonCareerStats from "./person/PersonCareerStats";
import PersonGamesStats from "./person/PersonGamesStats";
import PersonPagesHeader from "./person/PersonPagesHeader";

export default function PersonPage({ personId }) {
  useEnforceAuth({ enforceAuthenticated: true });
  const [data] = useData(
    gql`
      query PersonPage($personId: ID!) {
        person(id: $personId) {
          id
          fullNameWithAnonymisation
        }
      }
    `,
    { personId },
  );

  if (data && !data.person) return <NotFoundPage />;

  return (
    <>
      <Helmet>
        <title>{data?.person?.fullNameWithAnonymisation}</title>
      </Helmet>
      <PersonPagesHeader personId={personId} tab="profile" />
      <PageContentStack>
        <PersonCareerStats personId={personId} />
        <PersonGamesStats personId={personId} />
      </PageContentStack>
    </>
  );
}
