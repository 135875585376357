import { gql } from "@apollo/client";
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import ListLoader from "atoms/ListLoader";
import MuteText from "atoms/MuteText";
import gtag from "helpers/gtag";
import useAction from "helpers/useAction";
import useData from "helpers/useData";
import useShowMessage from "helpers/useShowMessage";
import { Plus } from "mdi-material-ui";
import Dialog, { DialogActionButton } from "molecules/Dialog";
import PlaylistIcon from "pages/playlists/PlaylistIcon";
import React, { useState } from "react";

import CreatePlaylistDialog from "./CreatePlaylistDialog";

export default function AddToPlaylistDialog({ eventId, open, onClose }) {
  const [actioned, actionedSet] = useState(false);
  const [createPlaylist, createPlaylistSet] = useState(false);
  const showMessage = useShowMessage();

  const [data] = useData(
    gql`
      query AddToPlaylistDialog($eventId: ID!) {
        event(id: $eventId) {
          id
        }
        currentUser {
          id
          playlistsMaximum
          playlistsMaximumReached
          playlists {
            id
            name
            playlistType
            clips {
              id
            }
            clipsMaximum
            clipsMaximumReached
            hasEventClip(eventId: $eventId)
          }
          starredPlaylist {
            id
            name
            playlistType
            clips {
              id
            }
            clipsMaximum
            clipsMaximumReached
            hasEventClip(eventId: $eventId)
          }
        }
      }
    `,
    { eventId },
    {
      skip: !open,
    },
  );

  const playlistToggleEventClip = useAction(gql`
    mutation PlaylistToggleEventClip($input: PlaylistToggleEventClipInput!, $eventId: ID!) {
      playlistToggleEventClip(input: $input) {
        playlist {
          id
          hasEventClip(eventId: $eventId)
        }
      }
    }
  `);

  return (
    <Dialog
      title="Save clip to playlist"
      open={open}
      onClose={onClose}
      actions={
        <>
          <DialogActionButton label={actioned ? "Done" : "Cancel"} onClick={onClose} />
        </>
      }
    >
      {!data?.currentUser && <ListLoader />}
      {data?.currentUser && (
        <List
          style={{
            margin: "0 -20px",
          }}
        >
          {[data.currentUser.starredPlaylist, ...data.currentUser.playlists]
            .map((playlist) => ({
              playlist,
              onToggle: async () => {
                playlistToggleEventClip({
                  eventId,
                  input: {
                    playlistId: playlist.id,
                    eventId,
                    present: !playlist.hasEventClip,
                  },
                });
                if (!playlist.hasEventClip) showMessage(`Added clip to ${playlist.name}`, "success");
                else showMessage(`Removed clip from ${playlist.name}`, "success");
                actionedSet(true);
              },
            }))
            .map(({ playlist, onToggle }) => (
              <ListItem
                key={playlist.id}
                disableGutters
                disablePadding
                secondaryAction={
                  <ListItemSecondaryAction>
                    <Checkbox
                      checked={playlist.hasEventClip || false}
                      onChange={onToggle}
                      disabled={playlist.clipsMaximumReached && !playlist.hasEventClip}
                      disableRipple
                    />
                  </ListItemSecondaryAction>
                }
              >
                <ListItemButton onClick={onToggle} disabled={playlist.clipsMaximumReached && !playlist.hasEventClip}>
                  <ListItemIcon>
                    <PlaylistIcon playlistType={playlist.playlistType} />
                  </ListItemIcon>
                  <ListItemText
                    primary={playlist.name}
                    secondary={`${playlist.clips.length}/${playlist.clipsMaximum} clips`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          <ListItem disableGutters disablePadding>
            <ListItemButton
              disabled={data?.currentUser?.playlistsMaximumReached}
              onClick={() => createPlaylistSet(true)}
            >
              <ListItemIcon>
                <Plus />
              </ListItemIcon>
              <ListItemText primary="Create new playlist" />
            </ListItemButton>
          </ListItem>
        </List>
      )}
      <MuteText>
        {data?.currentUser?.playlists.length}/{data?.currentUser.playlistsMaximum} playlists used
      </MuteText>
      <CreatePlaylistDialog
        open={createPlaylist}
        onClose={() => createPlaylistSet(false)}
        onPlaylistCreated={async ({ playlistId }) => {
          playlistToggleEventClip({
            eventId,
            input: {
              playlistId,
              eventId,
              present: true,
            },
          });
          gtag("event", "playlist_create", {
            location: "game_video",
          });
          showMessage(`Added clip to playlist`, "success");
          actionedSet(true);
        }}
      />
    </Dialog>
  );
}
