import { gql } from "@apollo/client";
import { Button, ButtonBase, Paper } from "@mui/material";
import DateTime from "atoms/DateTime";
import ImageIcon from "atoms/ImageIcon";
import VideoThumbnail from "atoms/VideoThumbnail";
import { FONT_FAMILY_LEAGUE_GOTHIC, FONT_FAMILY_PATHWAY_GOTHIC_ONE } from "helpers/FONT";
import { SPACING, SPACING_HALF } from "helpers/spacings";
import { ChartBar } from "mdi-material-ui";
import getCompetitionStyle from "pages/potw/getCompetitionStyle";
import React from "react";

export const GameEntryFragment = gql`
  fragment GameEntryFragment on Game {
    id
    videoHlsUrl
    thumbnailUrl
    videoProcessing
    homeScore
    awayScore
    currentUserRoles
    playedAt
    home {
      id
      name
      logoUrl
    }
    away {
      id
      name
      logoUrl
    }
    livePotwCompetition {
      id
      competitionType
    }
  }
`;

export default function GameEntry({ game }) {
  const competitionStyle = game.livePotwCompetition && getCompetitionStyle(game.livePotwCompetition?.competitionType);

  return (
    <Paper
      key={game.id}
      style={{
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "stretch",
        overflow: "hidden",
      }}
    >
      <VideoThumbnail
        component={ButtonBase}
        style={{ flex: "1 1 auto" }}
        videoUrl={game.videoHlsUrl}
        thumbnailUrl={game.thumbnailUrl}
        previewable
        processing={game.videoProcessing}
        href={`/game/${game.id}`}
      />
      <div
        style={{
          flex: "100 100 300px",
          display: "flex",
          flexFlow: "column nowrap",
          padding: SPACING,
          gap: SPACING_HALF,
        }}
      >
        <div
          style={{
            fontFamily: FONT_FAMILY_PATHWAY_GOTHIC_ONE,
            textTransform: "uppercase",
            fontSize: 20,
            lineHeight: 1,
          }}
        >
          <span>{game.home.name}</span>
          <span
            style={{
              opacity: 0.5,
              fontSize: ".5em",
              margin: "0 .25em",
            }}
          >
            vs
          </span>
          <span>{game.away.name}</span>
        </div>
        <div style={{ opacity: 0.5 }}>
          {game.currentUserRoles.map((role) => (
            <span key={role} style={{ textTransform: "capitalize" }}>
              {role}
              {" - "}
            </span>
          ))}
          <DateTime value={game.playedAt} variant="date" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "stretch",
          alignItems: "center",
          flex: "1 1 auto",
        }}
      >
        {competitionStyle && (
          <img
            src={competitionStyle.logoImage}
            alt={competitionStyle.title}
            style={{
              height: "3em",
            }}
          />
        )}
        <Button
          style={{ flex: "1 1 auto", margin: SPACING_HALF }}
          color="inherit"
          variant="outlined"
          href={`/game/${game.id}/overview`}
        >
          <ChartBar />
          View Game
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: 34,
          fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
          fontWeight: 400,
          padding: 10,
          flex: "1 1 200px",
        }}
      >
        <ImageIcon fontSize="inherit" src={game.home.logoUrl} alt={game.home.name} />
        <div>{game.homeScore}</div>
        <div style={{ fontSize: ".5em", opacity: 0.5 }}>vs</div>
        <div>{game.awayScore}</div>
        <ImageIcon fontSize="inherit" src={game.away.logoUrl} alt={game.away.name} />
      </div>
    </Paper>
  );
}
