import useGotoUrl from "helpers/useGotoUrl";
import { useEffect } from "react";

export default function GamePage({ gameId }) {
  const gotoUrl = useGotoUrl();
  useEffect(() => {
    gotoUrl(`/game/${gameId}/overview`, { replace: true });
  }, []);
  return null;
}
