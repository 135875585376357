import { gql } from "@apollo/client";
import DateTime from "atoms/DateTime";
import useData from "helpers/useData";
import Subnav, { SubnavDropdown, SubnavDropdownItem } from "molecules/Subnav";
import React from "react";

export default function TeamSeasonSelectSubnav({ seasonId, seasonIdSet, teamId, allowAllSeasons = false, children }) {
  const [data] = useData(
    gql`
      query TeamSeasonSelectSubnav($teamId: ID!) {
        team(id: $teamId) {
          id
          seasons {
            id
            nameWithLeague
            firstPlayedAt
            lastPlayedAt
          }
        }
      }
    `,
    { teamId },
  );
  const selectedSeason = data?.team?.seasons.find((season) => season.id === seasonId);

  return (
    <Subnav secondary>
      <SubnavDropdown text={selectedSeason?.nameWithLeague || (allowAllSeasons ? "All Seasons" : "...")}>
        {data?.team?.seasons.map((season) => (
          <SubnavDropdownItem
            key={season.id}
            onClick={() => seasonIdSet(season.id)}
            selected={season.id === seasonId}
            text={season.nameWithLeague}
            description={
              <>
                <DateTime value={season.firstPlayedAt} variant="month" /> -{" "}
                <DateTime value={season.lastPlayedAt} variant="month" />
              </>
            }
          />
        ))}
        {allowAllSeasons && (
          <SubnavDropdownItem onClick={() => seasonIdSet(null)} text="All Seasons" selected={!seasonId} />
        )}
      </SubnavDropdown>
      {children}
    </Subnav>
  );
}
