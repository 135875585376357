import { gql } from "@apollo/client";
import { ButtonBase, Paper } from "@mui/material";
import courtOutlinePng from "assets/court-outline.png";
import shotChartCourtBgJpg from "assets/shot-chart-court-bg.jpg";
import Grid from "atoms/Grid";
import Stack from "atoms/Stack";
import { COLOR_BLACK, COLOR_BLACK_LIGHTER, COLOR_WHITE_LIGHTER, getStatColor } from "helpers/colors";
import formatTimeString from "helpers/formatTime";
import { SPACING_DENSE } from "helpers/spacings";
import useData from "helpers/useData";
import { Circle, CloseThick } from "mdi-material-ui";
import EventAvatar, { EventAvatarFragment } from "molecules/EventAvatar";
import Message from "molecules/Message";
import Section, { SectionLabel } from "molecules/Section";
import HighlightPlayerDialog from "pages/video_player/HighlightPlayerDialog";
import React, { useState } from "react";

export default function ShotChart({ gamePlayerId }) {
  const [showTeam, showTeamSet] = useState(false);

  const [gamePlayerData] = useData(
    gql`
      query ShotChart_gamePlayer($gamePlayerId: ID!) {
        gamePlayer(id: $gamePlayerId) {
          id
          events(hasCoordinates: true) {
            id
            ...ShotChartEventFragment
          }
          participation {
            id
            events(hasCoordinates: true) {
              id
              ...ShotChartEventFragment
            }
          }
        }
      }
      fragment ShotChartEventFragment on Event {
        id
        occurredAtSeconds
        period {
          name
        }
        eventView {
          id
          name
          distanceInFeet
          made
          coordinates {
            x
            y
          }
          ...EventAvatarFragment
        }
      }
      ${EventAvatarFragment}
    `,
    { gamePlayerId },
    { skip: !gamePlayerId },
  );
  const gamePlayer = gamePlayerData?.gamePlayer;
  const events = showTeam ? gamePlayer?.participation?.events : gamePlayer?.events;
  const [hoveringEventId, hoveringEventIdSet] = useState();
  const [dialogEventViewId, dialogEventViewIdSet] = useState();

  return (
    <Section
      title="Shot Chart"
      labels={
        <>
          <SectionLabel label="My Shots" selected={!showTeam} onClick={() => showTeamSet(false)} />
          <SectionLabel label="My Team's Shots" selected={showTeam} onClick={() => showTeamSet(true)} />
        </>
      }
    >
      <HighlightPlayerDialog
        open={!!dialogEventViewId}
        eventViewId={dialogEventViewId}
        onClose={() => dialogEventViewIdSet(null)}
      />
      {events?.length === 0 && <Message style={{ flex: "999 1 auto" }}>Shot chart is not available</Message>}
      <Stack horizontal alignItemsStart>
        {!!events?.length && (
          <Paper
            square
            style={{
              flex: "1 1 auto",
              position: "relative",
              width: 400,
              aspectRatio: "5/4", // Can be customised to different sports
            }}
          >
            <img
              src={shotChartCourtBgJpg}
              alt="Shot Chart"
              style={{ position: "absolute", width: "100%", height: "100%" }}
            />
            <div
              src={courtOutlinePng}
              alt="Court Outline"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                // 84% is the height of the 3pt top and bottom line boundaries on scorekeeper
                // 56% is the position of the 3pt line between the middle of the baseline and halfway.
                backgroundSize: "84% 56%",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${courtOutlinePng})`,
                backgroundPosition: "top center",
              }}
            >
              {events?.map((event) => (
                <ButtonBase
                  key={event.id}
                  style={{
                    position: "absolute",
                    bottom: `${event.eventView.coordinates.x}%`,
                    right: `${event.eventView.coordinates.y}%`,
                    transform: [
                      //
                      "translate(-50%, -50%)",
                      hoveringEventId === event.id && "scale(1.5)",
                    ]
                      .filter(Boolean)
                      .join(" "),
                    transition: "transform 0.1s ease",
                    zIndex: hoveringEventId === event.id ? 1 : 0,
                  }}
                  onMouseEnter={() => hoveringEventIdSet(event.id)}
                  onMouseLeave={() => hoveringEventIdSet()}
                  onClick={() => dialogEventViewIdSet(event.eventView.id)}
                >
                  {event.eventView.made === false ? (
                    <CloseThick
                      style={{
                        stroke: COLOR_WHITE_LIGHTER,
                        strokeWidth: 2,
                        fill: COLOR_BLACK_LIGHTER,
                        fontSize: 24,
                        filter: hoveringEventId === event.id ? "drop-shadow(1px 1px 2px rgba(0,0,0,0.5))" : "none",
                      }}
                      fontSize="inherit"
                    />
                  ) : (
                    <Circle
                      style={{
                        stroke: COLOR_BLACK,
                        strokeWidth: 3,
                        fill: getStatColor(event.eventView.name),
                        fontSize: 16,
                        filter: hoveringEventId === event.id ? "drop-shadow(1px 1px 2px rgba(0,0,0,0.5))" : "none",
                      }}
                      fontSize="inherit"
                    />
                  )}
                </ButtonBase>
              ))}
            </div>
          </Paper>
        )}
        <Grid gridItemWidth="150px" style={{ flex: "999 1 auto" }}>
          {events?.map((event) => (
            <Paper
              component={ButtonBase}
              key={event.id}
              elevation={hoveringEventId === event.id ? 6 : 0}
              style={{
                display: "flex",
                flexFlow: "row nowrap",
                padding: SPACING_DENSE,
                gap: SPACING_DENSE,
                justifyContent: "stretch",
                alignItems: "center",
                border: `2px solid ${COLOR_BLACK}`,
              }}
              onClick={() => dialogEventViewIdSet(event.eventView.id)}
              onMouseEnter={() => hoveringEventIdSet(event.id)}
              onMouseLeave={() => hoveringEventIdSet()}
            >
              <EventAvatar size={36} eventView={event.eventView} />
              <div
                style={{
                  flex: "1 1 auto",
                }}
              >
                <div>
                  {event.period?.name} - {formatTimeString(event.occurredAtSeconds)}
                </div>
                <div>{event.eventView.distanceInFeet} ft</div>
              </div>
            </Paper>
          ))}
        </Grid>
      </Stack>
    </Section>
  );
}
