import { gql } from "@apollo/client";
import useData from "helpers/useData";
import useGotoUrl from "helpers/useGotoUrl";
import { useEffect } from "react";

export default function PeoplePage() {
  const gotoUrl = useGotoUrl();
  const [data, dataMeta] = useData(gql`
    query PeoplePage {
      currentUser {
        people {
          id
        }
      }
    }
  `);

  useEffect(() => {
    if (!dataMeta.loading && data) {
      const personId = data.currentUser?.people[0]?.id;
      if (personId) {
        gotoUrl(`/person/${personId}`);
      }
    }
  }, [data, dataMeta]);

  return null;
}
