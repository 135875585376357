import "nprogress/nprogress.css";

import { defer, remove } from "lodash-es";
import NProgress from "nprogress";
import { useLayoutEffect } from "react";

import { ONSEN_ENV } from "./environment";

const _bars = [];

NProgress.configure({ showSpinner: false });

export async function barsUpdate() {
  // Delay to wait for potentially another fetch request to kick in immediately
  await new Promise(defer);

  if (_bars.length) {
    NProgress.start();
    document.body.dataset.progressbar = "busy";
  } else {
    NProgress.done();
    document.body.dataset.progressbar = "";
  }
}

export function barsAdd(bar) {
  _bars.push(bar);
  barsUpdate();
}

export async function barsRemove(bar) {
  // batch up the removals until it's triggered from spec/support/js_helpers.rb
  if (ONSEN_ENV === "test") {
    while (!window._wait_for_progress_bars) {
      await new Promise(defer);
    }
  }
  remove(_bars, (b) => b === bar);
  barsUpdate();
}

export async function progressBar(func) {
  const bar = {};
  barsAdd(bar);

  try {
    return await func();
  } finally {
    barsRemove(bar);
  }
}
window._progressBar = progressBar;
window._barsAdd = barsAdd;
window._barsRemove = barsRemove;

export default function useProgressBar(display) {
  useLayoutEffect(() => {
    if (!display) return;

    const bar = {};
    barsAdd(bar);

    return () => {
      barsRemove(bar);
    };
  }, [display]);

  return progressBar;
}
