import { gql } from "@apollo/client";
import { COLOR_SECONDARY, TEAM_COLORS } from "helpers/colors";
import React from "react";

import PlayerAvatar from "../atoms/PlayerAvatar";

export const GamePlayerAvatarFragment = gql`
  fragment GamePlayerAvatarFragment on GamePlayer {
    id
    playerNumber
    person {
      id
      fullNameWithAnonymisation
      photoAvatarUrl
    }
    team {
      id
      colour
    }
  }
`;

export default function GamePlayerAvatar({ gamePlayer, ...others }) {
  const photoAvatarUrl = gamePlayer?.person.photoAvatarUrl;
  const teamColor = TEAM_COLORS[gamePlayer?.team?.colour] || COLOR_SECONDARY;

  return (
    <PlayerAvatar
      avatarUrl={photoAvatarUrl}
      color={teamColor}
      playerNumber={gamePlayer?.playerNumber}
      alt={gamePlayer?.person.fullNameWithAnonymisation}
      {...others}
    />
  );
}
