import AbortError from "helpers/AbortError";
import Dialog, { DialogActionButton } from "molecules/Dialog";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

export default function useConfirmDialog() {
  const [entries, entriesSet] = useState([]);

  const confirmDialogOpen = async (props = {}) => {
    const id = uuidv4();
    try {
      await new Promise((resolve, reject) => {
        const entry = {
          id,
          props,
          resolve,
          reject,
        };
        entriesSet((entries) => [...entries, entry]);
      });
    } finally {
      entriesSet((entries) => entries.filter((e) => e.id !== id));
    }
  };

  const confirmDialog = (
    <>
      {entries.map(
        ({ id, props: { title = "Confirm action", content = "Are you sure?", action = "OK" }, reject, resolve }) => (
          <Dialog
            key={id}
            open
            onClose={() => reject(new AbortError())}
            title={title}
            actions={
              <>
                <DialogActionButton primary label={action} onClick={() => resolve()} />
                <DialogActionButton onClick={() => reject(new AbortError())} label="Cancel" />
              </>
            }
          >
            {content}
          </Dialog>
        ),
      )}
    </>
  );
  return [confirmDialog, confirmDialogOpen];
}
