import useGotoUrl from "helpers/useGotoUrl";
import { useEffect } from "react";

export default function TeamPage({ teamId }) {
  const gotoUrl = useGotoUrl();
  useEffect(() => {
    gotoUrl(`/team/${teamId}/roster`, { replace: true });
  }, []);
  return null;
}
