import { ButtonBase } from "@mui/material";
import { COLOR_SECONDARY } from "helpers/colors";
import { Star as StarIcon, StarOutline as StarOutlineIcon } from "mdi-material-ui";
import React, { useState } from "react";

export default function RatingStars({ currentRating = null, onRate, ...others }) {
  const [hoveringRating, hoveringRatingSet] = useState(null);

  return (
    <div
      data-test-rating-stars
      onMouseLeave={() => hoveringRatingSet(null)}
      {...others}
      style={{
        display: "flex",
        flexFlow: "row nowrap",
        ...others.style,
      }}
    >
      {[1, 2, 3, 4, 5].map((rating) => (
        <ButtonBase
          key={rating}
          title={`Rate ${rating}`}
          onClick={() => {
            onRate?.(rating);
            hoveringRatingSet(null);
          }}
        >
          <Star
            active={hoveringRating ? rating <= hoveringRating : rating <= currentRating}
            large={hoveringRating && hoveringRating === rating}
            onMouseEnter={() => hoveringRatingSet(rating)}
          />
        </ButtonBase>
      ))}
    </div>
  );
}

function Star({ active = false, large = false, ...others }) {
  const Icon = active ? StarIcon : StarOutlineIcon;
  return (
    <Icon
      fontSize="inherit"
      style={{
        color: COLOR_SECONDARY,
        transformOrigin: "center",
        transition: "transform 0.2s",
        ...(large && {
          transform: "scale(1.2)",
        }),
        cursor: "pointer",
      }}
      {...others}
    />
  );
}
