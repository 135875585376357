import { gql } from "@apollo/client";
import React from "react";

export const StatValueFragment = gql`
  fragment StatValueFragment on Stat {
    statType
    value
    made
    total
  }
`;

export default function StatValue({ stat }) {
  return (
    <span
      style={{
        display: "inline-flex",
        flexFlow: "column nowrap",
      }}
    >
      {stat?.statType === "simple" && <div>{stat.value}</div>}
      {stat?.statType === "percentage" && (
        <>
          <div
            style={{
              fontSize: "0.6em",
            }}
          >
            {stat.made} - {stat.total}
          </div>
          <div
            style={{
              fontSize: "0.4em",
            }}
          >
            ({Math.round((stat.made / stat.total) * 100) + "%"})
          </div>
        </>
      )}
      {!stat && <span>?</span>}
    </span>
  );
}
