import { gql } from "@apollo/client";
import { ButtonBase, Paper } from "@mui/material";
import VideoThumbnail from "atoms/VideoThumbnail";
import { COLOR_WHITE } from "helpers/colors";
import { Note } from "mdi-material-ui";
import EventMarker, { EventMarkerFragment } from "molecules/EventMarker";
import React from "react";

export const ClipThumbnailFragment = gql`
  fragment ClipThumbnailFragment on Clip {
    id
    thumbnailUrl
    videoUrl
    hasDescription
    eventView {
      id
      ...EventMarkerFragment
    }
  }
  ${EventMarkerFragment}
`;

export default function ClipThumbnail({ clip, href, active, activeProgress, previewable = false, ...others }) {
  return (
    <Paper
      component={ButtonBase}
      draggable="false"
      href={href}
      {...others}
      style={{
        overflow: "hidden",
      }}
    >
      <VideoThumbnail
        previewable={previewable}
        active={active}
        activeProgress={activeProgress}
        key={clip.id}
        thumbnailUrl={clip.thumbnailUrl}
        videoUrl={clip.videoUrl}
        leftLabel={<EventMarker eventView={clip.eventView} />}
        rightLabel={
          clip.hasDescription && (
            <Note
              style={{
                color: COLOR_WHITE,
                fontSize: 24,
              }}
            />
          )
        }
      />
    </Paper>
  );
}
