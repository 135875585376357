import { useEffect, useRef } from "react";

export default function useDOMEvent(eventName, handler, getElement = () => window, options) {
  const handlerRef = useRef();
  handlerRef.current = handler;

  useEffect(() => {
    const element = getElement();
    const wrappedHandler = (...args) => handlerRef.current?.(...args);
    element.addEventListener(eventName, wrappedHandler, options);

    return () => {
      element.removeEventListener(eventName, wrappedHandler, options);
    };
  }, []);
}
