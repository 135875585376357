import { intlFormat, parseISO } from "date-fns";
import useTimeZone from "helpers/useTimeZone";
import React from "react";

export default function DateTime({ value, variant, defaultValue = "?", ...others }) {
  const timeZone = useTimeZone();
  return (
    <span title={timeZone} {...others}>
      {!value && defaultValue}
      {value &&
        intlFormat(parseISO(value), {
          ...({
            time: {
              timeStyle: "medium",
            },
            date: {
              dateStyle: "long",
            },
            short_date: {
              month: "short",
              day: "numeric",
            },
            month: {
              month: "short",
              year: "numeric",
            },
          }[variant] || {
            timeStyle: "medium",
            dateStyle: "short",
          }),
          timeZone,
        })}
    </span>
  );
}
