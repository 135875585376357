import { gql } from "@apollo/client";
import TextWithTooltip from "atoms/TextWithTooltip";
import {
  COLOR_ASSIST,
  COLOR_BLACK,
  COLOR_BLOCK,
  COLOR_POINT,
  COLOR_REBOUND,
  COLOR_STEAL,
  COLOR_WHITE,
} from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC, FONT_FAMILY_ROBOTO } from "helpers/FONT";
import useData from "helpers/useData";
import StatValue, { StatValueFragment } from "molecules/StatValue";
import React from "react";

export default function PersonCareerStats({ personId }) {
  const [data] = useData(
    gql`
      query PersonCareerStats($personId: ID!) {
        person(id: $personId) {
          id
          leaderboardSegments {
            title
            columns {
              name
              shortName
            }
            rows {
              cells {
                ...StatValueFragment
              }
            }
          }
        }
      }
      ${StatValueFragment}
    `,
    { personId },
  );
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row wrap",
        gap: 20,
      }}
    >
      {data?.person?.leaderboardSegments.map((leaderboardSegment) => (
        <div
          key={leaderboardSegment.title}
          style={{
            backgroundColor: COLOR_BLACK,
            color: COLOR_WHITE,
            padding: 10,
            borderRadius: 10,
            fontFamily: FONT_FAMILY_ROBOTO,
            fontWeight: 500,
            textTransform: "uppercase",
            display: "flex",
            flexFlow: "column nowrap",
            gap: 10,
          }}
        >
          <div>{leaderboardSegment.title}</div>
          <table>
            <tbody>
              {leaderboardSegment.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      style={{
                        fontSize: 52,
                        textAlign: "center",
                        whiteSpace: "pre-line",
                        fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                        fontWeight: 200,
                        width: 60,
                        verticalAlign: "middle",
                        ...(!cellIndex && {
                          color: {
                            Points: COLOR_POINT,
                            Rebounds: COLOR_REBOUND,
                            Assists: COLOR_ASSIST,
                            Steals: COLOR_STEAL,
                            Blocks: COLOR_BLOCK,
                          }[leaderboardSegment.title],
                        }),
                      }}
                      key={cellIndex}
                    >
                      <StatValue stat={cell} />
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                {leaderboardSegment.columns.map((column, columnIndex) => (
                  <td style={{ textAlign: "center", opacity: 0.5 }} key={columnIndex}>
                    <TextWithTooltip tooltip={column.name}>{column.shortName}</TextWithTooltip>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}
