import { Button, Divider, Paper } from "@mui/material";
import logoSvg from "assets/logo.svg";
import { COLOR_PRIMARY, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { SPACING } from "helpers/spacings";
import useBodyBackgroundColor from "helpers/useBodyBackgroundColor";
import React, { createContext, useContext, useState } from "react";

import FooterText from "./FooterText";

const formPageContext = createContext();

export default function FormPage({ title, onSubmit, children }) {
  const [submitting, submittingSet] = useState(false);
  useBodyBackgroundColor(COLOR_PRIMARY);

  return (
    <formPageContext.Provider value={{ submitting }}>
      <div
        style={{
          minHeight: "100svh",
          display: "flex",
          flexFlow: "column nowrap",
          gap: SPACING,
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          backgroundColor: COLOR_PRIMARY,
        }}
      >
        <Paper
          component="form"
          style={{
            width: 400,
            padding: SPACING,
            display: "flex",
            flexFlow: "column nowrap",
            gap: SPACING,
          }}
          onSubmit={async (event) => {
            event.preventDefault();
            submittingSet(true);
            try {
              await onSubmit?.();
            } finally {
              submittingSet(false);
            }
          }}
        >
          <img alt="Glory League Logo" src={logoSvg} style={{ width: 60, margin: "0 auto" }} />
          <div style={{ textAlign: "center", fontSize: 32, fontFamily: FONT_FAMILY_LEAGUE_GOTHIC }}>{title}</div>
          <Divider />
          {children}
        </Paper>
        <div
          style={{
            color: COLOR_WHITE,
            width: 400,
            fontSize: 12,
            opacity: 0.5,
          }}
        >
          <FooterText />
        </div>
      </div>
    </formPageContext.Provider>
  );
}

export function FormPageSubmitButton({ label, disabled }) {
  const { submitting } = useContext(formPageContext);
  return (
    <Button fullWidth color="secondary" type="submit" variant="contained" disabled={disabled || submitting}>
      {submitting ? "..." : label}
    </Button>
  );
}
