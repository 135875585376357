import ExtendedError from "./ExtendedError";

export default class UserError extends ExtendedError {
  constructor(message, metadata) {
    super(message, metadata);
    Object.assign(this, {
      isUserError: true,
    });
  }
}
