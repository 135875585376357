export const {
  //
  ONSEN_ENV,
  GLORYLEAGUE_URL,
  GLORYLEAGUE_API_URL,
  ONSEN_PUBLIC_LEAGUES_HOST_SUFFIX,
  RECAPTCHA_SITE_KEY,
} =
  // eslint-disable-next-line no-undef
  ONSEN_ENVIRONMENTS;
