import { Fade, Popper } from "@mui/material";
import { noop } from "lodash-es";
import React, { cloneElement, useState } from "react";

import ControlButton from "./ControlButton";

export default function ControlDropdown({
  children,
  placement = "bottom-start",
  onOpen = noop,
  onClose = noop,
  ...others
}) {
  const [open, openSet] = useState(false);
  const [anchorEl, anchorElSet] = useState(null);

  return (
    <>
      <ControlButton
        inverse={open}
        onClick={(event) => {
          openSet(!open);
          open ? onClose() : onOpen();
          anchorElSet(event.currentTarget);
        }}
        {...others}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        elevation={0}
        style={{
          zIndex: 2,
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div>{cloneElement(children, { onClose: () => openSet(false) })}</div>
          </Fade>
        )}
      </Popper>
    </>
  );
}
