import { defer } from "lodash-es";

const MIME_TYPES = {
  mp4: "video/mp4",
  mkv: "video/x-matroska",
};

export async function fetchFile(url, title) {
  let blob = null;
  if (url) {
    const response = await fetch(url);
    if (response.ok) {
      blob = await response.blob();
    } else {
      defer(() => {
        throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
      });
    }
  }

  const pathname = url && new URL(url).pathname;
  const ext = pathname?.split(".").pop().toLowerCase();
  const basename = (title || "gloryleague").replace(/[^a-z0-9]+/gi, " ");
  const filename = ext ? `${basename}.${ext}` : basename;
  const mimeType = MIME_TYPES[ext];

  const file = blob && new File([blob], filename, { type: mimeType });
  const fileObjectUrl = URL.createObjectURL(file);

  return { filename, file, fileObjectUrl };
}
