import { gql } from "@apollo/client";
import useData from "helpers/useData";
import useGotoUrl from "helpers/useGotoUrl";
import { useEffect } from "react";

export default function RootPage() {
  const [data] = useData(gql`
    query RootPage {
      currentUser {
        id
      }
    }
  `);
  const gotoUrl = useGotoUrl();

  useEffect(() => {
    if (data) {
      gotoUrl(`${data.currentUser ? "/games" : "/login"}`);
    }
  }, [!!data]);
  return null;
}
