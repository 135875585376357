import UserError from "helpers/UserError";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";

import ErrorPage from "./ErrorPage";

export const ERROR_MESSAGE_PAGE_NOT_FOUND = "Page not found";

export default function NotFoundPage() {
  const error = useMemo(() => new UserError(ERROR_MESSAGE_PAGE_NOT_FOUND), []);
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ErrorPage error={error} />
    </>
  );
}
