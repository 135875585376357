import { ButtonBase } from "@mui/material";
import {
  COLOR_BLACK,
  COLOR_GREY_BORDER,
  COLOR_SECONDARY,
  COLOR_SECONDARY_LIGHTER,
  COLOR_WHITE,
  COLOR_WHITE_LIGHTER,
} from "helpers/colors";
import disableTouchForHover from "helpers/disableTouchForHover";
import { controlBarContext } from "molecules/ControlBar";
import React, { cloneElement, useContext, useState } from "react";

export default function ControlButton({ icon = null, iconEnd = null, label = "", inverse = false, ...others }) {
  const [hovering, hoveringSet] = useState(false);

  const { controlBarHeight } = useContext(controlBarContext);

  return (
    <ButtonBase
      {...others}
      onMouseEnter={() => hoveringSet(true)}
      onMouseLeave={() => hoveringSet(false)}
      {...disableTouchForHover}
      style={{
        flex: "1 0 auto",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
        minHeight: controlBarHeight,
        padding: "0 1em",
        color: COLOR_BLACK,
        backgroundColor: COLOR_WHITE,
        border: `.5px solid ${inverse ? COLOR_SECONDARY : COLOR_GREY_BORDER}`,
        ...(hovering && {
          backgroundColor: COLOR_WHITE_LIGHTER,
        }),
        ...(inverse && {
          color: COLOR_WHITE,
          backgroundColor: COLOR_SECONDARY,
          ...(hovering && {
            backgroundColor: COLOR_SECONDARY_LIGHTER,
          }),
        }),
        ...(others.disabled && {
          color: COLOR_GREY_BORDER,
        }),
        textDecoration: "none",
        textTransform: "uppercase",
        fontWeight: "bold",
        ...others.style,
      }}
    >
      {icon &&
        cloneElement(icon, {
          style: {
            color: COLOR_SECONDARY,
            ...(inverse && {
              color: COLOR_WHITE,
            }),
            ...(others.disabled && {
              color: COLOR_GREY_BORDER,
            }),
            ...icon.props.style,
          },
        })}
      {label}
      {iconEnd &&
        cloneElement(iconEnd, {
          style: {
            color: COLOR_SECONDARY,
            ...(inverse && {
              color: COLOR_WHITE,
            }),
            ...(others.disabled && {
              color: COLOR_GREY_BORDER,
            }),
            ...iconEnd.props.style,
          },
        })}
    </ButtonBase>
  );
}
