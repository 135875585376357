import { alpha, ButtonBase } from "@mui/material";
import Spacer from "atoms/Spacer";
import Stack from "atoms/Stack";
import { COLOR_BLACK, COLOR_GREY, COLOR_PRIMARY_DARKER, COLOR_WHITE } from "helpers/colors";
import { SPACING, SPACING_DENSE } from "helpers/spacings";
import useFullscreenState from "helpers/useFullscreenState";
import { MenuRight } from "mdi-material-ui";
import { videoPageContext } from "pages/VideoPage";
import React, { createContext, useContext, useLayoutEffect, useState } from "react";
import { useComponentSize } from "react-use-size";

export const videoPageLayoutContext = createContext();
const SIDEBAR_WIDTH = 250;

export default function VideoPageLayout({
  header,
  video,
  controls,
  floatingControls,
  highlightHub,
  rightFloatingControls,
  listHeader,
  listContent,
}) {
  const { activeEventId, sidebarRef, floatingControlsRef } = useContext(videoPageContext);
  const [fullscreen] = useFullscreenState();
  const componentSize = useComponentSize();
  const isPortrait = componentSize.width < 500 || componentSize.height > componentSize.width;
  const [sidebarOpen, sidebarOpenSet] = useState(true);
  useLayoutEffect(() => {
    if (isPortrait && !sidebarOpen) {
      sidebarOpenSet(true);
    }
  }, [isPortrait, sidebarOpen]);

  return (
    <videoPageLayoutContext.Provider value={{ isPortrait, sidebarOpen }}>
      <div
        ref={componentSize.ref}
        style={{
          flex: "1 1 auto",
          backgroundColor: COLOR_BLACK,
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "stretch",
          alignItems: "stretch",
          color: COLOR_WHITE,
          overflow: "hidden",
          ...(isPortrait && {
            flexFlow: "column nowrap",
          }),
        }}
      >
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "stretch",
            alignItems: "stretch",
            ...(isPortrait && {
              flex: "0 0 auto",
            }),
          }}
        >
          <Stack horizontal noWrap alignItemsCenter>
            {header}
          </Stack>
          <div
            style={{
              flex: "1 1 auto",
              position: "relative",
              ...(isPortrait && {
                flex: "0 0 auto",
                aspectRatio: "16 / 9",
              }),
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              {video}
            </div>
            <Stack
              componentRef={floatingControlsRef}
              horizontal
              padding
              dense
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
                alignItems: "flex-end",
              }}
            >
              <Stack horizontal dense alignItemsCenter>
                {floatingControls}
              </Stack>
              {!isPortrait && (
                <div style={{ flex: "1 1 auto", position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Stack
                      alignItemsCenter
                      horizontal
                      padding
                      dense
                      style={{
                        borderRadius: SPACING_DENSE,
                        backgroundColor: alpha(COLOR_PRIMARY_DARKER, 0.9),
                      }}
                      data-test-highlight-hub
                      data-video-joyride="highlight-bar"
                    >
                      {activeEventId && highlightHub}
                    </Stack>
                  </div>
                </div>
              )}
              {isPortrait && <Spacer />}
              <Stack horizontal dense alignItemsCenter>
                {rightFloatingControls}
              </Stack>
            </Stack>
          </div>
          <Stack dense padding>
            {isPortrait && (
              <Stack
                horizontal
                alignItemsCenter
                dense
                style={{
                  minHeight: 40,
                }}
                data-test-highlight-hub
                data-video-joyride="highlight-bar"
              >
                {activeEventId && highlightHub}
              </Stack>
            )}
            <Stack horizontal noWrap dense alignItemsCenter>
              {controls}
            </Stack>
          </Stack>
        </div>
        <div
          data-test-sidebar
          ref={sidebarRef}
          style={{
            backgroundColor: COLOR_PRIMARY_DARKER,
            flex: "0 0 auto",
            marginRight: sidebarOpen ? 0 : -SIDEBAR_WIDTH,
            transition: "margin-right 0.2s",
            width: SIDEBAR_WIDTH,
            position: "relative",
            ...(isPortrait && {
              flex: "1 1 auto",
              width: "auto",
            }),
            display: "flex",
            ...(fullscreen &&
              !isPortrait && {
                display: "none",
              }),
            flexFlow: "column nowrap",
          }}
        >
          <ButtonBase
            style={{
              position: "absolute",
              top: "50%",
              left: 0,
              backgroundColor: alpha(COLOR_GREY, 0.5),
              width: SPACING,
              paddingTop: SPACING,
              paddingBottom: SPACING,
              transform: "translate(-100%, -50%)",
            }}
            onClick={() => sidebarOpenSet(!sidebarOpen)}
          >
            <MenuRight
              style={{
                transform: sidebarOpen ? "none" : "rotate(180deg)",
                transition: "transform 0.2s",
              }}
            />
          </ButtonBase>
          <Stack>{listHeader}</Stack>
          <div
            style={{
              //minimum height
              flex: "1 0 100px",
              position: "relative",
            }}
            data-video-joyride="highlight-stream"
          >
            <div
              style={{
                flex: "1 0 0",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: "auto",
                display: "flex",
                flexFlow: "column nowrap",
              }}
            >
              {listContent}
            </div>
          </div>
        </div>
      </div>
    </videoPageLayoutContext.Provider>
  );
}
