import { gql } from "@apollo/client";
import { TextField } from "@mui/material";
import useActionFragment from "helpers/useActionFragment";
import useData from "helpers/useData";
import useShowMessage from "helpers/useShowMessage";
import { defer } from "lodash-es";
import Section from "molecules/Section";
import Toolbar, { ToolbarButton } from "molecules/Toolbar";
import React, { useLayoutEffect, useState } from "react";

import useChangeProfilePhoto from "./useChangeProfilePhoto";

export default function PersonProfile({ personId }) {
  const [firstName, firstNameSet] = useState("");
  const [lastName, lastNameSet] = useState("");
  const [editing, editingSet] = useState(false);

  const [data] = useData(
    gql`
      query PersonProfile($personId: ID!) {
        person(id: $personId) {
          id
          firstName
          lastName
          photoAvatarUrl
        }
      }
    `,
    { personId },
  );

  const onsenPersonUpdate = useActionFragment("onsenPersonUpdate", "person { id firstName lastName }");
  const changeProfilePhoto = useChangeProfilePhoto({ personId });

  useLayoutEffect(() => {
    if (!editing && data?.person) {
      firstNameSet(data.person.firstName);
      lastNameSet(data.person.lastName);
    }
  }, [data, editing]);
  const showMessage = useShowMessage();

  return (
    <Section
      title="Personal Information"
      component="form"
      onSubmit={async (event) => {
        event.preventDefault();
        await onsenPersonUpdate({
          input: {
            personId,
            firstName,
            lastName,
          },
        });
        showMessage("Profile updated");
        editingSet(false);
      }}
    >
      <TextField
        label="First Name"
        value={firstName}
        onChange={(event) => firstNameSet(event.target.value)}
        autoComplete="given-name"
        required
        disabled={!editing}
      />
      <TextField
        label="Last Name"
        value={lastName}
        onChange={(event) => lastNameSet(event.target.value)}
        autoComplete="family-name"
        disabled={!editing}
        helperText={
          <>
            Your names will be visible to other Glory League users who have access to games you played, unless you
            enable the <b>Hide my name publicly</b> option below.
          </>
        }
      />
      <Toolbar>
        {!editing ? (
          <>
            <ToolbarButton label="Edit" onClick={() => defer(() => editingSet(true))} />
            <ToolbarButton label="Change Profile Photo" onClick={() => changeProfilePhoto()} />
          </>
        ) : (
          <>
            <ToolbarButton primary label="Save" type="submit" />
            <ToolbarButton label="Cancel" onClick={() => editingSet(false)} />
          </>
        )}
      </Toolbar>
    </Section>
  );
}
