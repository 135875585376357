import { gql } from "@apollo/client";
import { ButtonBase, Paper } from "@mui/material";
import Stack from "atoms/Stack";
import VideoThumbnail from "atoms/VideoThumbnail";
import { SPACING_DENSE } from "helpers/spacings";
import ReelViewAvatar, { ReelViewAvatarFragment } from "molecules/ReelAvatar";
import ViewCountLabel from "molecules/ViewCountLabel";
import ReelPlayerDialog from "pages/video_player/ReelPlayerDialog";
import React, { useState } from "react";

export const ReelCardFragment = gql`
  fragment ReelCardFragment on ReelView {
    id
    title
    thumbnailUrl
    eventsCount
    viewCount
    ...ReelViewAvatarFragment
  }
  ${ReelViewAvatarFragment}
`;

export default function ReelCard({ reelView }) {
  const [dialogOpen, dialogOpenSet] = useState(false);

  return (
    <Paper
      variant="outlined"
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "flex-start",
        alignItems: "stretch",
        overflow: "hidden",
      }}
    >
      <ReelPlayerDialog open={dialogOpen} onClose={() => dialogOpenSet(false)} reelViewId={reelView.id} />
      <ButtonBase
        href={`/reel/${reelView.id}`}
        onClick={(event) => {
          if (event.ctrlKey || event.shiftKey || event.metaKey || event.altKey) return;
          event.preventDefault();
          dialogOpenSet(true);
        }}
      >
        <VideoThumbnail
          style={{ width: "100%", aspectRatio: "2.5/1" }}
          thumbnailUrl={reelView.thumbnailUrl}
          rightLabel={<ViewCountLabel viewCount={reelView.viewCount} />}
        />
      </ButtonBase>
      <div
        style={{
          padding: SPACING_DENSE,
        }}
      >
        <Stack horizontal dense>
          <ReelViewAvatar reelView={reelView} size={32} />
          <Stack dense>
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {reelView.title}
            </div>
            <div
              style={{
                fontSize: 12,
                color: "rgba(0, 0, 0, 0.54)",
              }}
            >
              {reelView.eventsCount} highlights
            </div>
          </Stack>
        </Stack>
      </div>
    </Paper>
  );
}
