import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_ROBOTO } from "helpers/FONT";
import React, { cloneElement } from "react";

export default function Avatar({
  icon,
  text = icon && cloneElement(icon, { fontSize: "inherit" }),
  component: Component = "div",
  element = <Component />,
  imgSrc,
  color,
  inverse = false,
  active = false,
  size = 32,
  ...others
}) {
  // No idea about numbers behind this formula, but it works for 1, 2, 3, 4 characters text
  const fontSize = (size / (text?.length || 1) + 15) * 0.5 - 4;
  color ||= inverse ? COLOR_WHITE : COLOR_BLACK;

  let textColor = "transparent",
    backgroundColor = "transparent",
    borderColor = "transparent";
  if (inverse) {
    if (active) {
      backgroundColor = color;
      textColor = COLOR_BLACK;
    } else {
      borderColor = color;
      backgroundColor = COLOR_BLACK;
      textColor = color;
    }
  } else {
    if (active) {
      // TODO:
      // backgroundColor = eventColor;
      // textColor = COLOR_BLACK;
    } else {
      backgroundColor = color;
      textColor = COLOR_WHITE;
    }
  }

  if (imgSrc) {
    borderColor = "transparent";
  }

  return cloneElement(element, {
    ...others,
    style: {
      ...element.props.style,
      width: size,
      height: size,
      borderRadius: "50%",
      ...(borderColor !== "transparent" && {
        borderWidth: 2,
        borderStyle: "solid",
        borderColor,
      }),
      color: textColor,
      backgroundColor,
      ...others.style,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: FONT_FAMILY_ROBOTO,
      fontWeight: 600,
      fontSize,
    },
    children: (
      <>
        {imgSrc && (
          <img
            src={imgSrc}
            alt={text}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        )}
        {!imgSrc && text}
      </>
    ),
  });
}
