import { SPACING, SPACING_DENSE } from "helpers/spacings";
import React from "react";

export default function Grid({ gridItemWidth = "400px", dense = false, children, ...others }) {
  return (
    <div
      {...others}
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(min(calc(100svw - 40px), ${gridItemWidth}), 1fr))`,
        gridGap: dense ? SPACING_DENSE : SPACING,
        ...others.style,
      }}
    >
      {children}
    </div>
  );
}
