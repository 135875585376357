import { gql } from "@apollo/client";
import EventAvatar, { EventAvatarFragment } from "molecules/EventAvatar";
import GameTitle from "molecules/GameTitle";
import React from "react";

export const ClipInfoFragment = gql`
  fragment ClipInfoFragment on Clip {
    id
    homeTeamName
    awayTeamName
    playedOn
    eventView {
      id
      ...EventAvatarFragment
    }
  }
  ${EventAvatarFragment}
`;

export default function ClipInfo({ clip, ...others }) {
  return (
    <div {...others}>
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center",
          height: 40,
        }}
      >
        <EventAvatar size={32} eventView={clip?.eventView} />
        <div
          style={{
            display: "column nowrap",
            margin: ".5em",
          }}
        >
          <GameTitle homeTeamName={clip?.homeTeamName} awayTeamName={clip?.awayTeamName} />
          <div>{clip?.playedOn}</div>
        </div>
      </div>
    </div>
  );
}
