import { gql } from "@apollo/client";
import { classify } from "underscore.string";

import useAction from "./useAction";

export default function useActionFragment(actionName, ...fragments) {
  const actionClassName = classify(actionName);
  const fragment = fragments.join(" ") || "clientMutationId";

  return useAction(gql`
    mutation($input: ${actionClassName}Input!) {
      ${actionName}(input: $input) {
        ${fragment}
      }
    }
  `);
}
