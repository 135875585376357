import { gql } from "@apollo/client";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from "@mui/material";
import ListLoader from "atoms/ListLoader";
import useData from "helpers/useData";
import PlaylistIcon from "pages/playlists/PlaylistIcon";
import React from "react";

import PlaylistDroppable from "./PlaylistDroppable";

export default function PlaylistsDropdown({ currentPlaylistId, ...others }) {
  const [data] = useData(gql`
    query PlaylistsDropdown {
      currentUser {
        id
        playlists {
          id
          ...playlistsDropdownPlaylistFragment
        }
        starredPlaylist {
          id
          ...playlistsDropdownPlaylistFragment
        }
      }
    }
    ${playlistsDropdownPlaylistFragment}
  `);

  return (
    <Paper elevation={6} square {...others}>
      {!data && <ListLoader />}
      {data && (
        <List disablePadding>
          {[].concat(data?.currentUser?.starredPlaylist, data?.currentUser?.playlists).map((playlist) => (
            <PlaylistDroppable key={playlist.id} component={<ListItem disablePadding />} playlistId={playlist.id}>
              <ListItemButton
                selected={playlist.id === currentPlaylistId}
                disabled={playlist.clips.length === 0}
                href={`/playlists/${playlist.id}/${playlist.clips[0]?.id}`}
              >
                <ListItemIcon>
                  <PlaylistIcon playlistType={playlist.playlistType} />
                </ListItemIcon>
                <ListItemText primary={playlist.name} secondary={<>{playlist.clips.length} clips</>} />
              </ListItemButton>
            </PlaylistDroppable>
          ))}
        </List>
      )}
    </Paper>
  );
}

export const playlistsDropdownPlaylistFragment = gql`
  fragment playlistsDropdownPlaylistFragment on Playlist {
    name
    playlistType
    clips {
      id
    }
  }
`;
