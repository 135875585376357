import Tour from "molecules/Tour";
import React from "react";

export default function VideoPageTour({ ...others }) {
  return (
    <Tour
      {...others}
      welcomeTitle="Welcome"
      welcomeContent={
        <>
          Welcome to the Glory League video experience.
          <br />
          Let&apos;s show you around.
        </>
      }
      steps={[
        {
          target: "[data-video-joyride='video-timeline']",
          title: "Video Timeline",
          content: (
            <>
              This handy bar is your <b>video timeline</b>, a.k.a game navigator.
              <br />
              Your personal highlights are available here.
            </>
          ),
        },
        {
          target: "[data-video-joyride='highlight-stream']",
          title: "Highlight Stream",
          content: (
            <>
              Your personal highlights live here too, in what we call the <b>highlight stream</b>.
            </>
          ),
        },
        {
          target: "[data-video-joyride='filter-highlights']",
          title: "Filter Highlights",
          content: (
            <>
              Choose how the game&apos;s highlights are filtered – by team, player or type.
              <br />
              Your <b>video timeline</b> and <b>highlight stream</b> will automatically adjust to match.
            </>
          ),
        },
        {
          target: "[data-video-joyride='highlight-bar']",
          title: "Highlight Bar",
          content: (
            <>
              The current highlight is captured in the <b>highlight bar</b> as it occurs in the video.
              <br />
              From here it can be <b>edited</b> and <b>shared</b> to social media.
            </>
          ),
        },
        {
          target: '[data-video-joyride="tag-stat-highlights"]',
          title: "Tag Stat Highlights",
          content: (
            <>
              Add additional stats and video highlights to your game by <b>tagging</b>.
              <br />
              You can tag points, as well as rebounds, blocks, steals and assists.
            </>
          ),
        },
        {
          target: '[data-video-joyride="tag-reaction-highlights"]',
          title: "Tag Reaction Highlights",
          content: (
            <>
              Interact with the video by tagging reaction highlights on your game.
              <br />
              You can choose from a variety of icons.
            </>
          ),
        },
        {
          target: '[data-video-joyride="thanks"]',
          title: "Thanks",
          content: (
            <>
              Thanks for taking the tour.
              <br />
              You can revisit at any time by simply clicking on the <b>question mark</b> here.
            </>
          ),
        },
      ]}
    />
  );
}
