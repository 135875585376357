import { gql } from "@apollo/client";
import { SHORTCUT_KEY_CODE_VIDEO_NEXT_HIGHLIGHT, SHORTCUT_KEY_CODE_VIDEO_PREVIOUS_HIGHLIGHT } from "helpers/SHORTCUTS";
import useDOMEvent from "helpers/useDOMEvent";
import { videoPageContext } from "pages/VideoPage";
import { useContext } from "react";

export const VideoEventNavShortcutHandlerFragment = gql`
  fragment VideoEventNavShortcutHandlerFragment on Game {
    id
    events {
      id
      occurredAtSeconds
    }
  }
`;

export default function VideoEventNavShortcutHandler({ game }) {
  const { isVideoPageModalActive, activeEventId, currentTime, videoRef, filteredEventIds } =
    useContext(videoPageContext);

  const events = game?.events.filter((event) => filteredEventIds.includes(event.id) && event.id !== activeEventId);

  useDOMEvent("keydown", (evt) => {
    if (!isVideoPageModalActive) return;
    if (evt.metaKey || evt.ctrlKey || evt.altKey || evt.shiftKey) return;
    if (evt.code === SHORTCUT_KEY_CODE_VIDEO_PREVIOUS_HIGHLIGHT) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const event = events?.findLast((event) => event.occurredAtSeconds < currentTime);
      if (event) videoRef.current.currentTime = event.occurredAtSeconds;
    }
    if (evt.code === SHORTCUT_KEY_CODE_VIDEO_NEXT_HIGHLIGHT) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const event = events?.find((event) => event.occurredAtSeconds > currentTime);
      if (event) videoRef.current.currentTime = event.occurredAtSeconds;
    }
  });
  return null;
}
