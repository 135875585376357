import { gql } from "@apollo/client";
import { ButtonBase, Paper } from "@mui/material";
import MuteText from "atoms/MuteText";
import VideoThumbnail from "atoms/VideoThumbnail";
import Section from "molecules/Section";
import React from "react";

import PlaylistIcon from "./PlaylistIcon";
import PlaylistInfo, { PlaylistInfoFragment } from "./PlaylistInfo";

export const PlaylistSummaryFragment = gql`
  fragment PlaylistSummaryFragment on Playlist {
    id
    name
    description
    clipsMaximum
    playlistType
    clips {
      id
      thumbnailUrl
      videoUrl
    }
    ...PlaylistInfoFragment
  }
  ${PlaylistInfoFragment}
`;

export default function PlaylistSummary({ playlist }) {
  const firstClip = playlist?.clips[0];

  return (
    <Section icon={<PlaylistIcon playlistType={playlist.playlistType} />} title={playlist?.name}>
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          gap: 15,
        }}
      >
        <PlaylistInfo playlist={playlist} />
        {firstClip && (
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              alignItems: "flex-start",
              gap: 15,
            }}
          >
            <ButtonBase draggable="false" href={`/playlists/${playlist.id}/${firstClip.id}`}>
              <Paper
                style={{
                  overflow: "hidden",
                }}
              >
                <VideoThumbnail thumbnailUrl={firstClip.thumbnailUrl} videoUrl={firstClip.videoUrl} />
              </Paper>
            </ButtonBase>
            <div
              style={{
                display: "flex",
                flexFlow: "column nowrap",
                alignItems: "flex-start",
                gap: 15,
              }}
            >
              <MuteText>
                {playlist.clips.length}/{playlist.clipsMaximum} clips
              </MuteText>
              <a href={`/playlists/${playlist.id}`}>View full playlist</a>
            </div>
          </div>
        )}
      </div>
    </Section>
  );
}
