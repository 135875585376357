import { Paper } from "@mui/material";
import Spacer from "atoms/Spacer";
import Stack from "atoms/Stack";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import Toolbar, { ToolbarButton } from "molecules/Toolbar";
import ModalContextProvider from "providers/ModalContextProvider";
import React, { useLayoutEffect, useState } from "react";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

export default function Tour({ open, onClose, welcomeTitle, welcomeContent, steps }) {
  const [stepIndex, stepIndexSet] = useState(0);
  useLayoutEffect(() => {
    stepIndexSet(0);
  }, [open]);

  return (
    <>
      <ModalContextProvider open={open}>
        <ReactJoyride
          run={open}
          spotlightPadding={0}
          stepIndex={stepIndex}
          showProgress
          showSkipButton
          scrollToFirstStep
          continuous
          tooltipComponent={JoyrideTooltip}
          callback={({ action, index, status, type }) => {
            // https://docs.react-joyride.com/callback
            if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
              // Update state to advance the tour
              stepIndexSet(index + (action === ACTIONS.PREV ? -1 : 1));
            } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
              // You need to set our running state to false, so we can restart if we click start again.
              onClose();
            }
          }}
          steps={[
            {
              target: "body",
              placement: "center",
              disableBeacon: true,
              data: {
                title: welcomeTitle,
              },
              content: welcomeContent,
            },
            ...(steps || []).map(({ target, title, content }) => ({
              target,
              disableBeacon: true,
              data: {
                title,
              },
              content,
            })),
          ]}
        />
      </ModalContextProvider>
    </>
  );
}

function JoyrideTooltip({ index, size, skipProps, step, backProps, primaryProps, tooltipProps }) {
  return (
    <Paper {...tooltipProps}>
      <Stack padding>
        <h1
          style={{
            textTransform: "uppercase",
            fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
            fontSize: 24,
            lineHeight: 1.5,
            margin: 0,
          }}
        >
          {step.data.title}
        </h1>
        <div>{step.content}</div>
        <Toolbar>
          <ToolbarButton flat {...skipProps} title={null} label={skipProps.title} />
          <Spacer />
          <ToolbarButton {...backProps} title={null} label={backProps.title} />
          <ToolbarButton
            primary
            {...primaryProps}
            title={null}
            label={
              <>
                {primaryProps.title} ({index + 1}/{size})
              </>
            }
          />
        </Toolbar>
      </Stack>
    </Paper>
  );
}
