import ClipSvg from "assets/reactions/clip.svg";
import CoolSvg from "assets/reactions/cool.svg";
import FunnySvg from "assets/reactions/funny.svg";
import GoodJobSvg from "assets/reactions/good-job.svg";
import SadSvg from "assets/reactions/sad.svg";
import WowSvg from "assets/reactions/wow.svg";

const REACTION_TYPES = [
  {
    name: "funny",
    imgSrc: FunnySvg,
    title: "Funny",
  },
  {
    name: "wow",
    imgSrc: WowSvg,
    title: "Wow",
  },
  {
    name: "sad",
    imgSrc: SadSvg,
    title: "Sad",
  },
  {
    name: "cool",
    imgSrc: CoolSvg,
    title: "Cool",
  },
  {
    name: "good-job",
    imgSrc: GoodJobSvg,
    title: "Good Job",
  },
  {
    name: "clip",
    imgSrc: ClipSvg,
    title: "Clip",
  },
];

export default REACTION_TYPES;
