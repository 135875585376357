import { gql } from "@apollo/client";
import FetchMoreButton from "atoms/FetchMoreButton";
import Grid from "atoms/Grid";
import { PageContentStack } from "atoms/Stack";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import Section from "molecules/Section";
import React from "react";
import { Helmet } from "react-helmet";

import getCompetitionStyle from "./potw/getCompetitionStyle";
import PotwCompetitionCard, { PotwCompetitionCardFragment } from "./potw/PotwCompetitionCard";
import PotwIntroBanner from "./potw/PotwIntroBanner";

export default function PotwPage() {
  useEnforceAuth({ enforceAuthenticated: true });

  const [data, dataMeta] = useData(gql`
    query PotwPage($offset: Int) {
      currentUser {
        id
        potwCompetitions(offset: $offset, limit: 10) {
          id
          ...PotwCompetitionCardFragment
        }
        potwCompetitionsCount
      }
    }
    ${PotwCompetitionCardFragment}
  `);

  return (
    <>
      <Helmet>
        <title>Plays of the Week</title>
      </Helmet>
      <PotwIntroBanner />
      <PageContentStack>
        <Section title="All Competitions">
          <Grid>
            {data?.currentUser?.potwCompetitions
              .map((potwCompetition) => ({
                ...potwCompetition,
                competitionStyle: getCompetitionStyle(potwCompetition.competitionType),
              }))
              .map((potwCompetition) => (
                <PotwCompetitionCard potwCompetition={potwCompetition} key={potwCompetition.id} />
              ))}
          </Grid>
          <FetchMoreButton
            dataMeta={dataMeta}
            rows={data?.currentUser?.potwCompetitions}
            rowsCount={data?.currentUser?.potwCompetitionsCount}
          />
        </Section>
      </PageContentStack>
    </>
  );
}
