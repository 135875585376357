import { Dialog as MUIDialog, Grow, IconButton } from "@mui/material";
import { COLOR_PRIMARY_DARKER, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { SPACING, SPACING_DENSE } from "helpers/spacings";
import { CloseCircle } from "mdi-material-ui";
import ModalContextProvider from "providers/ModalContextProvider";
import React from "react";
import { useWindowSize } from "react-use-size";

import Toolbar, { ToolbarButton } from "./Toolbar";

export default function Dialog({
  name,
  title,
  inverse = false,
  fullWidth = false,
  mediumWidth = false,
  fullHeight = false,
  open,
  onClose,
  children,
  actions,
  ...others
}) {
  let maxWidth = "sm";
  if (mediumWidth) maxWidth = "md";
  if (fullWidth) maxWidth = false;
  const windowSize = useWindowSize();
  const fullScreen = windowSize.width < 500 || windowSize.height < 500;

  return (
    <ModalContextProvider open={open} name={`Dialog-${name}`}>
      <MUIDialog
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        fullWidth
        {...others}
        open={open}
        onClose={onClose}
        PaperProps={{
          ...others.PaperProps,
          style: {
            position: "relative",
            display: "flex",
            flexFlow: "column nowrap",
            gap: SPACING,
            padding: SPACING,
            ...(inverse && {
              backgroundColor: COLOR_PRIMARY_DARKER,
              color: COLOR_WHITE,
            }),
            ...(fullHeight && {
              height: "100svh",
            }),
            ...others.PaperProps?.style,
          },
        }}
        TransitionComponent={Grow}
        TransitionProps={{ easing: "ease-in-out", timeout: 300 }}
      >
        {title && (
          <h1
            style={{
              textTransform: "uppercase",
              fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
              fontSize: 24,
              lineHeight: 1.5,
              margin: 0,
            }}
          >
            {title}
          </h1>
        )}
        {children}
        {actions && <Toolbar>{actions}</Toolbar>}
        <IconButton
          size="small"
          color="inherit"
          onClick={onClose}
          style={{ position: "absolute", zIndex: 1, top: SPACING_DENSE, right: SPACING_DENSE }}
        >
          <CloseCircle />
        </IconButton>
      </MUIDialog>
    </ModalContextProvider>
  );
}

export function DialogActionButton({ ...others }) {
  return <ToolbarButton {...others} />;
}
