import readFileBase64 from "helpers/readFileBase64";
import selectFiles from "helpers/selectFiles";
import useActionFragment from "helpers/useActionFragment";
import { progressBar } from "helpers/useProgressBar";
import useShowMessage from "helpers/useShowMessage";

export default function useChangeProfilePhoto({ personId }) {
  const onsenPersonUpdateAvatar = useActionFragment("onsenPersonUpdateAvatar", "person { id photoAvatarUrl }");
  const showMessage = useShowMessage();

  return () =>
    selectFiles(
      {
        accept: "image/*",
      },
      ([file]) =>
        progressBar(async () => {
          if (!file) return;
          const filename = file.name;
          const base64 = await readFileBase64(file);
          await onsenPersonUpdateAvatar({
            input: { personId, base64, filename },
          });
          showMessage("Profile photo updated");
        }),
    );
}
