import { gql } from "@apollo/client";
import { COLOR_PRIMARY } from "helpers/colors";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import Message from "molecules/Message";
import Splash from "molecules/Splash";
import EditAccountForm, { EditAccountFormFragment } from "pages/account/EditAccountForm";
import React from "react";

export default function AccountPage() {
  useEnforceAuth({ enforceAuthenticated: true });

  const [data] = useData(gql`
    query AccountPageCurrentUser {
      currentUser {
        id
        email
        onboarded
        ...EditAccountFormFragment
      }
    }
    ${EditAccountFormFragment}
  `);

  return (
    <>
      <Splash
        title="Account Settings"
        descriptionHeader="Edit your account details"
        descriptionValue={data?.currentUser?.email}
        color={COLOR_PRIMARY}
      />
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          gap: 20,
          padding: 20,
        }}
      >
        {data?.currentUser && !data.currentUser.onboarded && (
          <Message>
            Set your password now and log in anytime at{" "}
            <a target="_blank" href="https://my.gloryleague.basketball/login" rel="noreferrer">
              my.gloryleague.basketball
            </a>{" "}
            to access your Glory League.
          </Message>
        )}
        {data?.currentUser && <EditAccountForm user={data.currentUser} />}
      </div>
    </>
  );
}
