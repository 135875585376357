import { gql } from "@apollo/client";
import { COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_ROBOTO } from "helpers/FONT";
import gtag from "helpers/gtag";
import useActionFragment from "helpers/useActionFragment";
import useData from "helpers/useData";
import getCompetitionStyle from "pages/potw/getCompetitionStyle";
import RatingStars from "pages/potw/RatingStars";
import React from "react";

export const VideoEventPotwRatingFragment = gql`
  fragment VideoEventPotwRatingFragment on Game {
    id
    livePotwCompetition {
      id
      competitionType
    }
  }
`;

export default function VideoEventPotwRating({ game, eventId, mobileEventStream = false }) {
  const [data] = useData(
    gql`
      query VideoEventPotwRating($eventId: ID!) {
        event(id: $eventId) {
          id
          livePotwEntry {
            id
            currentUserRating
          }
          potwEntriesAny
        }
      }
    `,
    { eventId },
  );

  const livePotwCompetition = game?.livePotwCompetition;

  const competitionStyle = livePotwCompetition && getCompetitionStyle(livePotwCompetition.competitionType);
  const eventPotwRate = useActionFragment("eventPotwRate", "event { id livePotwEntry { id currentUserRating } }");

  return (
    livePotwCompetition && (
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center",
          gap: 5,
          padding: mobileEventStream ? 10 : 0,
        }}
      >
        <img
          src={competitionStyle.logoImage}
          alt={competitionStyle.title}
          style={{ height: mobileEventStream ? 48 : 32 }}
        />
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: mobileEventStream ? 16 : 10,
              color: COLOR_WHITE,
              fontFamily: FONT_FAMILY_ROBOTO,
            }}
          >
            Your rating
          </div>
          <RatingStars
            currentRating={data?.event.livePotwEntry?.currentUserRating}
            onRate={(rating) => {
              gtag("event", "potw_rate", {
                from: "video_page",
                rating,
              });
              eventPotwRate({ input: { eventId, rating } });
            }}
            style={{ fontSize: mobileEventStream ? 32 : 14 }}
          />
        </div>
      </div>
    )
  );
}
