import { gql } from "@apollo/client";
import { ButtonBase, Paper } from "@mui/material";
import VideoThumbnail from "atoms/VideoThumbnail";
import EventMarker, { EventMarkerFragment } from "molecules/EventMarker";
import ViewCountLabel from "molecules/ViewCountLabel";
import HighlightPlayerDialog from "pages/video_player/HighlightPlayerDialog";
import React, { useState } from "react";

export const EventCardFragment = gql`
  fragment EventCardFragment on EventView {
    id
    thumbnailUrl
    videoUrl
    videoPending
    viewCount
    ...EventMarkerFragment
  }
  ${EventMarkerFragment}
`;

export default function EventCard({ eventView }) {
  const [dialogOpen, dialogOpenSet] = useState(false);
  return (
    <>
      <HighlightPlayerDialog eventViewId={eventView.id} open={dialogOpen} onClose={() => dialogOpenSet(false)} />
      <ButtonBase
        component={ButtonBase}
        href={`/event/${eventView.id}`}
        onClick={(event) => {
          if (event.ctrlKey || event.shiftKey || event.metaKey || event.altKey) return;
          event.preventDefault();
          dialogOpenSet(true);
        }}
        style={{
          display: "flex",
          justifyContent: "stretch",
          alignItems: "stretch",
        }}
      >
        <VideoThumbnail
          component={Paper}
          previewable
          thumbnailUrl={eventView.thumbnailUrl}
          videoUrl={eventView.videoUrl}
          processing={eventView.videoPending}
          style={{ width: "100%", aspectRatio: "7/4" }}
          leftLabel={<EventMarker size={18} eventView={eventView} />}
          rightLabel={<ViewCountLabel viewCount={eventView.viewCount} />}
        />
      </ButtonBase>
    </>
  );
}
