import { alpha, Slider } from "@mui/material";
import { COLOR_BLACK } from "helpers/colors";
import { SPACING_DENSE } from "helpers/spacings";
import { useVideoVolumn } from "helpers/useVideoVolumn";
import { VolumeHigh, VolumeMedium, VolumeOff } from "mdi-material-ui";
import React, { useState } from "react";

import PlayerButton from "./PlayerButton";

export default function VolumeButton({ enabled }) {
  const [volume, volumeSet] = useVideoVolumn();
  const volumeState = volume === 0 ? "off" : volume <= 30 ? "medium" : "high";
  const [volumnHover, volumnHoverSet] = useState(false);

  return (
    <>
      <PlayerButton
        title="Volume"
        onHoveringChange={volumnHoverSet}
        disabled={!enabled}
        onClick={() => {
          if (volumeState === "off") {
            volumeSet(30);
          } else if (volumeState === "medium") {
            volumeSet(100);
          } else {
            volumeSet(0);
          }
        }}
        icon={
          <>
            {!enabled && <VolumeOff />}
            {enabled &&
              {
                off: <VolumeOff />,
                medium: <VolumeMedium />,
                high: <VolumeHigh />,
              }[volumeState]}
          </>
        }
        decorators={
          <div
            style={{
              backgroundColor: alpha(COLOR_BLACK, 0.8),
              position: "absolute",
              width: 100,
              right: 0,
              top: "50%",
              transform: "translate(100%, -50%)",
              opacity: volumnHover ? 1 : 0,
              pointerEvents: volumnHover ? "auto" : "none",
              transition: "opacity 0.2s",
              padding: SPACING_DENSE,
              zIndex: 1,
            }}
          >
            <Slider size="small" color="inherit" value={volume} onChange={(_, value) => volumeSet(value)} />
          </div>
        }
      />
    </>
  );
}
