import { gql } from "@apollo/client";
import { FormHelperText, TextField } from "@mui/material";
import Stack from "atoms/Stack";
import useActionFragment from "helpers/useActionFragment";
import useConfirmDialog from "helpers/useConfirmDialog";
import useData from "helpers/useData";
import useShowMessage from "helpers/useShowMessage";
import { TrashCan } from "mdi-material-ui";
import FormDialog from "molecules/FormDialog";
import Message from "molecules/Message";
import PersonCard, { PersonCardActionButton, PersonCardFragment } from "molecules/PersonCard";
import Section from "molecules/Section";
import Toolbar, { ToolbarButton } from "molecules/Toolbar";
import React, { useLayoutEffect, useState } from "react";

export default function PersonSupporters({ personId }) {
  const [data] = useData(
    gql`
      query PersonSettings($personId: ID!) {
        person(id: $personId) {
          id
          supporters {
            id
            ...PersonCardFragment
          }
        }
      }
      ${PersonCardFragment}
    `,
    { personId },
  );
  const [addSupporterDialogOpen, addSupporterDialogOpenSet] = useState(false);
  const onsenPersonRemoveSupporter = useActionFragment("onsenPersonRemoveSupporter", "person { id supporters { id } }");
  const showMessage = useShowMessage();
  const [confirmDialog, confirmDialogOpen] = useConfirmDialog();

  return (
    <Section title="Supporters">
      {confirmDialog}
      {data?.person?.supporters.length === 0 && <div>(You have no supporters yet)</div>}
      <Stack horizontal>
        {data?.person?.supporters.map((supporter) => (
          <PersonCard
            key={supporter.id}
            person={supporter}
            actionButtons={
              <>
                <PersonCardActionButton
                  label="Remove"
                  icon={<TrashCan />}
                  onClick={async () => {
                    await confirmDialogOpen({
                      content: "Are you sure you want to remove this supporter?",
                      action: "Remove Supporter",
                    });
                    await onsenPersonRemoveSupporter({
                      input: { personId, supporterId: supporter.id },
                    });
                    showMessage("Supporter removed successfully.");
                  }}
                />
              </>
            }
          />
        ))}
      </Stack>
      <FormHelperText>Supporters can view your game stats and videos.</FormHelperText>
      <Toolbar>
        <ToolbarButton primary label="Add Supporter" onClick={() => addSupporterDialogOpenSet(true)} />
      </Toolbar>
      <AddSupporterDialog
        personId={personId}
        open={addSupporterDialogOpen}
        onClose={() => addSupporterDialogOpenSet(false)}
      />
    </Section>
  );
}

function AddSupporterDialog({ personId, open, onClose }) {
  const [email, emailSet] = useState("");
  const [firstName, firstNameSet] = useState("");
  const [lastName, lastNameSet] = useState("");
  const onsenPersonAddSupporter = useActionFragment("onsenPersonAddSupporter", "person { id supporters { id } }");

  useLayoutEffect(() => {
    emailSet("");
    firstNameSet("");
    lastNameSet("");
  }, [open]);

  const showMessage = useShowMessage();

  return (
    <FormDialog
      title="Add Supporter"
      open={open}
      onClose={onClose}
      onSubmit={async () => {
        await onsenPersonAddSupporter({
          input: {
            personId,
            email,
            firstName,
            lastName,
          },
        });
        showMessage("Supporter added successfully.");
      }}
    >
      <Message>
        Supporters can view your game stats and videos.
        <p>
          We will send an email to the supporter with instructions to setup a Glory League account if they haven&apos;t
          already.
        </p>
      </Message>
      <TextField
        label="Supporter Email"
        type="email"
        autoComplete="email"
        value={email}
        onChange={(event) => emailSet(event.target.value)}
        required
      />
      <TextField
        label="First Name"
        value={firstName}
        autoComplete="given-name"
        onChange={(event) => firstNameSet(event.target.value)}
        required
      />
      <TextField
        label="Last Name"
        value={lastName}
        autoComplete="family-name"
        onChange={(event) => lastNameSet(event.target.value)}
      />
    </FormDialog>
  );
}
