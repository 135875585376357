const SHORTCUTS = [];
export default SHORTCUTS;

export const SHORTCUT_KEY_CODE_VIDEO_PLAY_PAUSE = "Space";
export const SHORTCUT_KEY_CODE_VIDEO_SKIP_FORWARD = "ArrowRight";
export const SHORTCUT_KEY_CODE_VIDEO_SKIP_BACKWARD = "ArrowLeft";
export const SHORTCUT_KEY_CODE_VIDEO_SLOW_DOWN = "KeyS";
export const SHORTCUT_KEY_CODE_VIDEO_SPEED_UP = "KeyF";

export const SHORTCUT_KEY_CODE_VIDEO_PAGE_NEW_STAT = "KeyD";
export const SHORTCUT_KEY_CODE_VIDEO_PAGE_NEW_REACTION = "KeyR";

export const SHORTCUT_KEY_CODE_VIDEO_PREVIOUS_HIGHLIGHT = "KeyK";
export const SHORTCUT_KEY_CODE_VIDEO_NEXT_HIGHLIGHT = "KeyL";

export const SHORTCUT_KEY_CODE_VIDEO_PAGE_EDIT_HIGHLIGHT = "KeyY";
export const SHORTCUT_KEY_CODE_VIDEO_PAGE_SHARE_HIGHLIGHT = "KeyU";
export const SHORTCUT_KEY_CODE_VIDEO_PAGE_DOWNLOAD_HIGHLIGHT = "KeyI";
export const SHORTCUT_KEY_CODE_VIDEO_PAGE_TOGGLE_STAR = "KeyO";
export const SHORTCUT_KEY_CODE_VIDEO_PAGE_ADD_TO_PLAYLIST = "KeyP";

export const SHORTCUT_KEY_CODE_SHOW_SHORTCUTS = "Slash";

export const SHORTCUT_KEY_LABEL_VIDEO_PLAY_PAUSE = "Space";
export const SHORTCUT_KEY_LABEL_VIDEO_SKIP_FORWARD = "→";
export const SHORTCUT_KEY_LABEL_VIDEO_SKIP_BACKWARD = "←";
export const SHORTCUT_KEY_LABEL_VIDEO_SLOW_DOWN = "S";
export const SHORTCUT_KEY_LABEL_VIDEO_SPEED_UP = "F";

export const SHORTCUT_KEY_LABEL_VIDEO_PAGE_NEW_STAT = "D";
export const SHORTCUT_KEY_LABEL_VIDEO_PAGE_NEW_REACTION = "R";

export const SHORTCUT_KEY_LABEL_VIDEO_PREVIOUS_HIGHLIGHT = "K";
export const SHORTCUT_KEY_LABEL_VIDEO_NEXT_HIGHLIGHT = "L";

export const SHORTCUT_KEY_LABEL_VIDEO_PAGE_EDIT_HIGHLIGHT = "Y";
export const SHORTCUT_KEY_LABEL_VIDEO_PAGE_SHARE_HIGHLIGHT = "U";
export const SHORTCUT_KEY_LABEL_VIDEO_PAGE_DOWNLOAD_HIGHLIGHT = "I";
export const SHORTCUT_KEY_LABEL_VIDEO_PAGE_TOGGLE_STAR = "O";
export const SHORTCUT_KEY_LABEL_VIDEO_PAGE_ADD_TO_PLAYLIST = "P";

export const SHORTCUT_KEY_LABEL_SHOW_SHORTCUTS = "/";
