import { gql } from "@apollo/client";
import React from "react";

export const EventSubjectLabelFragment = gql`
  fragment EventSubjectLabelFragment on Event {
    id
    gamePlayer {
      id
      playerNumber
    }
    person {
      id
      shortNameWithAnonymisation
    }
    createdByPerson {
      id
      shortNameWithAnonymisation
    }
    team {
      id
      name
    }
  }
`;

export default function EventSubjectLabel({ event }) {
  return (
    <>
      {event?.person?.shortNameWithAnonymisation ||
        event?.team?.name ||
        event?.createdByPerson?.shortNameWithAnonymisation}
      {event?.gamePlayer?.playerNumber && <> #{event.gamePlayer.playerNumber}</>}
    </>
  );
}
