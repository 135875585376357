import { alpha } from "@mui/material";
import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import { Eye } from "mdi-material-ui";
import React from "react";

export default function ViewCountLabel({
  viewCount,
  // size is not implemented
  size,
  inverse = false,
  ...others
}) {
  const textColor = inverse ? COLOR_WHITE : COLOR_BLACK;
  const backgroundColor = inverse ? alpha(COLOR_BLACK, 0.8) : alpha(COLOR_WHITE, 0.8);

  return (
    <div
      {...others}
      style={{
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "center",
        gap: "0.2em",
        color: textColor,
        backgroundColor,
        padding: 4,
        borderRadius: 4,
        ...others.style,
      }}
    >
      <Eye fontSize="inherit" />
      {viewCount}
    </div>
  );
}
