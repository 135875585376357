import { matchPath, useNavigate } from "react-router-dom";
import { routes } from "routes";

import { progressBar } from "./useProgressBar";

async function navigateFallback(href, { replace = false } = {}) {
  if (replace) window.location.replace(href);
  else window.location.href = href;
}

export default function useGotoUrl() {
  const navigate = useNavigate();
  return (href, { replace = false } = {}) => {
    const url = new URL(href, window.location.href);
    if (url.origin !== window.location.origin) return navigateFallback(href, { replace });
    if (!routes.find(([path]) => matchPath(path, url.pathname))) return navigateFallback(href, { replace });
    // force progressbar to run before new component starts loading
    progressBar(() => new Promise((resolve) => setTimeout(resolve, 100)));
    navigate(url.pathname + url.search, { replace });
  };
}
