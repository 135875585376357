import { SPACING, SPACING_DENSE, SPACING_PADDING, SPACING_VERY_DENSE } from "helpers/spacings";
import React, { cloneElement, createContext } from "react";

export const stackContext = createContext({});

export default function Stack({
  component: Component = "div",
  element = <Component />,
  componentRef,
  children,
  horizontal = false,
  // warning: we should avoid using nowrap for as much as possible for responsive design
  noWrap = false,
  dense = false,
  veryDense = false,
  noGap = false,
  alignItemsCenter = false,
  alignItemsStart = false,
  padding = false,
  reverse = false,
  ...others
}) {
  let spacing = SPACING;
  if (dense) {
    spacing = SPACING_DENSE;
  } else if (veryDense) {
    spacing = SPACING_VERY_DENSE;
  } else if (noGap) {
    spacing = 0;
  }

  return (
    <stackContext.Provider value={{ dense, veryDense }}>
      {cloneElement(element, {
        ref: componentRef,
        ...others,
        style: {
          ...element.props.style,
          display: "flex",
          gap: spacing,
          flexDirection: reverse ? "column-reverse" : "column",
          ...(horizontal && {
            flexDirection: reverse ? "row-reverse" : "row",
            // wrapping should only be used for horizontal stacks
            flexWrap: noWrap ? "nowrap" : "wrap",
          }),
          justifyContent: "flex-start",
          alignItems: "stretch",
          ...(alignItemsCenter && {
            alignItems: "center",
          }),
          ...(alignItemsStart && {
            alignItems: "flex-start",
          }),
          ...(padding && {
            padding: spacing,
          }),
          ...others.style,
        },
        children,
      })}
    </stackContext.Provider>
  );
}

export function PageContentStack({ children, ...others }) {
  return (
    <Stack
      {...others}
      style={{
        padding: SPACING_PADDING,
        ...others.style,
      }}
    >
      {children}
    </Stack>
  );
}
