import { TextField } from "@mui/material";
import useActionFragment from "helpers/useActionFragment";
import useShowMessage from "helpers/useShowMessage";
import FormDialog from "molecules/FormDialog";
import React, { useState } from "react";

export default function CreatePlaylistDialog({ open, onClose, onPlaylistCreated }) {
  const [name, nameSet] = useState("");
  const [description, descriptionSet] = useState("");
  const createPlaylist = useActionFragment("createPlaylist", `playlist { id }`, "currentUser { id playlists { id } }");
  const showMessage = useShowMessage();

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      title="Create a new playlist"
      onSubmit={async () => {
        const result = await createPlaylist({
          input: {
            name,
            description,
          },
        });
        showMessage(`Playlist ${name} created.`);
        const playlistId = result.createPlaylist.playlist.id;
        await onPlaylistCreated({ playlistId });
      }}
    >
      <TextField
        autoFocus
        label="Playlist name"
        value={name}
        onChange={(event) => nameSet(event.target.value)}
        required
      />
      <TextField
        label="Description"
        value={description}
        onChange={(event) => descriptionSet(event.target.value)}
        multiline
        rows={5}
      />
    </FormDialog>
  );
}
