import Droppable from "atoms/Droppable";
import useActionFragment from "helpers/useActionFragment";
import React from "react";

export default function PlaylistDroppable({ playlistId, ...others }) {
  const clipMoveToPlaylist = useActionFragment(
    "clipMoveToPlaylist",
    `
      clip { id playlist { id } }
      playlist { id clips { id } }
      oldPlaylist { id clips { id } }
    `,
  );

  return (
    <Droppable
      {...others}
      dataType="clip"
      onDropData={(clip) => {
        if (clip.playlist.id === playlistId) return;
        clipMoveToPlaylist({
          input: { clipId: clip.id, playlistId },
        });
      }}
    />
  );
}
