import { gql } from "@apollo/client";
import useData from "helpers/useData";
import React from "react";
import { Helmet } from "react-helmet";
import NotFoundPage from "shared/NotFoundPage";

import ReelPlayer, { ReelPlayerFragment } from "./video_player/ReelPlayer";

export default function ReelPage({ reelViewId }) {
  const [data] = useData(
    gql`
      query ReelPage($reelViewId: ID!) {
        reelView(id: $reelViewId) {
          id
          title
          ...ReelPlayerFragment
        }
      }
      ${ReelPlayerFragment}
    `,
    { reelViewId },
  );
  if (data && !data.reelView) return <NotFoundPage />;

  return (
    <>
      <Helmet>
        <title>{data?.reelView?.title}</title>
      </Helmet>
      <ReelPlayer reelViewId={reelViewId} reelView={data?.reelView} />
    </>
  );
}
