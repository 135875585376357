import { gql } from "@apollo/client";
import Draggable from "atoms/Draggable";
import React from "react";

export const ClipDraggableFragment = gql`
  fragment ClipDraggableFragment on Clip {
    id
    playlist {
      id
    }
  }
`;

export default function ClipDraggable({ clip, draggable, ...others }) {
  return <Draggable dataType="clip" data={clip} draggable={draggable} {...others} />;
}
