import { gql } from "@apollo/client";
import { IconButton } from "@mui/material";
import MuteText from "atoms/MuteText";
import gtag from "helpers/gtag";
import useActionFragment from "helpers/useActionFragment";
import useConfirmDialog from "helpers/useConfirmDialog";
import useData from "helpers/useData";
import { ArrowDownBold, ArrowUpBold, DragHorizontalVariant, TrashCan } from "mdi-material-ui";
import GameTitle from "molecules/GameTitle";
import Message from "molecules/Message";
import Splash from "molecules/Splash";
import ClipDraggable, { ClipDraggableFragment } from "pages/playlists/ClipDraggable";
import ClipDroppable from "pages/playlists/ClipDroppable";
import PlaylistInfo, { PlaylistInfoFragment } from "pages/playlists/PlaylistInfo";
import React from "react";
import { useBreakpoint } from "react-use-size";
import NotFoundPage from "shared/NotFoundPage";

import ClipThumbnail, { ClipThumbnailFragment } from "./playlists/ClipThumbnail";

export default function PlaylistPage({ playlistId }) {
  const isSmall = useBreakpoint(500); //matches video thumbnail resize

  const [confirmDialog, confirmDialogOpen] = useConfirmDialog();
  const [data] = useData(
    gql`
      query Playlist($playlistId: ID!) {
        playlist(id: $playlistId) {
          id
          name
          clipsMaximumReached
          clipsMaximum
          canUpdate
          canUpdateClips
          clips {
            id
            homeTeamName
            awayTeamName
            playedOn
            ...ClipDraggableFragment
            ...ClipThumbnailFragment
            description
          }
          ...PlaylistInfoFragment
        }
      }
      ${ClipThumbnailFragment}
      ${ClipDraggableFragment}
      ${PlaylistInfoFragment}
    `,
    { playlistId },
  );
  const clipDelete = useActionFragment("clipDelete", "playlist { id clips { id } }");
  const clipMove = useActionFragment(
    "clipMoveToClip",
    `
      clip { id playlist { id } }
      playlist { id clips { id } }
      oldPlaylist { id clips { id } }
    `,
  );

  if (data && !data.playlist) return <NotFoundPage />;

  return (
    <>
      {confirmDialog}
      <Splash
        title={data?.playlist?.name}
        descriptionHeader="Playlist"
        descriptionValue={<>{data?.playlist?.clips.length} clips</>}
        color="#00315f"
      />
      <div
        style={{
          padding: 20,
          display: "flex",
          flexFlow: "column nowrap",
          gap: 20,
        }}
      >
        <PlaylistInfo playlist={data?.playlist} />
        {data?.playlist?.clipsMaximumReached && data?.playlist?.canUpdateClips && (
          <Message>You have reached the maximum number of clips. Delete unwanted ones to free up space</Message>
        )}
        <MuteText>
          {data?.playlist?.clips.length}/{data?.playlist?.clipsMaximum} clips used
        </MuteText>
        {data?.playlist?.clips.map((clip, index) => (
          <ClipDroppable
            key={clip.id}
            clipId={clip.id}
            vertical
            component={
              <ClipDraggable
                key={clip.id}
                draggable={data?.playlist.canUpdateClips}
                clip={clip}
                component={
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row wrap",
                      justifyContent: "stretch",
                      gap: 10,
                      alignItems: "center",
                    }}
                  >
                    {/* Grip container */}
                    {data?.playlist.canUpdateClips && !isSmall && (
                      <div
                        style={{
                          cursor: "grab",
                        }}
                      >
                        <DragHorizontalVariant />
                      </div>
                    )}

                    {/* Curate button container */}
                    {data?.playlist.canUpdateClips && (
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "column nowrap",
                        }}
                      >
                        <IconButton
                          disabled={index === 0}
                          onClick={async () => {
                            await clipMove({
                              input: {
                                clipId: clip.id,
                                toClipId: data?.playlist.clips[index - 1].id,
                                after: false,
                              },
                            });
                            gtag("event", "playlist_move_clip", {
                              method: "click",
                            });
                          }}
                        >
                          <ArrowUpBold />
                        </IconButton>
                        <IconButton
                          disabled={index === data?.playlist.clips.length - 1}
                          onClick={async () => {
                            await clipMove({
                              input: {
                                clipId: clip.id,
                                toClipId: data?.playlist.clips[index + 1].id,
                                after: true,
                              },
                            });
                            gtag("event", "playlist_move_clip", {
                              method: "click",
                            });
                          }}
                        >
                          <ArrowDownBold />
                        </IconButton>
                      </div>
                    )}
                    <ClipThumbnail
                      previewable
                      clip={clip}
                      href={`/playlists/${playlistId}/${clip.id}`}
                      style={{
                        flex: "0 0 auto",
                      }}
                    />
                    <div
                      style={{
                        flex: "1 1 auto",
                        display: "flex",
                        flexFlow: "column wrap",
                        alignSelf: "stretch",
                        gap: 10,
                      }}
                    >
                      <GameTitle homeTeamName={clip?.homeTeamName} awayTeamName={clip?.awayTeamName} dense={isSmall} />
                      <MuteText>{clip?.playedOn}</MuteText>
                      {clip?.description && (
                        <div
                          style={{
                            flex: "1 1 auto",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              overflow: "hidden",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {clip.description}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Toolbar container */}
                    {data?.playlist?.canUpdateClips && (
                      <IconButton
                        title="Remove"
                        onClick={async () => {
                          await confirmDialogOpen({
                            title: "Remove clip from playlist",
                          });
                          await clipDelete({
                            input: { clipId: clip.id },
                          });
                        }}
                      >
                        <TrashCan />
                      </IconButton>
                    )}
                  </div>
                }
              />
            }
          />
        ))}
      </div>
    </>
  );
}
