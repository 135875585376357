import { gql } from "@apollo/client";
import { alpha, CircularProgress, Collapse, IconButton } from "@mui/material";
import { ButtonBase } from "@mui/material";
import { COLOR_BLACK } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import gtag from "helpers/gtag";
import useActionFragment from "helpers/useActionFragment";
import { FastForward, Refresh } from "mdi-material-ui";
import RatingStars from "pages/potw/RatingStars";
import React, { useEffect, useState } from "react";

export const PotwEntryOverlayFragment = gql`
  fragment PotwEntryOverlayFragment on PotwEntry {
    id
    currentUserRating
  }
`;

const WAIT_TOTAL = 3000;

export default function PotwEntryOverlay({
  potwEntry,
  waiting = false,
  onWaitingEnd,
  onReplayClick,
  onNextClick,
  ...others
}) {
  const [hovering, hoveringSet] = useState(false);
  const [waitValue, waitValueSet] = useState(0);
  const [waitHolding, waitHoldingSet] = useState(false);

  useEffect(() => {
    waitHoldingSet(waiting && hovering);
  }, [waiting, hovering]);

  useEffect(() => {
    if (waiting && !waitHolding) {
      if (waitValue < WAIT_TOTAL) {
        const timeout = setTimeout(() => {
          waitValueSet((prev) => prev + 100);
        }, 100);
        return () => clearTimeout(timeout);
      } else {
        waitHoldingSet(true);
        onWaitingEnd?.();
      }
    }
  }, [waiting, waitHolding, waitValue]);

  useEffect(() => {
    if (!waiting) waitValueSet(0);
  }, [waiting]);

  const potwEntryRate = useActionFragment("potwEntryRate", "potwEntry { id currentUserRating }");

  return (
    <div
      onMouseEnter={() => hoveringSet(true)}
      onMouseLeave={() => hoveringSet(false)}
      {...others}
      style={{
        ...others.style,
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: alpha(COLOR_BLACK, 0.5),
        padding: 20,
        gap: 10,
        fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
        fontSize: 16,
      }}
    >
      <Collapse orientation="horizontal" in={waiting}>
        <div
          style={{
            position: "relative",
          }}
        >
          <CircularProgress
            key={waiting}
            color="inherit"
            variant="determinate"
            value={(waitValue / WAIT_TOTAL) * 100}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {Math.ceil((WAIT_TOTAL - waitValue) / 1000)}
          </div>
        </div>
      </Collapse>
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "center",
          gap: 10,
        }}
      >
        <div style={{ textTransform: "uppercase" }}>Rate this Play</div>
        <RatingStars
          style={{ fontSize: 22 }}
          currentRating={potwEntry?.currentUserRating}
          onRate={(rating) => {
            gtag("event", "potw_rate", {
              from: "potw_competition_page",
              rating,
            });
            potwEntryRate({ input: { potwEntryId: potwEntry.id, rating } });
          }}
        />
        {potwEntry?.currentUserRating !== null && (
          <div style={{ fontSize: ".6em", opacity: 0.8 }}>
            <ButtonBase
              href="#"
              onClick={() => {
                gtag("event", "potw_remove_rating");
                potwEntryRate({
                  input: { potwEntryId: potwEntry.id, rating: null },
                });
              }}
            >
              remove my rating
            </ButtonBase>
          </div>
        )}
      </div>
      <Collapse orientation="horizontal" in={waiting}>
        <IconButton color="inherit" onClick={onReplayClick}>
          <Refresh />
        </IconButton>
      </Collapse>
      <Collapse orientation="horizontal" in={waiting}>
        <IconButton color="inherit" onClick={onNextClick}>
          <FastForward />
        </IconButton>
      </Collapse>
    </div>
  );
}
