import EventPage from "pages/EventPage";
import GameOverviewPage from "pages/GameOverviewPage";
import GamePage from "pages/GamePage";
import LoginPage from "pages/LoginPage";
import PeoplePage from "pages/PeoplePage";
import PersonPage from "pages/PersonPage";
import PersonSettingsPage from "pages/PersonSettingsPage";
import PersonTeamsPage from "pages/PersonTeamsPage";
import PotwCompetitionVideoPage from "pages/PotwCompetitionVideoPage";
import ReelPage from "pages/ReelPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import RootPage from "pages/RootPage";
import TeamGamesPage from "pages/TeamGamesPage";
import TeamLeaderboardPage from "pages/TeamLeaderboardPage";
import TeamPage from "pages/TeamPage";
import TeamPerformancePage from "pages/TeamPerformancePage";
import TeamRosterPage from "pages/TeamRosterPage";
import TeamSeasonPage from "pages/TeamSeasonPage";
import TeamsPage from "pages/TeamsPage";
import VideoPage from "pages/VideoPage";

import AccountPage from "./pages/AccountPage";
import ClipPage from "./pages/ClipPage";
import ConfigurePasswordPage from "./pages/ConfigurePasswordPage";
import GamesPage from "./pages/GamesPage";
import LeaderboardsPage from "./pages/LeaderboardsPage";
import PlaylistPage from "./pages/PlaylistPage";
import PlaylistsPage from "./pages/PlaylistsPage";
import PotwCompetitionPage from "./pages/PotwCompetitionPage";
import PotwPage from "./pages/PotwPage";

export const routes = [
  ["/", RootPage],
  ["/login", LoginPage],
  ["/reset-password", ResetPasswordPage],
  ["/configure-password", ConfigurePasswordPage],
  ["/games", GamesPage],
  ["/game/:gameId", GamePage],
  ["/game/:gameId/overview", GameOverviewPage],
  ["/game/:gameId/video", VideoPage],
  ["/teams", TeamsPage],
  ["/team/:teamId", TeamPage],
  ["/team/:teamId/roster", TeamRosterPage],
  ["/team/:teamId/games", TeamGamesPage],
  ["/team/:teamId/leaderboard", TeamLeaderboardPage],
  ["/team/:teamId/performance", TeamPerformancePage],
  ["/team/:teamId/season", TeamSeasonPage],
  ["/people", PeoplePage],
  ["/person/:personId", PersonPage],
  ["/person/:personId/settings", PersonSettingsPage],
  ["/person/:personId/teams", PersonTeamsPage],
  ["/leaderboards", LeaderboardsPage],
  ["/playlists", PlaylistsPage],
  ["/playlists/:playlistId", PlaylistPage],
  ["/playlists/:playlistId/:clipId", ClipPage],
  ["/account", AccountPage],
  ["/potw", PotwPage],
  ["/potw/competitions/:potwCompetitionId", PotwCompetitionPage],
  ["/potw/competitions/:potwCompetitionId/video", PotwCompetitionVideoPage],
  ["/event/:eventViewId", EventPage],
  ["/reel/:reelViewId", ReelPage],
];
