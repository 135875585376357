import competitionBgGoldPng from "assets/competition-bg-gold.png";
import potwWeekSvg from "assets/potw-week.svg";

const COMPETITION_STYLES = {
  week: {
    color: "#FFD300",
    backgroundImage: competitionBgGoldPng,
    logoImage: potwWeekSvg,
    title: "Plays of the Week",
  },
};

export default function getCompetitionStyle(competitionType) {
  const competitionStyle = COMPETITION_STYLES[competitionType];
  if (!competitionStyle) throw new Error(`Unknown competition type: ${competitionType}`);
  return competitionStyle;
}
