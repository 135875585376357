import { Alert } from "@mui/material";
import { FONT_FAMILY_ROBOTO } from "helpers/FONT";
import React from "react";

export default function Message({ children, ...others }) {
  return (
    <Alert
      severity="info"
      variant="filled"
      {...others}
      style={{
        fontFamily: FONT_FAMILY_ROBOTO,
        ...others.style,
      }}
    >
      {children}
    </Alert>
  );
}
