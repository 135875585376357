import useDOMEvent from "helpers/useDOMEvent";
import React, { createContext, useState } from "react";

const SUPPORT_FULLSCREEN = !!document.requestFullscreen;

export const fullscreenStateContext = createContext();

export default function FullscreenStateProvider({ children }) {
  const [fullscreen, fullscreenSetRaw] = useState(() => !!document.fullscreenElement);

  useDOMEvent(
    "fullscreenchange",
    () => {
      if (SUPPORT_FULLSCREEN) fullscreenSetRaw(!!document.fullscreenElement);
    },
    () => document,
  );

  const fullscreenSet = (value) => {
    if (SUPPORT_FULLSCREEN) {
      if (value) {
        document.documentElement.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    } else fullscreenSetRaw(value);
  };

  return (
    <fullscreenStateContext.Provider value={{ fullscreen, fullscreenSet }}>{children}</fullscreenStateContext.Provider>
  );
}
