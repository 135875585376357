import React, { cloneElement, useState } from "react";

export default function Draggable({
  dataType = "undefined",
  data = {},
  effectAllowed = "all",
  draggable = true,
  component = <div />,
  draggingStyle = { opacity: 0.5 },
  ...others
}) {
  const [dragging, draggingSet] = useState(false);
  const mimeType = `application/gloryleague.${dataType.toLowerCase()}+json`;

  return cloneElement(component, {
    ...others,
    draggable: draggable,
    onDragStart: (event) => {
      draggingSet(true);
      event.dataTransfer.setData(mimeType, JSON.stringify(data));
      event.effectAllowed = effectAllowed;
    },
    onDragEnd: () => {
      draggingSet(false);
    },
    style: {
      ...component.props.style,
      ...others.style,
      ...(dragging && draggingStyle),
    },
  });
}
