import { gql } from "@apollo/client";
import { Button } from "@mui/material";
import TextWithTooltip from "atoms/TextWithTooltip";
import { COLOR_ASSIST, COLOR_BLOCK, COLOR_POINT, COLOR_REBOUND, COLOR_STEAL } from "helpers/colors";
import { FONT_FAMILY_PATHWAY_GOTHIC_ONE } from "helpers/FONT";
import { MenuDown, MenuUp } from "mdi-material-ui";
import Section from "molecules/Section";
import StatsTable, {
  StatsTableButtonRow,
  StatsTableHeaderCell,
  StatsTableRow,
  StatsTableStatCell,
  StatsTableStatCellFragment,
} from "molecules/StatsTable";
import StatValue, { StatValueFragment } from "molecules/StatValue";
import React, { useState } from "react";

export const LeaderboardTableSegmentFragment = gql`
  fragment LeaderboardTableSegmentFragment on LeaderboardTableSegment {
    title
    columns {
      shortName
      name
    }
    headColumns
    rows {
      rank
      rankDiff
      lastRoundRank
      highlighted
      collapsible
      cells {
        shortName
        ...StatsTableStatCellFragment
      }
      headCells
      lastRoundCells {
        shortName
        ...StatValueFragment
      }
    }
  }
  ${StatsTableStatCellFragment}
  ${StatValueFragment}
`;

export default function LeaderboardTableSegment({ leaderboardTableSegment, collapsible = false }) {
  const [collapsed, collapsedSet] = useState(true);

  return (
    <Section title={leaderboardTableSegment.title}>
      <StatsTable
        headerColumns={["Rank", ...leaderboardTableSegment.headColumns]}
        columns={leaderboardTableSegment.columns.map((column) => (
          <TextWithTooltip key={column.shortName} tooltip={column.name}>
            {column.shortName}
          </TextWithTooltip>
        ))}
        color={
          {
            Points: COLOR_POINT,
            Rebounds: COLOR_REBOUND,
            Assists: COLOR_ASSIST,
            Steals: COLOR_STEAL,
            Blocks: COLOR_BLOCK,
          }[leaderboardTableSegment.title]
        }
      >
        {leaderboardTableSegment.rows
          .filter((row) => !row.collapsible || !collapsed || !collapsible)
          .map((row, rowIndex) => (
            <StatsTableRow key={rowIndex} highlighted={row.highlighted}>
              <StatsTableHeaderCell tooltip={<>Last Round Rank: {row.lastRoundRank}</>}>
                {row.rank}
                <span
                  style={{
                    color: "grey",
                    ...(row.rankDiff < 0 && { color: "green" }),
                    ...(row.rankDiff > 0 && { color: "red" }),
                    verticalAlign: "middle",
                    margin: "-50% 0",
                    display: "inline-block",
                  }}
                >
                  {row.rankDiff < 0 && <MenuUp />}
                  {row.rankDiff > 0 && <MenuDown />}
                  {row.rankDiff === 0 && "-"}
                </span>
              </StatsTableHeaderCell>
              {row.headCells.map((cell, cellIndex) => (
                <StatsTableHeaderCell key={cellIndex}>{cell}</StatsTableHeaderCell>
              ))}
              {leaderboardTableSegment.columns.map((column, columnIndex) => (
                <StatsTableStatCell
                  key={columnIndex}
                  stat={row.cells[columnIndex]}
                  tooltip={
                    <div>
                      Last Round: <br />
                      <StatValue state={row.cells[columnIndex]} />
                    </div>
                  }
                />
              ))}
            </StatsTableRow>
          ))}
        {collapsible && !!leaderboardTableSegment.rows.find((r) => r.collapsible) && (
          <StatsTableButtonRow>
            <Button
              fullWidth
              color="inherit"
              onClick={() => collapsedSet(!collapsed)}
              style={{
                textTransform: "uppercase",
                fontFamily: FONT_FAMILY_PATHWAY_GOTHIC_ONE,
              }}
            >
              {collapsed ? "Show More" : "Show Less"}
            </Button>
          </StatsTableButtonRow>
        )}
      </StatsTable>
    </Section>
  );
}
