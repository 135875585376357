import { gql } from "@apollo/client";
import DateTime from "atoms/DateTime";
import FetchMoreButton from "atoms/FetchMoreButton";
import ImageIcon from "atoms/ImageIcon";
import { PageContentStack } from "atoms/Stack";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import useRouteState from "helpers/useRouteState";
import Subnav, { SubnavDropdown, SubnavDropdownItem } from "molecules/Subnav";
import React from "react";

import GameEntry, { GameEntryFragment } from "./games/GameEntry";
import GamesBlankMessage from "./games/GamesBlankMessage";
import GamesSplash from "./games/GamesSplash";

export default function GamesPage() {
  useEnforceAuth({ enforceAuthenticated: true });

  const [seasonId, seasonIdSet] = useRouteState("seasonId");
  const [teamId, teamIdSet] = useRouteState("teamId");
  const [data] = useData(
    gql`
      query GamesPage($seasonId: ID, $teamId: ID) {
        season(id: $seasonId) {
          id
          nameWithLeague
        }
        team(id: $teamId) {
          id
          logoUrl
          name
        }
        currentUser {
          id
          gamesSeasons(limit: 20) {
            id
            nameWithLeague
            firstPlayedAt
            lastPlayedAt
          }
        }
      }
    `,
    {
      seasonId,
      teamId,
    },
  );
  const [dataTeams] = useData(
    gql`
      query GamesPage_teams($seasonId: ID!) {
        currentUser {
          id
          gamesTeams(seasonId: $seasonId) {
            id
            logoUrl
            name
            lastSeason {
              id
              nameWithLeague
            }
          }
        }
      }
    `,
    {
      seasonId,
    },
    {
      skip: !seasonId,
    },
  );
  const [dataGames, dataGamesMeta] = useData(
    gql`
      query GamesPage_game($seasonId: ID, $teamId: ID, $offset: Int) {
        currentUser {
          id
          gamesCount(seasonId: $seasonId, teamId: $teamId)
          games(seasonId: $seasonId, teamId: $teamId, offset: $offset, limit: 10) {
            id
            ...GameEntryFragment
          }
        }
      }
      ${GameEntryFragment}
    `,
    {
      seasonId,
      teamId,
    },
  );

  return (
    <>
      <GamesSplash title="Games" subtitle={data?.team?.name || data?.season?.nameWithLeague || "All"} teamId={teamId} />
      <Subnav>
        <SubnavDropdown text={data?.season?.nameWithLeague || "All Seasons"}>
          <SubnavDropdownItem
            text="All Seasons"
            onClick={() => {
              seasonIdSet(null);
              teamIdSet(null);
            }}
            selected={!seasonId}
          />
          {data?.currentUser?.gamesSeasons.map((season) => (
            <SubnavDropdownItem
              key={season.id}
              text={season.nameWithLeague}
              onClick={() => {
                seasonIdSet(season.id);
                teamIdSet(null);
              }}
              selected={season.id === seasonId}
              description={
                <>
                  <DateTime value={season.firstPlayedAt} variant="month" /> -{" "}
                  <DateTime value={season.lastPlayedAt} variant="month" />
                </>
              }
            />
          ))}
        </SubnavDropdown>
        {seasonId && (
          <SubnavDropdown
            icon={data?.team && <ImageIcon src={data.team.logoUrl} />}
            text={data?.team?.name || "All Teams"}
          >
            <SubnavDropdownItem text="All Teams" onClick={() => teamIdSet(null)} selected={!teamId} />
            {dataTeams?.currentUser?.gamesTeams.map((team) => (
              <SubnavDropdownItem
                key={team.id}
                icon={<ImageIcon src={team.logoUrl} />}
                text={team.name}
                onClick={() => teamIdSet(team.id)}
                selected={team.id === teamId}
                description={team.lastSeason?.nameWithLeague}
              />
            ))}
          </SubnavDropdown>
        )}
      </Subnav>
      <PageContentStack>
        {dataGames?.currentUser?.games.map((game) => (
          <GameEntry key={game.id} game={game} />
        ))}
        {dataGames?.currentUser?.games?.length === 0 && <GamesBlankMessage />}
        <FetchMoreButton
          dataMeta={dataGamesMeta}
          rows={dataGames?.currentUser?.games}
          rowsCount={dataGames?.currentUser?.gamesCount}
        />
      </PageContentStack>
    </>
  );
}
