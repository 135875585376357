import { ONSEN_AUTH_TOKEN_KEY } from "helpers/constants";
import useActionFragment from "helpers/useActionFragment";
import { progressBar } from "helpers/useProgressBar";
import { jwtDecode } from "jwt-decode";
import { defer } from "lodash-es";
import { useEffect } from "react";

export default function AuthTokenRefresher() {
  const requestAuthToken = useActionFragment("requestAuthToken", "authToken");
  const checkToken = () => {
    const authToken = window.localStorage.getItem(ONSEN_AUTH_TOKEN_KEY);

    let refresh = false;
    let expirationSeconds = 30 * 24 * 3600; // 30 days
    if (authToken && authToken.startsWith("EMBER_LEGACY_TOKEN:")) {
      refresh = true;
    } else if (authToken) {
      let decodedToken;
      try {
        decodedToken = jwtDecode(authToken);
      } catch (error) {
        defer(() => {
          new Error(`Error decoding token: ${error.message}`);
        });
        window.localStorage.setItem(ONSEN_AUTH_TOKEN_KEY, "");
      }
      if (!decodedToken) return;

      const currentTime = Date.now() / 1000;
      const tokenExpirationSeconds = decodedToken.exp - decodedToken.nbf;
      const tokenExpiresInSeconds = decodedToken.exp - currentTime;
      // half-life
      if (tokenExpiresInSeconds > tokenExpirationSeconds / 2) {
        refresh = true;
        expirationSeconds = Math.round(tokenExpirationSeconds);
      }
    }

    if (refresh)
      progressBar(async () => {
        // Refresh token
        const result = await requestAuthToken({
          input: {
            expirationSeconds,
          },
        });
        const authTokenNew = result.requestAuthToken.authToken;
        window.localStorage.setItem(ONSEN_AUTH_TOKEN_KEY, authTokenNew || "");
      });
  };

  useEffect(() => {
    const timer = setInterval(checkToken(), 1000 * 60); // 1 minutes

    return () => {
      clearInterval(timer);
    };
  }, []);

  return null;
}
