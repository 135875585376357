import { Chip } from "@mui/material";
import Stack from "atoms/Stack";
import { COLOR_GREY_BORDER } from "helpers/colors";
import { FONT_FAMILY_PATHWAY_GOTHIC_ONE } from "helpers/FONT";
import { SPACING, SPACING_DENSE } from "helpers/spacings";
import React from "react";

export default function Section({ icon, title, labels, children, component: Component = "div", ...others }) {
  return (
    <Component
      data-test-section
      {...others}
      style={{
        //
        ...others.style,
        display: "flex",
        flexFlow: "column nowrap",
        gap: SPACING,
      }}
    >
      <div
        style={{
          color: "inherit",
          paddingBottom: SPACING_DENSE,
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center",
          gap: SPACING_DENSE,
          borderBottom: `1px solid ${COLOR_GREY_BORDER}`,
        }}
      >
        {icon &&
          React.cloneElement(icon, {
            fontSize: "inherit",
            style: { fontSize: 16 },
          })}
        <div
          style={{
            fontFamily: FONT_FAMILY_PATHWAY_GOTHIC_ONE,
            fontSize: 24,
          }}
        >
          {title}
        </div>
        {labels}
      </div>
      <Stack>{children}</Stack>
    </Component>
  );
}

export function SectionLabel({ label, selected, onClick }) {
  return (
    <Chip size="small" variant="outlined" color={selected ? "secondary" : "default"} onClick={onClick} label={label} />
  );
}
