import ModalContextProvider from "providers/ModalContextProvider";
import React, { cloneElement, useState } from "react";

export default function HoverModal({ element, name }) {
  const [hover, hoverSet] = useState(false);

  return (
    <ModalContextProvider open={hover} name={`HoverModal-${name}`}>
      {cloneElement(element, {
        onMouseEnter: (event) => {
          hoverSet(true);
          element.props.onMouseEnter?.(event);
        },
        onMouseLeave: (event) => {
          hoverSet(false);
          element.props.onMouseLeave?.(event);
        },
      })}
    </ModalContextProvider>
  );
}
