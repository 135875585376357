import { gql } from "@apollo/client";
import { IconButton, Paper } from "@mui/material";
import { ButtonBase } from "@mui/material";
import defaultAvatarPng from "assets/default-avatar.png";
import { COLOR_GREY_BORDER, COLOR_SECONDARY, COLOR_WHITE, COLOR_WHITE_DARKER } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { SPACING_DENSE, SPACING_HALF } from "helpers/spacings";
import React from "react";

export const PersonCardFragment = gql`
  fragment PersonCardFragment on Person {
    id
    fullNameWithAnonymisation
    photoAvatarUrl
  }
`;

export default function PersonCard({
  color = COLOR_SECONDARY,
  playerNumber,
  person,
  href,
  onClick,
  actionButtons,
  stats,
}) {
  return (
    <Paper
      data-test-card
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        width: 180,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
        }}
      >
        <div
          style={{
            fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
            fontSize: 18,
            padding: SPACING_HALF,
            color: COLOR_WHITE,
            backgroundColor: color,
          }}
        >
          #{playerNumber}
        </div>
        <div
          style={{
            flex: "1 1 auto",
            fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
            textTransform: "uppercase",
            fontWeight: 200,
            fontSize: 18,
            padding: SPACING_HALF,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {person.fullNameWithAnonymisation}
        </div>
      </div>
      <ButtonBase href={href} onClick={onClick}>
        <img
          style={{
            display: "block",
            width: "100%",
            aspectRatio: "1/1",
            objectFit: "cover",
          }}
          src={person?.photoAvatarUrl || defaultAvatarPng}
          alt="Photo"
        />
      </ButtonBase>
      {actionButtons && (
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-around",
            padding: SPACING_DENSE,
            gap: SPACING_DENSE,
          }}
        >
          {actionButtons}
        </div>
      )}
      {stats && (
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-around",
            padding: SPACING_DENSE,
            gap: SPACING_DENSE,
            borderTop: `1px solid ${COLOR_GREY_BORDER}`,
            backgroundColor: COLOR_WHITE_DARKER,
          }}
        >
          {stats}
        </div>
      )}
    </Paper>
  );
}

export function PersonCardActionButton({ label, icon, onClick, href, disabled = false }) {
  return (
    <IconButton size="small" onClick={onClick} href={href} disabled={disabled} title={label}>
      {icon}
    </IconButton>
  );
}

export function PersonCardStat({ color, label, value }) {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "center",
        gap: SPACING_DENSE,
      }}
    >
      <div
        style={{
          fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
          fontSize: 12,
          color,
        }}
      >
        {label}
      </div>
      <div
        style={{
          fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
          fontSize: 18,
        }}
      >
        {value}
      </div>
    </div>
  );
}
