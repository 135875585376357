import { gql } from "@apollo/client";
import React from "react";

export const TeamAvatarFragment = gql`
  fragment TeamAvatarFragment on Team {
    id
    name
    logoUrl
  }
`;

export default function TeamAvatar({ team, teamLogoUrl = team?.logoUrl, teamName = team?.name, size = 32 }) {
  return (
    <div
      style={{
        height: size,
        width: size,
      }}
    >
      {teamLogoUrl && (
        <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={teamLogoUrl} alt={teamName} />
      )}
    </div>
  );
}
