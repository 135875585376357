import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import React from "react";

export default function KeyPressLabel({ children, inverse = false, ...others }) {
  return (
    <span
      {...others}
      style={{
        fontSize: "0.8em",
        margin: "0 0.5em",
        display: "inline-block",
        color: COLOR_WHITE,
        backgroundColor: COLOR_BLACK,
        ...(inverse && {
          backgroundColor: COLOR_WHITE,
          color: COLOR_BLACK,
        }),
        borderRadius: 3,
        padding: "2px 5px",
        ...others.style,
      }}
    >
      {children}
    </span>
  );
}
