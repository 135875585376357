import { gql } from "@apollo/client";
import gtag from "helpers/gtag";
import useActionFragment from "helpers/useActionFragment";
import ReelViewAvatar, { ReelViewAvatarFragment } from "molecules/ReelAvatar";
import React from "react";

import ReelVideoPlayer from "./ReelVideoPlayer";

const _playedReelViewIds = new Set();

export const ReelPlayerFragment = gql`
  fragment ReelPlayerFragment on ReelView {
    title
    eventsCount
    viewCount
    thumbnailUrl
    videoUrl
    sharingDisabled
    ...ReelViewAvatarFragment
  }
  ${ReelViewAvatarFragment}
`;

export default function ReelPlayer({ reelViewId, reelView }) {
  const reelViewIncreaseViewCount = useActionFragment("reelViewIncreaseViewCount", "reelView { id viewCount }");
  const reelViewIncreaseShareCount = useActionFragment("reelViewIncreaseShareCount");

  return (
    <>
      <ReelVideoPlayer
        avatar={<ReelViewAvatar reelView={reelView} />}
        title={reelView?.title}
        subtitle={<>{reelView?.eventsCount} highlights</>}
        poster={reelView?.thumbnailUrl}
        videoUrl={reelView?.videoUrl}
        shareUrl={`/reel/${reelViewId}`}
        sharingDisabled={reelView?.sharingDisabled === true}
        viewCount={reelView?.viewCount}
        onShared={({ share_destination }) => {
          gtag("event", "reel_share", {
            share_destination,
          });
          reelViewIncreaseShareCount({ input: { reelViewId } });
        }}
        onVideoPlay={() => {
          const isUnique = !_playedReelViewIds.has(reelViewId);
          if (isUnique) {
            _playedReelViewIds.add(reelViewId);
          }
          gtag("event", "reel_play", {
            is_unique: isUnique,
          });
          reelViewIncreaseViewCount({ input: { reelViewId, isUnique } });
        }}
      />
    </>
  );
}
