import { ApolloClient, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { HttpLink } from "@apollo/client/link/http";
import { RetryLink } from "@apollo/client/link/retry";

import apolloCache from "./apolloCache";
import { ONSEN_AUTH_TOKEN_KEY } from "./constants";
import { GLORYLEAGUE_API_URL, ONSEN_ENV, ONSEN_PUBLIC_LEAGUES_HOST_SUFFIX } from "./environment";
import { barsAdd, barsRemove } from "./useProgressBar";

let _stub_location_host = null;
if (ONSEN_ENV === "test") {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.has("_stub_location_host")) {
    _stub_location_host = urlSearchParams.get("_stub_location_host");
  }
}

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    new RetryLink({
      delay: (count) => {
        return Math.max(10000, count * 1000 * Math.random());
      },
      attempts: (count, operation, error) => {
        if (!error) return false;
        return true;
      },
    }),
    new ApolloLink((operation, forward) => {
      const authToken = localStorage[ONSEN_AUTH_TOKEN_KEY];
      if (authToken) operation.extensions.authToken = authToken;
      let locationHost = _stub_location_host || location.host;
      if (locationHost.endsWith(ONSEN_PUBLIC_LEAGUES_HOST_SUFFIX)) {
        const publicLeaguePageSlug = locationHost.substring(
          0,
          locationHost.length - ONSEN_PUBLIC_LEAGUES_HOST_SUFFIX.length,
        );
        operation.extensions.publicLeaguePageSlug = publicLeaguePageSlug;
      }

      return forward(operation);
    }),
    setContext(async () => {
      return {
        uri: `${GLORYLEAGUE_API_URL}/graphql`,
      };
    }),
    new HttpLink({
      includeExtensions: true,
      fetch: async (...args) => {
        const bar = {};
        barsAdd(bar);
        try {
          return await fetch(...args);
        } finally {
          barsRemove(bar);
        }
      },
    }),
  ]),
  cache: apolloCache,
  partialRefetch: true,
});
window._apolloClient = apolloClient;

export default apolloClient;
