import "./index.css";
import "./styles/progressbar-spinner.css";
import "./styles/recaptcha.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/pathway-gothic-one/index.css";
import "@fontsource-variable/league-gothic/standard.css";
import "helpers/rollbar";

import App from "App";
import { ONSEN_ENV, RECAPTCHA_SITE_KEY } from "helpers/environment";
import React from "react";
import { createRoot } from "react-dom/client";

const element = document.getElementById("root");
const root = createRoot(element);
root.render(<App />);

if (ONSEN_ENV !== "test") {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
  script.defer = true;
  document.head.appendChild(script);
}
