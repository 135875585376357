import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import Stack from "atoms/Stack";
import VideoPlayer from "atoms/VideoPlayer";
import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import { fetchFile } from "helpers/fetchFile";
import gtag from "helpers/gtag";
import useData from "helpers/useData";
import useGotoUrl from "helpers/useGotoUrl";
import { progressBar } from "helpers/useProgressBar";
import useShowMessage from "helpers/useShowMessage";
import {
  ArrowLeft,
  ArrowRight,
  Download,
  MenuDown,
  Pause,
  Pencil,
  Play,
  PlaylistPlay,
  Restore,
  Share,
} from "mdi-material-ui";
import ControlBar from "molecules/ControlBar";
import ControlBarContent from "molecules/ControlBarContent";
import ControlButton from "molecules/ControlButton";
import ControlDropdown from "molecules/ControlDropdown";
import ClipDraggable, { ClipDraggableFragment } from "pages/playlists/ClipDraggable";
import ClipDroppable from "pages/playlists/ClipDroppable";
import ClipInfo, { ClipInfoFragment } from "pages/playlists/ClipInfo";
import ClipThumbnail, { ClipThumbnailFragment } from "pages/playlists/ClipThumbnail";
import NotesDropdown from "pages/playlists/NotesDropdown";
import PlaylistIcon from "pages/playlists/PlaylistIcon";
import { PlaylistInfoFragment } from "pages/playlists/PlaylistInfo";
import PlaylistsDropdown from "pages/playlists/PlaylistsDropdown";
import React, { cloneElement, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useComponentSize } from "react-use-size";
import { useAsyncMemo } from "use-async-memo";

import FullscreenButton from "./video_player/FullscreenButton";
import PlayerButton from "./video_player/PlayerButton";
import Timeline from "./video_player/Timeline";
import VolumeButton from "./video_player/VolumeButton";

export default function ClipPage({ playlistId, clipId }) {
  const gotoUrl = useGotoUrl();
  const videoRef = useRef(null);
  const showMessage = useShowMessage();
  const size = useComponentSize();
  const vertical = size.height > size.width;

  const [playing, playingSet] = useState(false);
  const [currentTime, currentTimeSet] = useState(0);
  const [duration, durationSet] = useState(0);
  const [showFullList, showFullListSet] = useState(window.innerWidth > 600);
  const [audioEnabled, audioEnabledSet] = useState(false);

  const [data, dataMeta] = useData(
    gql`
      query ClipPage($playlistId: ID!) {
        playlist(id: $playlistId) {
          id
          name
          description
          playlistType
          ...PlaylistInfoFragment
          clips {
            id
            homeTeamName
            awayTeamName
            videoUrl
            ...ClipDraggableFragment
            ...ClipThumbnailFragment
            ...ClipInfoFragment
          }
        }
      }
      ${ClipDraggableFragment}
      ${ClipThumbnailFragment}
      ${ClipInfoFragment}
      ${PlaylistInfoFragment}
    `,
    { playlistId, clipId },
  );

  const playlist = data?.playlist;
  const currentClip = playlist?.clips.find((c) => c.id === clipId) || playlist?.clips[0];
  const clipMissing = data && !dataMeta.loading && !currentClip;

  useLayoutEffect(() => {
    if (clipMissing) {
      gotoUrl(`/playlists/${playlistId}`, { replace: true });
    }
  }, [clipMissing]);

  const [looping, loopingSet] = useState(false);

  let nextClip, prevClip;
  if (currentClip && playlist) {
    const currentClipIndex = playlist.clips.indexOf(currentClip);
    nextClip = playlist.clips[currentClipIndex + 1];
    prevClip = playlist.clips[currentClipIndex - 1];
    if (looping) {
      nextClip ||= playlist.clips[0];
      prevClip ||= playlist.clips[playlist.clips.length - 1];
    }
  }

  useEffect(() => {
    if (currentClip && !currentClip.videoUrl) {
      showMessage("Video is not available yet");
    }
  }, [!!currentClip, !!currentClip?.videoUrl]);
  const title =
    playlist && currentClip && `${playlist?.name} - ${currentClip?.homeTeamName} vs ${currentClip?.awayTeamName}`;

  const fetchFileResult = useAsyncMemo(
    //
    () => title && currentClip?.videoUrl && fetchFile(currentClip.videoUrl, title),
    [title, currentClip?.videoUrl],
  );

  const { file, filename, fileObjectUrl } = fetchFileResult || {};

  return (
    <div
      ref={size.ref}
      style={{
        flex: "1 0 auto",
        height: 400, // Minimum height
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
      }}
    >
      <Helmet title={title} />
      <ControlBar style={{ position: "relative", zIndex: 1, flex: "0 0 auto" }}>
        <ControlDropdown
          icon={<PlaylistIcon playlistType={playlist?.playlistType} />}
          label={playlist?.name}
          iconEnd={<MenuDown />}
        >
          <PlaylistsDropdown currentPlaylistId={playlistId} />
        </ControlDropdown>
        <ControlBarContent>
          <ClipInfo clip={currentClip} style={{ margin: "0 1em" }} />
        </ControlBarContent>
        <ControlDropdown
          icon={<Pencil />}
          label="Notes"
          onOpen={() => {
            videoRef.current?.pause();
            gtag("event", "playlist_clip_notes", {
              action: "click",
            });
          }}
        >
          <NotesDropdown clipId={currentClip?.id} />
        </ControlDropdown>
        <ControlButton
          icon={<Restore />}
          label="Loop"
          onClick={() => {
            const isOnLoop = !looping;
            loopingSet(isOnLoop);
            gtag("event", "playlist_looped", {
              action: isOnLoop ? "on" : "off",
            });
          }}
          inverse={looping}
        />
        <ControlButton
          icon={<ArrowLeft />}
          label="Previous"
          disabled={!prevClip}
          href={`/playlists/${playlistId}/${prevClip?.id}`}
        />
        <ControlButton
          iconEnd={<ArrowRight />}
          label="Next"
          disabled={!nextClip}
          href={`/playlists/${playlistId}/${nextClip?.id}`}
        />
        <ControlButton
          icon={<PlaylistPlay />}
          label="See full list"
          inverse={showFullList}
          onClick={() => {
            const showList = !showFullList;
            showFullListSet(showList);
            gtag("event", "playlist_full_list", {
              action: showList ? "show" : "hide",
            });
          }}
        />
      </ControlBar>
      <PlaylistPlayer
        showFullList={showFullList}
        vertical={vertical}
        style={{
          flex: "1 1 auto",
          height: 0,
        }}
        video={
          <VideoPlayer
            playsInline
            autoPlay
            videoRef={videoRef}
            src={currentClip?.videoUrl}
            onAudioEnabledChange={audioEnabledSet}
            onEnded={() => {
              if (nextClip)
                gotoUrl(`/playlists/${playlistId}/${nextClip.id}`, {
                  replace: true,
                });
              gtag("event", "playlist_clip_interaction", {
                action: "ended",
              });
            }}
            onDurationChange={() => {
              durationSet(videoRef.current.duration);
            }}
            onTimeUpdateUnthrottled={() => {
              const currentTime = videoRef.current?.currentTime;
              currentTimeSet(currentTime);
            }}
            onLoadStart={(e) => {
              e.target.volume = 0.3; // set low default to avoid unpleasant user experience
            }}
            onSeeked={() => {
              gtag("event", "playlist_clip_interaction", {
                action: "seek",
              });
            }}
            onPlay={() => {
              playingSet(true);
            }}
            onPause={() => {
              playingSet(false);
              gtag("event", "playlist_clip_interaction", {
                action: "pause",
              });
            }}
          />
        }
        controls={
          <>
            <PlayerButton
              title={playing ? "Pause" : "Play"}
              onClick={() => {
                if (videoRef.current.paused) {
                  videoRef.current.play();
                } else {
                  videoRef.current.pause();
                }
              }}
              icon={playing ? <Pause /> : <Play />}
            />
            <VolumeButton enabled={audioEnabled} />
            <Timeline currentTime={currentTime} duration={duration} videoRef={videoRef} />
            <PlayerButton
              title="Download"
              icon={<Download />}
              disabled={!fileObjectUrl}
              onClick={() => {
                const a = document.createElement("a");
                a.download = filename;
                a.href = fileObjectUrl;
                a.click();
                gtag("event", "playlist_clip_shared", {
                  destination: "download",
                });
              }}
            />
            <PlayerButton
              title="Share"
              icon={<Share />}
              disabled={!window.navigator?.share || !file}
              onClick={() =>
                progressBar(async () => {
                  await window.navigator.share({
                    files: [file],
                  });
                  gtag("event", "playlist_clip_shared", {
                    destination: "web_share",
                  });
                })
              }
            />

            <FullscreenButton />
          </>
        }
        videoThumbnails={
          <>
            {playlist?.clips.map((clip) => (
              <ClipDroppable
                key={clip.id}
                clipId={clip.id}
                vertical={!vertical}
                component={
                  <ClipDraggable
                    clip={clip}
                    component={
                      <ClipThumbnail
                        style={{ flex: "0 0 auto" }}
                        clip={clip}
                        active={clip === currentClip}
                        activeProgress={clip === currentClip ? currentTime / duration : 0}
                        href={`/playlists/${playlistId}/${clip.id}`}
                      />
                    }
                  />
                }
              />
            ))}
            <div>
              <a href={`/playlists/${playlistId}`}>View full playlist</a>
            </div>
          </>
        }
      />
    </div>
  );
}

function PlaylistPlayer({
  video,
  controls,
  headerSection,
  videoThumbnails,
  showFullList = false,
  vertical = false,
  ...others
}) {
  return (
    <div
      {...others}
      style={{
        display: "flex",
        flexFlow: !vertical ? "row-reverse nowrap" : "column nowrap",
        alignItems: "stretch",
        ...others.style,
      }}
    >
      {showFullList && (
        <Paper
          square
          elevation={8}
          style={{
            backgroundColor: COLOR_WHITE,
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "stretch",
            ...(!vertical
              ? {
                  width: "min-content",
                }
              : {}),
          }}
        >
          <div
            style={{
              flex: "1 1 auto",
              padding: 10,
              display: "flex",
              alignItems: "center",
              ...(!vertical
                ? {
                    flexFlow: "column nowrap",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }
                : {
                    flexFlow: "row nowrap",
                    overflowX: "auto",
                    overflowY: "hidden",
                  }),
              gap: 10,
            }}
          >
            {headerSection}
            {videoThumbnails}
          </div>
        </Paper>
      )}
      <div
        style={{
          flex: "1 1 auto",
          position: "relative",
          color: COLOR_WHITE,
          backgroundColor: COLOR_BLACK,
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "stretch",
        }}
      >
        <div
          style={{
            flex: "1 0 100px",
            position: "relative",
          }}
        >
          {cloneElement(video, {
            style: {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "contain",
              ...video.props.style,
            },
          })}
        </div>
        <Stack dense padding horizontal alignItemsCenter>
          {controls}
        </Stack>
      </div>
    </div>
  );
}
