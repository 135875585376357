import { SPACING, SPACING_DENSE, SPACING_VERY_DENSE } from "helpers/spacings";
import React, { useContext } from "react";

import { stackContext } from "./Stack";

export default function Spacer() {
  const { dense, veryDense } = useContext(stackContext);
  let spacing = SPACING;
  if (dense) {
    spacing = SPACING_DENSE;
  } else if (veryDense) {
    spacing = SPACING_VERY_DENSE;
  }

  return <div style={{ flex: "1 1 auto", marginLeft: `-${spacing}`, marginTop: `-${spacing}` }} />;
}
