import { Button } from "@mui/material";
import { COLOR_GREY } from "helpers/colors";
import React from "react";
import { useComponentSize } from "react-use-size";

export default function Toolbar({ children, ...others }) {
  const componentSize = useComponentSize();

  return (
    <div
      {...others}
      style={{
        ...others.style,
      }}
    >
      <div
        ref={componentSize.ref}
        style={{
          display: "flex",
          flexFlow: "row wrap",
          gap: 15,
        }}
      >
        {children}
      </div>
    </div>
  );
}

export function ToolbarButton({ icon, label, primary = false, flat = false, disabled, ...others }) {
  return (
    <Button
      title={label}
      variant="outlined"
      color="inherit"
      {...(primary && {
        variant: "contained",
        color: "secondary",
      })}
      {...(flat && {
        variant: "text",
      })}
      {...others}
      disabled={disabled}
      style={{
        ...(disabled && {
          color: COLOR_GREY,
        }),
        ...others.style,
      }}
    >
      {icon}
      {label}
    </Button>
  );
}
