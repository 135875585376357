import { gql } from "@apollo/client";

import { EventTypeFilterFragment } from "./EVENT_TYPE_FILTERS";

export const useFilteredEventsFragment = gql`
  fragment useFilteredEventsFragment on Game {
    id
    home {
      id
    }
    events {
      id
      person {
        id
      }
      team {
        id
      }
      ...EventTypeFilterFragment
    }
  }
  ${EventTypeFilterFragment}
`;

export default function useFilteredEvents({ game, activeEventTypeFilter, homeFilterPersonId, awayFilterPersonId }) {
  const filteredEventIds = game?.events
    .filter((event) => {
      if (!activeEventTypeFilter.filter(event)) return false;
      // non-team events should be visible for everyone
      if (!event.team) return true;
      if (event.team.id === game.home.id) {
        return homeFilterPersonId === "ALL" || event.person?.id === homeFilterPersonId;
      } else {
        return awayFilterPersonId === "ALL" || event.person?.id === awayFilterPersonId;
      }
    })
    .map((event) => event.id);

  return filteredEventIds;
}
