import React, { cloneElement } from "react";

export default function StackedAvatars({ primaryAvatar, secondaryAvatar, size = 15, ...others }) {
  const secondaryAvatarSize = Math.min(size * 0.5, 18);
  return (
    <div
      {...others}
      style={{
        position: "relative",
        ...others.style,
      }}
    >
      {cloneElement(primaryAvatar, {
        size,
      })}
      {cloneElement(secondaryAvatar, {
        size: secondaryAvatarSize,
        style: {
          ...secondaryAvatar.props.style,
          position: "absolute",
          top: secondaryAvatarSize * -0.2,
          right: secondaryAvatarSize * -0.2,
        },
      })}
    </div>
  );
}
