import { IconButton } from "@mui/material";
import WithTooltip from "atoms/WithTooltip";
import { COLOR_WHITE } from "helpers/colors";
import React from "react";

export default function PlayerButton({
  icon,
  disabled = false,
  href,
  target,
  download,
  onClick,
  onHoveringChange,
  decorators,
  title,
  shortcutLabel,
  ...others
}) {
  return (
    <>
      <div
        {...others}
        onMouseEnter={() => {
          if (disabled) return;
          onHoveringChange?.(true);
        }}
        onMouseLeave={() => {
          onHoveringChange?.(false);
        }}
        style={{ position: "relative", ...others.style }}
      >
        <WithTooltip
          inverse
          shortcutLabel={shortcutLabel}
          tooltip={title}
          placement="top"
          element={
            <IconButton
              style={{
                color: COLOR_WHITE,
                ...(disabled && { opacity: 0.5 }),
              }}
              size="small"
              disabled={disabled}
              onClick={onClick}
              href={href}
              target={target}
              download={download}
            >
              {icon}
            </IconButton>
          }
        />
        {decorators}
      </div>
    </>
  );
}
