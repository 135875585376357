import { ButtonBase, CircularProgress } from "@mui/material";
import KeyPressLabel from "atoms/KeyPressLabel";
import Spacer from "atoms/Spacer";
import Stack from "atoms/Stack";
import VideoPlayer from "atoms/VideoPlayer";
import { COLOR_BLACK, COLOR_SECONDARY, COLOR_WHITE } from "helpers/colors";
import { fetchFile } from "helpers/fetchFile";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { SPACING, SPACING_PADDING } from "helpers/spacings";
import useFullscreenState from "helpers/useFullscreenState";
import { progressBar } from "helpers/useProgressBar";
import useShowMessage from "helpers/useShowMessage";
import { ContentCopy, Download, Link, Play, Share } from "mdi-material-ui";
import ViewCountLabel from "molecules/ViewCountLabel";
import React, { cloneElement, useRef, useState } from "react";
import { useAsyncMemo } from "use-async-memo";

import FullscreenButton from "./FullscreenButton";
import PlayerButton from "./PlayerButton";
import VolumeButton from "./VolumeButton";

export default function ReelVideoPlayer({
  title,
  avatar,
  subtitle,
  poster,
  videoUrl,
  shareUrl,
  viewCount,
  videoPending = false,
  vidoeUnavailable = false,
  onVideoPlay,
  onShared,
  sharingDisabled = false,
}) {
  const [fullscreen] = useFullscreenState();
  const [audioEnabled, audioEnabledSet] = useState(true);
  const [playing, playingSet] = useState(false);
  const [playingProgress, playingProgressSet] = useState(0);
  const videoRef = useRef();

  const fetchFileResult = useAsyncMemo(
    //
    () => title && videoUrl && fetchFile(videoUrl, title),
    [title, videoUrl],
  );

  const { file, filename, fileObjectUrl } = fetchFileResult || {};

  const showMessage = useShowMessage();

  return (
    <div
      style={{
        flex: "1 1 auto",
        backgroundColor: COLOR_BLACK,
        color: COLOR_WHITE,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: SPACING_PADDING,
        ...(fullscreen && {
          justifyContent: "stretch",
          alignItems: "stretch",
        }),
      }}
    >
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "stretch",
          alignItems: "stretch",
          maxWidth: 1000,
          gap: SPACING,
          ...(fullscreen && {
            maxWidth: "initial",
          }),
        }}
      >
        <Stack horizontal dense alignItemsCenter>
          {avatar && cloneElement(avatar, { size: 42, inverse: true })}
          <Stack dense>
            <div
              style={{
                fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                fontSize: "1.5em",
              }}
            >
              {title}
            </div>
            <div>{subtitle}</div>
          </Stack>
          <Spacer />
          {!sharingDisabled && (
            <>
              <PlayerButton
                title="Download"
                onClick={() => {
                  const a = document.createElement("a");
                  a.download = filename;
                  a.href = fileObjectUrl;
                  a.click();
                  onShared?.({ share_destination: "download" });
                }}
                disabled={!file}
                icon={<Download />}
              />
              <PlayerButton title="Open in new tab" target="_blank" href={shareUrl} icon={<Link />} />
              <PlayerButton
                title="Copy link"
                onClick={() =>
                  progressBar(async () => {
                    await window.navigator.clipboard.writeText(shareUrl);
                    await showMessage("Link copied to clipboard.");
                    onShared?.({ share_destination: "copy_link" });
                  })
                }
                icon={<ContentCopy />}
              />
              {!!window?.navigator?.share && (
                <PlayerButton
                  title="Share"
                  disabled={!file}
                  onClick={() =>
                    progressBar(async () => {
                      window.navigator.share({
                        files: [file],
                      });
                      onShared?.({ share_destination: "web_share" });
                    })
                  }
                  icon={<Share />}
                />
              )}
            </>
          )}
        </Stack>
        <ButtonBase
          component="div"
          style={{
            aspectRatio: "16 / 9",
            position: "relative",
            ...(fullscreen && {
              aspectRatio: "initial",
              flex: "1 1 auto",
            }),
          }}
          disabled={!videoUrl}
        >
          {!videoUrl && (
            <Stack dense alignItemsCenter>
              {!vidoeUnavailable && <CircularProgress color="inherit" />}
              {vidoeUnavailable && <div>Video unavailable</div>}
              {videoPending && <div>Processing video...</div>}
            </Stack>
          )}
          <VideoPlayer
            loop
            autoPlay
            playsInline
            preload="auto"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            poster={poster}
            src={videoUrl}
            onPlaying={() => playingSet(true)}
            onPause={() => playingSet(false)}
            onTimeUpdateUnthrottled={() => playingProgressSet(videoRef.current.currentTime / videoRef.current.duration)}
            videoRef={videoRef}
            onAudioEnabledChange={audioEnabledSet}
            onCanPlayThrough={() => {
              onVideoPlay?.();
            }}
          />
          {videoUrl && (
            <>
              <Play
                fontSize="inherit"
                style={{
                  pointerEvents: "none",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: COLOR_WHITE,
                  fontSize: 64,
                  transition: "opacity ease .3s",
                  opacity: !playing ? 1 : 0,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: 4,
                  width: `${playingProgress * 100}%`,
                  backgroundColor: COLOR_SECONDARY,
                }}
              />
            </>
          )}
        </ButtonBase>
        <Stack horizontal dense alignItemsCenter>
          <VolumeButton enabled={audioEnabled} videoRef={videoRef} />
          {!!videoUrl && (
            <div style={{ fontSize: "0.9em" }}>
              Hint: Hold down the <KeyPressLabel inverse>S</KeyPressLabel> key for slow motion and{" "}
              <KeyPressLabel inverse>F</KeyPressLabel> to fast-forward.
            </div>
          )}
          <div style={{ flex: "1 1 auto" }} />
          <ViewCountLabel viewCount={viewCount} inverse />
          <FullscreenButton />
        </Stack>
      </div>
    </div>
  );
}
