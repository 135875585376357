import { gql } from "@apollo/client";
import { ButtonBase } from "@mui/material";
import defaultAvatarPng from "assets/default-avatar.png";
import useData from "helpers/useData";
import Splash from "molecules/Splash";
import Subnav, { SubnavDropdown, SubnavDropdownItem, SubnavToggleLabel } from "molecules/Subnav";
import React from "react";

import useChangeProfilePhoto from "./useChangeProfilePhoto";

export default function PersonPagesHeader({ personId, tab }) {
  const [data] = useData(
    gql`
      query PersonPagesHeader($personId: ID!) {
        person(id: $personId) {
          id
          fullNameWithAnonymisation
          canOnsenPersonPageSettings
          photoAvatarUrl
          club {
            id
            name
          }
        }
        currentUser {
          id
          people {
            id
            fullNameWithAnonymisation
            club {
              id
              name
            }
          }
          supportingPeople {
            id
            fullNameWithAnonymisation
            club {
              id
              name
            }
          }
        }
      }
    `,
    { personId },
  );
  const changeProfilePhoto = useChangeProfilePhoto({ personId });

  return (
    <>
      <Splash
        logo={
          <ButtonBase
            disabled={!data?.person?.canOnsenPersonPageSettings}
            onClick={(event) => {
              event.preventDefault();
              changeProfilePhoto();
            }}
          >
            <img
              src={data?.person?.photoAvatarUrl || defaultAvatarPng}
              alt="Photo"
              data-test-profile-photo
              style={{
                width: 100,
                border: `3px solid #f68100`,
                borderRadius: 5,
                aspectRatio: "1/1",
                objectFit: "cover",
              }}
            />
          </ButtonBase>
        }
        color="#5C1E17"
        title={data?.person?.fullNameWithAnonymisation}
        descriptionHeader="Club"
        descriptionValue={data?.person?.club?.name}
      />
      <Subnav>
        <SubnavDropdown text={data?.person?.fullNameWithAnonymisation}>
          {[...(data?.currentUser?.people || []), ...(data?.currentUser?.supportingPeople || [])].map((person) => (
            <SubnavDropdownItem
              key={person.id}
              text={person.fullNameWithAnonymisation}
              description={person.club.name}
              selected={person.id === personId}
              href={`/person/${person.id}`}
            >
              {person.fullNameWithAnonymisation}
            </SubnavDropdownItem>
          ))}
        </SubnavDropdown>
        <SubnavToggleLabel text="Profile" checked={tab === "profile"} href={`/person/${personId}`} />
        <SubnavToggleLabel text="Teams" checked={tab === "teams"} href={`/person/${personId}/teams`} />
        {data?.person?.canOnsenPersonPageSettings && (
          <SubnavToggleLabel text="Settings" checked={tab === "settings"} href={`/person/${personId}/settings`} />
        )}
      </Subnav>
    </>
  );
}
