import { noop } from "lodash-es";
import React, { cloneElement, useEffect, useMemo, useState } from "react";

export default function Droppable({
  dataType = "undefined",
  dropEffect = "move",
  onDropData,
  onDroppingZoneChange = noop,
  component = <div />,
  droppingStyle = { outline: `dashed 2px` },
  ...others
}) {
  const [dropping, droppingSet] = useState(false);
  const [droppingLeft, droppingLeftSet] = useState(null);
  const [droppingTop, droppingTopSet] = useState(null);

  useEffect(() => {
    if (droppingLeft !== null && droppingTop !== null) {
      onDroppingZoneChange({
        dropping,
        left: droppingLeft,
        right: !droppingLeft,
        top: droppingTop,
        bottom: !droppingTop,
      });
    }
  }, [dropping, droppingLeft, droppingTop]);

  // https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
  const counter = useMemo(
    () => ({
      value: 0,
    }),
    [],
  );
  const mimeType = `application/gloryleague.${dataType.toLowerCase()}+json`;

  return cloneElement(component, {
    ...others,
    onDragEnter: (event) => {
      if (!event.dataTransfer.types.includes(mimeType)) return;
      counter.value += 1;
      if (counter.value !== 1) return;
      droppingSet(true);
      droppingLeftSet(null);
      droppingTopSet(null);
    },
    onDragLeave: (event) => {
      if (!event.dataTransfer.types.includes(mimeType)) return;
      counter.value -= 1;
      if (counter.value !== 0) return;
      droppingSet(false);
    },
    onDragOver: (event) => {
      if (!event.dataTransfer.types.includes(mimeType)) return;
      event.preventDefault();
      event.dataTransfer.dropEffect = dropEffect;
      const rect = event.currentTarget.getBoundingClientRect();
      const droppingTop = event.clientY < rect.top + rect.height / 2;
      const droppingLeft = event.clientX < rect.left + rect.width / 2;
      droppingTopSet(droppingTop);
      droppingLeftSet(droppingLeft);
    },
    onDrop: (event) => {
      if (!event.dataTransfer.types.includes(mimeType)) return;
      counter.value = 0;
      droppingSet(false);
      let data = event.dataTransfer.getData(mimeType);
      data = JSON.parse(data);
      onDropData(data);
    },
    style: {
      ...component.props.style,
      ...others.style,
      ...(dropping && droppingStyle),
    },
  });
}
