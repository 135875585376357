import { gql } from "@apollo/client";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import TextWithTooltip from "atoms/TextWithTooltip";
import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC, FONT_FAMILY_ROBOTO } from "helpers/FONT";
import { SPACING, SPACING_DENSE } from "helpers/spacings";
import { defer } from "lodash-es";
import React from "react";

export default function StatsTable({ headerColumns, color = COLOR_WHITE, columns, children }) {
  return (
    <Paper style={{ overflowX: "auto" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headerColumns?.map((column, columnIndex) => (
              <TableCell
                key={columnIndex}
                style={{
                  textTransform: "uppercase",
                  backgroundColor: COLOR_BLACK,
                  color: COLOR_WHITE,
                  fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                }}
              >
                {column}
              </TableCell>
            ))}
            {columns?.map((column, columnIndex) => (
              <TableCell
                key={columnIndex}
                style={{
                  textTransform: "uppercase",
                  textAlign: "center",
                  backgroundColor: COLOR_BLACK,
                  color: COLOR_WHITE,
                  ...(columnIndex === 0 && {
                    color,
                  }),
                  fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                }}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </Paper>
  );
}

export function StatsTableRow({ children, highlighted = false }) {
  return <TableRow selected={highlighted}>{children}</TableRow>;
}

export function StatsTableButtonRow({ children }) {
  return (
    <TableRow>
      <TableCell colSpan={99} padding="none">
        {children}
      </TableCell>
    </TableRow>
  );
}

export function StatsTableHeaderCell({ children, color, large = false, tooltip }) {
  return (
    <TableCell padding="none">
      <TextWithTooltip tooltip={tooltip}>
        <div
          style={{
            fontSize: 12,
            fontFamily: FONT_FAMILY_ROBOTO,
            padding: SPACING_DENSE,
            color,
            ...(large && {
              padding: SPACING,
              fontSize: 18,
              fontWeight: "bold",
            }),
          }}
        >
          {children}
        </div>
      </TextWithTooltip>
    </TableCell>
  );
}

export const StatsTableStatCellFragment = gql`
  fragment StatsTableStatCellFragment on Stat {
    statType
    value
    made
    total
  }
`;

export function StatsTableStatCell({ stat, ...others }) {
  if (stat?.statType === "simple") return <StatsTableCell value={stat.value} {...others} />;
  if (stat?.statType === "percentage")
    return <StatsTablePercentageCell made={stat.made} total={stat.total} {...others} />;
  if (stat)
    defer(() => {
      throw new Error(`Unknown stat type: ${stat.statType}`);
    });
  return <StatsTableCell value="?" />;
}

export function StatsTableCell({ value, large = false, description, tooltip }) {
  value = String(value);
  return (
    <TableCell padding="none">
      <TextWithTooltip tooltip={tooltip}>
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
            ...(large && {
              padding: SPACING,
            }),
          }}
        >
          <div
            style={{
              fontSize: 18,
              lineHeight: 1,
              textAlign: "center",
              fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
              fontWeight: 500,
            }}
          >
            {value}
          </div>
          <div style={{ fontSize: 10 }}>{description}</div>
        </div>
      </TextWithTooltip>
    </TableCell>
  );
}

export function StatsTablePercentageCell({ made, total, tooltip }) {
  const percentage = total > 0 ? Math.round(made / total) * 100 : 0;
  return (
    <TableCell
      padding="none"
      style={{
        textAlign: "center",
      }}
    >
      <TextWithTooltip tooltip={tooltip}>
        <div
          style={{
            fontSize: 16,
            fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
            fontWeight: 500,
          }}
        >
          {made} - {total}
        </div>
        <div
          style={{
            fontSize: 12,
          }}
        >
          {percentage}%
        </div>
      </TextWithTooltip>
    </TableCell>
  );
}
