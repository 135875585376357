import { gql } from "@apollo/client";
import StackedAvatars from "atoms/StackedAvatars";
import { TEAM_COLORS } from "helpers/colors";
import React from "react";

import PlayerAvatar from "../atoms/PlayerAvatar";
import EventMarker, { EventMarkerFragment } from "./EventMarker";

export const EventAvatarFragment = gql`
  fragment EventAvatarFragment on EventView {
    isPlayerEvent
    playerNumber
    avatarUrl
    teamColor
    ...EventMarkerFragment
  }
  ${EventMarkerFragment}
`;

export default function EventAvatar({ eventView, size = 16, active = false, inverse = false, ...others }) {
  if (!eventView?.isPlayerEvent)
    return <EventMarker size={size} eventView={eventView} active={active} inverse={inverse} {...others} />;

  return (
    <StackedAvatars
      {...others}
      size={size}
      primaryAvatar={
        <PlayerAvatar
          color={TEAM_COLORS[eventView?.teamColor]}
          avatarUrl={eventView?.avatarUrl}
          playerNumber={eventView?.playerNumber}
          inverse={inverse}
          active={active}
        />
      }
      secondaryAvatar={<EventMarker eventView={eventView} active={active} inverse={inverse} />}
    />
  );
}
