import { Paper } from "@mui/material";
import Stack from "atoms/Stack";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { SPACING } from "helpers/spacings";
import { defer } from "lodash-es";
import Toolbar, { ToolbarButton } from "molecules/Toolbar";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useWindowSize } from "react-use-size";

export default function ErrorPage({ error }) {
  const windowSize = useWindowSize();
  let errorMessage = null;
  if (error?.isUserError) {
    errorMessage = error?.message;
  }
  errorMessage ||= "Something went wrong";
  useEffect(() => {
    if (error)
      defer(() => {
        throw error;
      });
  }, [!!error]);

  return (
    <div
      style={{
        height: windowSize.height - 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Helmet title={errorMessage} />
      <Paper
        style={{
          padding: SPACING,
        }}
      >
        <h1
          style={{
            textTransform: "uppercase",
            fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
            fontSize: 24,
            lineHeight: 1.5,
            margin: 0,
          }}
        >
          {errorMessage}
        </h1>
        <Stack>
          <div>
            <p>You might have entered an invalid URL, or the page you are looking for is no longer available.</p>
            <p>If you think this is a mistake, please contact support.</p>
          </div>
          <Toolbar>
            <ToolbarButton href="/" label="Start again" />
          </Toolbar>
        </Stack>
      </Paper>
    </div>
  );
}
